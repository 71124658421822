import React, {ReactElement, useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import {Box, Grid, Menu, MenuItem, Typography} from '@mui/material'
import {Link, useLocation, useNavigate} from 'react-router-dom'
import CustomHeaderButton from './CustomHeaderButton'

import {ItemNavigation} from './ItemNavigation'


const ItemNavigationMenu = ({icon, itemList, label, subRoute, url}:
                                { icon?: ReactElement, itemList?: ItemNavigation[], label: string, subRoute: string, url?: string }) => {
    const {pathname} = useLocation()
    const [anchorEl, setAnchorEl] = useState<Element | null>(null)
    const handleClick = (e: React.MouseEvent) => {
        if (itemList) {
            setAnchorEl(e.currentTarget)
            return
        }
        if (url) {
            navigate(url)
            return
        }

    }
    const handleClose = () => setAnchorEl(null)

    const open = Boolean(anchorEl)
    const navigate = useNavigate()
    const [activeLink, setActiveLink] = useState(false)


    useEffect(() => {
        setActiveLink(pathname.startsWith(subRoute))
    }, [pathname, subRoute])

    /*    TODO ADD active link*/
    return (
        <>
            <Box pl={1} pr={1}>
                <CustomHeaderButton
                    onClick={handleClick}
                    endIcon={null}
                    activeNavLink={activeLink}
                    noIcon={!icon}
                >
                    <Grid container direction="column" alignItems="center">
                        {icon}
                        <Typography className="header-label">{label}</Typography>
                    </Grid>
                </CustomHeaderButton>
            </Box>
            {itemList && (
                <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
                    {Array.isArray(itemList) &&
                        itemList.map((item) => {
                            return (
                                <MenuItem
                                    key={item.objId}
                                    data-testid={item.dataTestId}
                                    disabled={item.disabled}
                                    onClick={() => {
                                        navigate(item.url)
                                        handleClose()
                                    }}
                                >
                                    {item.label}
                                </MenuItem>
                            )
                        })}
                </Menu>
            )}
        </>
    )
}

ItemNavigationMenu.propTypes = {
    itemList: PropTypes.array,
    label: PropTypes.string,
    icon: PropTypes.element,
}

export default ItemNavigationMenu
