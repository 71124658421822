import React, {useEffect, useState} from 'react'
import {Guild} from '../../models/guild'
import CustomModal from '../../../genericComponents/customModal/CustomModal'
import {LoadingButton} from '@mui/lab'
import {TextField} from '@mui/material'
import {OrganizationIdentificationSummary} from '../../models/OrganizationIdentificationSummary'
import {useGetTCISOrganizationQuery} from '../../../Search/rtkSearchApi'
import Autocomplete from '@mui/material/Autocomplete'
import {useAttachOrganizationMutation} from '../../rtkGuildApi'
import { setSnackbarMessage } from '../../../util/commonSlice'
import { useDispatch } from 'react-redux'

const EditLinkOrganization = ({guild, isOpen, onClose, detailType, currentLinkedOrganisation}: {
    guild: Guild,
    isOpen: boolean,
    onClose:() => void,
    detailType: string,
    currentLinkedOrganisation: OrganizationIdentificationSummary | undefined,
}) => {
    const {
        data: organizations = [],
        isFetching: isOrganizationsLoading
    } = useGetTCISOrganizationQuery()
    const [attachOrganization, attachOrganizationResult] = useAttachOrganizationMutation()

    const [organizationSelected, setOrganizationSelected] = useState<OrganizationIdentificationSummary | null>(null)

    const dispatch = useDispatch()

    useEffect(() => {
        if (attachOrganizationResult.isSuccess) {
            onClose()
            setOrganizationSelected(organizationSelected)
        }
    }, [attachOrganizationResult])

    const shouldLinkButtonBeDisabled = () => {
        return (!organizationSelected)
    }

    const onOptionChange = (org: OrganizationIdentificationSummary | null) => {
        if (org) setOrganizationSelected(org)
    }

    const linkOrganisationToGuild = () => {
        attachOrganization({ guildId: guild.id, organizationId: organizationSelected?.id }).unwrap()
            .then(() => dispatch(setSnackbarMessage('Linked organisation has been updated')))
            .catch((error) => dispatch(setSnackbarMessage(error)))
    }

    const onLinkedToSubmit = () => {
        linkOrganisationToGuild()
    }

    if (!isOpen) return <></>
    return <CustomModal handleClose={onClose}
                        title={<span>Update {detailType}</span>}
                        body={<ModalBody
                            detailType={detailType}
                            currentLinkedOrganisation={currentLinkedOrganisation}
                            organizations={organizations}
                            onOptionChange={onOptionChange}
                            organizationSelected={organizationSelected}
                            isOrganizationsLoading={isOrganizationsLoading}
                            onSubmit={onLinkedToSubmit}
                        />}
                        actions={<ModalActionButtonRow
                            shouldLinkButtonBeDisabled={shouldLinkButtonBeDisabled}
                            onLinkedToSubmit={onLinkedToSubmit}
                            attachOrganizationResult={attachOrganizationResult}
                        />}
    />
}

const ModalBody = ({ detailType, currentLinkedOrganisation, organizations, onOptionChange, organizationSelected, isOrganizationsLoading, onSubmit }: {
    detailType: string,
    currentLinkedOrganisation: OrganizationIdentificationSummary | undefined,
    organizations: OrganizationIdentificationSummary[],
    onOptionChange: (value: OrganizationIdentificationSummary | null) => void,
    organizationSelected: OrganizationIdentificationSummary | null,
    isOrganizationsLoading: boolean,
    onSubmit: () => void
}) => {

    return <form onSubmit={onSubmit}>
        <div className="edit-guild-details-modal">
            <div className="guild-details-current-value">
                <label className="detail-name">Current {detailType}:</label>
                <label className="detail-value">{currentLinkedOrganisation?.label}</label>
            </div>

            <div className="guild-details-new-value">
                <label className="detail-name">Update {detailType} to: <label className="asterisk">*</label></label>
                <Autocomplete
                    id="attachOrganizationAutoComplete"
                    className="auto-complete-organization"
                    options={organizations}
                    getOptionDisabled={(option) => option.id === currentLinkedOrganisation?.id}
                    onChange={(event, value) => {
                        onOptionChange(value)
                    }}
                    value={organizationSelected}
                    renderInput={(params) => <TextField {...params}
                                                        placeholder={isOrganizationsLoading ? 'Loading...' : 'Select Organization'} />}
                    disabled={isOrganizationsLoading}
                    renderOption={(props, item) => <li {...props} key={item.id}> {item.label}</li>}
                />
            </div>
            {/*Not used due to backend does not support yet*/}
            {/*<div className="guild-details-new-value-reason">*/}
            {/*    <label className="detail-name">Reason for Update:</label>*/}
            {/*    <TextField*/}
            {/*        id="reason"*/}
            {/*        className="new-value-reason-textfield"*/}
            {/*        multiline*/}
            {/*        rows={3}*/}
            {/*        placeholder="e.g. Contract Renewal"*/}
            {/*    />*/}
            {/*</div>*/}
        </div>
    </form>
}

const ModalActionButtonRow = ({shouldLinkButtonBeDisabled, onLinkedToSubmit, attachOrganizationResult}: {
    shouldLinkButtonBeDisabled: () => boolean,
    onLinkedToSubmit: () => void,
    attachOrganizationResult: any
}) => {
    return <div className="update-button-container">
        <LoadingButton data-cy="link-organization-save-button"
                       color="primary"
                       variant="contained"
                       disabled={shouldLinkButtonBeDisabled()}
                       type="submit"
                       className="update-button"
                       onClick={onLinkedToSubmit}
                       loading={attachOrganizationResult.isLoading}
        >
            Update
        </LoadingButton>
    </div>
}

export default EditLinkOrganization
