import CardContainer from '../../genericComponents/cardContainer/CardContainer'
import EditButton from '../../genericComponents/EditButton/EditButton'
import { useState } from 'react'
import EditGuildDetailsModal from './commonForm/EditGuildDetailsModal'
import { useUpdateGuildMutation } from '../rtkGuildApi'
import { formatBudget, Guild } from '../models/guild'
import './EditGuild.scss'
import { setSnackbarMessage } from '../../util/commonSlice'
import { useDispatch } from 'react-redux'
import { EditGuildDetailTypeEnum } from '../models/EditGuildDetailTypeEnum'

const GuildAvailableCreditsCard = ({guild}: {guild: Guild}) => {
    const [showEditModal, setShowEditModal] = useState(false)

    const [updateGuild, updateGuildResult] = useUpdateGuildMutation()

    const dispatch = useDispatch()

    const openEditModal = () => setShowEditModal(true)
    const closeEditModal = () => setShowEditModal(false)

    const onEditButtonClick = () => {
        openEditModal()
    }

    const guildCredits = guild?.budget

    const submitUpdatedGuildBudget = (newBudget: number, updateReason: string) => {
        if (!guild) return

        const updatedGuild: Guild = {
            ...guild,
            budget: formatBudget(newBudget),
            budgetUpdateComment: updateReason
        }
        updateGuild(updatedGuild).unwrap()
            .then(() => dispatch(setSnackbarMessage('Available credits has been updated')))
            .catch((error) => dispatch(setSnackbarMessage(error)))
    }

    return <CardContainer contentClassName="guild-credits" fullWidth>
        <img src="/images/guild/guild-credits.svg" alt="guild-credits-icon" className="guild-credits-icon" />
        <div className="available-credits">
            <label className="card-title">Available Credits</label>
            <label className="card-value">{guildCredits}</label>
        </div>
        <EditButton onEditClick={onEditButtonClick}/>
        <EditGuildDetailsModal isOpen={showEditModal} onClose={closeEditModal}
                               detailType={EditGuildDetailTypeEnum.AVAILABLE_CREDITS} existingValue={guildCredits.toString()}
                               onSubmit={submitUpdatedGuildBudget} isSubmitLoading={updateGuildResult.isLoading}

        />
    </CardContainer>
}

export default GuildAvailableCreditsCard