import './QuotationDetail.scss'
import {ROUTES} from '../../../util/constants/routing'
import {useNavigate} from 'react-router-dom'
import React, {ReactElement} from 'react'
import {ServiceRequest} from '../../models/ServiceRequest'
import getServiceSubscriptionType from '../../models/ServiceType'
import {CREDITS} from '../../../constants/credits'
import {Button, Divider} from '@mui/material'
import QuotationRuleTypeEnum from '../../models/QuotationRuleTypeEnum'
import {getTypedQuotationRule} from '../../models/QuotationRules/QuotationRule'
import {getTotalFromRequest} from '../../models/Quotation'
import UserRequestStatusEnum from '../../models/UserRequestStatusEnum'
import ServiceEnum from '../../models/ServiceEnum'
import {getCadModelDisplayName} from '../../models/CADModelTypeEnum'

const CadModelDetails = ({serviceRequest}: {
    serviceRequest: ServiceRequest
}) => {
    const cadModelType = serviceRequest?.serviceTypeAttributes?.cadModelType
    const cadVariant = serviceRequest?.serviceTypeAttributes?.cadModelVariant
    if (!cadModelType) return <></>
    return <>
        <div>CAD Model Type:</div>
        <div>{getCadModelDisplayName[cadModelType] ?? ''}</div>
        {cadVariant && <>
            <div>Variant:</div>
            <div>{serviceRequest?.serviceTypeAttributes?.cadModelVariant}</div>
        </>
        }
    </>
}

const ServiceRequestDetails = ({serviceRequest}: {
    serviceRequest: ServiceRequest
}) => {
    if (!serviceRequest.service) return <></>
    const detailsByService: Map<ServiceEnum, ReactElement> = new Map([
        [ServiceEnum.CAD_MODEL, <CadModelDetails serviceRequest={serviceRequest}/>]
    ])
    return detailsByService.get(serviceRequest.service) ?? <></>
}

const QuotationDetail = ({serviceRequest}: {
    serviceRequest: ServiceRequest
}) => {
    const navigate = useNavigate()

    const handleEditQuotation = () => {
        navigate('' + ROUTES.serviceRequestQuotation.path + serviceRequest?.id)
    }

    const genericRules = serviceRequest?.quotation?.quotationRules
        ?.filter((rule) => rule.ruleType !== QuotationRuleTypeEnum.ADJUSTMENT)
        .map((rule) => getTypedQuotationRule(rule)) ?? []

    const adjustmentRules = serviceRequest?.quotation?.quotationRules
        ?.filter((rule) => rule.ruleType === QuotationRuleTypeEnum.ADJUSTMENT)
        .map((rule) => getTypedQuotationRule(rule)) ?? []
    return (
        <>
            <div className="service-request-detail-container">
                <div>Request ID:</div>
                <div>{serviceRequest?.id}</div>
                <div>Service:</div>
                <div>{serviceRequest?.service && (getServiceSubscriptionType(serviceRequest?.service)?.label ?? '')}</div>
                <ServiceRequestDetails serviceRequest={serviceRequest}/>
            </div>
            <Divider/>
            <div className="quotation-detail-container">
                <div className="quotation-detail-rule-structure quotation-detail-header">
                    <p className="quotation-detail-description"><strong>Description</strong></p>
                    <p className="quotation-detail-unit-price"><strong>Unitary Cost</strong> <p
                        className="caption">({CREDITS})</p></p>
                    <p className="quotation-detail-nb-object"><strong>Qty</strong></p>
                    <p className="quotation-detail-min-quantity"><strong>Min. Qty</strong></p>
                    <p className="quotation-detail-total"><strong>Sub-Total</strong></p>

                </div>
                {
                    genericRules.map((rule, index) =>
                        rule.getDisplayComponent(serviceRequest, 0, index + '-genericRule')
                    )
                }
            </div>
            <Divider/>
            <div className="quotation-detail-container">
                {
                    adjustmentRules.map((rule, index) =>
                        rule.getDisplayComponent(serviceRequest, 0, index + '-adjustment')
                    )
                }
            </div>
            <div className="quotation-detail-rule-structure">
                <div className="quotation-detail-total-title"><strong>Total</strong>
                    <div className="caption">({CREDITS})</div>
                </div>
                <div className="quotation-detail-total-value">{getTotalFromRequest(serviceRequest)}</div>
            </div>
            {serviceRequest.status === UserRequestStatusEnum.AWAITING_QUOTATION && <div className="quotation-button">
                <Button variant="outlined" color="primary" onClick={() => handleEditQuotation()}><strong>Edit
                    Quotation</strong></Button>
            </div>}
        </>
    )
}

export default QuotationDetail