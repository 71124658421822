import React, {useState} from 'react'
import {DataGrid, GridColDef, GridRenderCellParams} from '@mui/x-data-grid'
import './BannerTable.scss'
import {getBannerTypeDisplay, getStatus} from './BannerTableFormat'
import BannerActions from './BannerTableActions'
import {useGetAllBannersQuery} from '../rtkBannerApi'
import {getFormattedDate} from '../../util/table'
import {Button, Grow, Snackbar} from '@mui/material'
import {ROUTES} from '../../util/constants/routing'
import {Link} from 'react-router-dom'
import {SortDates} from '../../genericComponents/utils/DateService'
import LoadingSpinner from '../../genericComponents/spinner/LoadingSpinner'

const BannerLinkButton = () => {
    return <>
            <Button
                className = "banner-header-button"
                variant = "contained"
                disableElevation
                component={Link}
                size = "small"
                to = {ROUTES.createBanner.path ?? ''}
            >
                Add New
            </Button>
    </>
}

const BannerTable = () => {
    const {data : bannerList, isLoading} = useGetAllBannersQuery()
    const [openSnack, setOpenSnack] = useState({
        open: false,
        message: '',
    })

    const handleSnack = () => {
        setOpenSnack({open: false, message: ''})
    }
    const getActions = (params: GridRenderCellParams) => {
        return (
            <BannerActions
                currentBanner= {params.row}
                openSnack ={setOpenSnack}
            />
        )
    }

    const columns: GridColDef[] = [

        {
            field: 'title',
            headerName: 'Banner Title',
            flex: 1,
            minWidth: 200,
            disableColumnMenu: true,
        },
        {
            field: 'name', // Empty
            headerName: 'Author',
            flex: 1,
            minWidth: 200,
            disableColumnMenu: true,
        },
        {
            field: 'enabled',
            headerName: 'Status',
            flex: 1,
            minWidth: 150,
            disableColumnMenu: true,
            renderCell: getStatus
        },
        {
            field: 'updatedDate',
            headerName: 'Last Updated',
            flex: 1,
            minWidth: 200,
            disableColumnMenu: true,
            valueGetter: getFormattedDate,
            sortComparator: (v1, v2) => SortDates(v1, v2)
        },
        {
            field: 'type',
            headerName: 'Color Status',
            flex: 1,
            minWidth: 150,
            disableColumnMenu: true,
            valueGetter: getBannerTypeDisplay
        },
        {
            field: 'action',
            headerName: 'Actions',
            flex: 1,
            minWidth: 40,
            sortable: false,
            headerClassName: 'more-option-menu-header',
            disableColumnMenu: true,
            renderCell: getActions
        },
    ]

    return <>
        <div className = "banner-page-container">

            <h1 className="page-title">Banners</h1>
            <BannerLinkButton/>

            <div className="banner-grid-container">
                <DataGrid
                    loading={isLoading}
                    slots={{loadingOverlay: LoadingSpinner}}
                    autoHeight={true}
                    onCellKeyDown={(params, events) => events.stopPropagation()}
                    getRowId={(banner) => banner?.id}
                    rows={bannerList ?? []}
                    columns={columns}
                    sortingOrder={['desc', 'asc']}
                    initialState={{
                        pagination: {paginationModel:{page: 0, pageSize: 20}},
                        sorting: {
                            sortModel: [
                                {
                                    field: 'updatedDate',
                                    sort: 'desc',
                                },
                            ],
                        },
                    }}

                />
            </div>
            {openSnack.message !== '' &&
                <Snackbar
                    open = {openSnack.open}
                    message = {openSnack.message}
                    autoHideDuration = {4000}
                    onClose = {handleSnack}
                    TransitionComponent = {Grow}
                />}

    </div>
    </>
}

export default BannerTable