import React, {useState} from 'react'
import './ServiceRequestTable.scss'
import {ServiceRequest} from '../models/ServiceRequest'
import {GridCellParams, GridColDef, GridRenderCellParams} from '@mui/x-data-grid'
import ServiceRequestDialog from '../../genericComponents/serviceRequest/ServiceRequestDialog'
import ServiceRequestActions from './ServiceRequestActions/ServiceRequestActions'
import {
    formatEmail,
    formatIdLink,
    formatService,
    getContractId,
    getCreatedDate,
    getGuildName,
    getLastModifiedDate,
    getQuotationValue,
    GetRequestDetails,
    getRequestDetailsValue,
    getRequesterEmail,
    getServiceLabel,
    getStatus,
    getStatusValue,
    quotationDetails
} from '../../genericComponents/serviceRequest/ServiceRequestFormatter'
import {CREDITS} from '../../constants/credits'
import UpdateQuotationDialog from './UpdateQuotation/UpdateQuotationDialog'
import {useGetOpenServiceRequestsQuery} from '../rtkServiceRequestApi'
import {SortDates} from '../../genericComponents/utils/DateService'
import ExportServiceRequests from './ExportServiceRequests'
import {equalsOperator, matchString} from '../../genericComponents/grid/gridFilterOperators'
import DaTXDataGrid from '../../genericComponents/grid/DaTXDataGrid'


const NewServiceRequestTable = () => {
    const [open, setOpen] = useState(false)
    const [openQuotationDialog, setOpenQuotationDialog] = useState(false)
    const [selectedServiceRequest, setSelectedServiceRequest] = useState<GridCellParams>()
    const [serviceRequestToUpdate, setServiceRequestToUpdate] = useState<ServiceRequest>()
    const {data: serviceRequests = [], isLoading} = useGetOpenServiceRequestsQuery()


    const onServiceRequestClick = (params: GridCellParams) => {
        setSelectedServiceRequest(params)
        setOpen(true)
    }

    const onQuotationClick = (params: GridCellParams) => {
        setServiceRequestToUpdate(serviceRequests?.find(x => x.id === params.id))
        setOpenQuotationDialog(true)
    }

    const getAction = (params: GridRenderCellParams) => {
        return (
            <ServiceRequestActions
                serviceRequest={params?.row}
            />
        )
    }

    const getCreditHeader = (headerName: string) => (<>
        <strong>{headerName}</strong>
        <div className="caption">({CREDITS})</div>
    </>)

    const columns: GridColDef[] = [
        {
            field: 'id',
            headerName: 'Request ID',
            flex: 1,
            minWidth: 120,
            sortable: true,
            renderCell: (params: GridRenderCellParams) => formatIdLink(params, onServiceRequestClick),
            filterOperators: [matchString]
        },
        {
            field: 'service',
            headerName: 'Service',
            flex: 1,
            minWidth: 150,
            sortable: true,
            valueGetter: getServiceLabel,
            renderCell: formatService,
            filterOperators: [equalsOperator]
        },
        {
            field: 'requesterEmail',
            headerName: 'Requester Email',
            flex: 1,
            minWidth: 200,
            sortable: true,
            valueGetter: getRequesterEmail,
            renderCell: formatEmail,
            filterOperators: [matchString]
        },
        {
            field: 'guildName',
            headerName: 'Guild Name',
            flex: 1,
            minWidth: 150,
            sortable: true,
            valueGetter: getGuildName,
            filterOperators: [matchString]
        },
        {
            field: 'billingId',
            headerName: 'Contract ID',
            flex: 1,
            minWidth: 150,
            sortable: true,
            valueGetter: getContractId,
            filterOperators: [matchString]
        },
        {
            field: 'requestDetails',
            headerName: 'Request Details',
            flex: 1,
            minWidth: 150,
            align: 'left',
            sortable: true,
            valueGetter: getRequestDetailsValue,
            renderCell: GetRequestDetails,
            filterOperators: [matchString]
        },
        {
            field: 'quotation',
            renderHeader: () => getCreditHeader('Quotation'),
            flex: 0.5,
            minWidth: 150,
            align: 'right',
            sortable: true,
            valueGetter: getQuotationValue,
            renderCell: (params: GridRenderCellParams) => quotationDetails(params, onQuotationClick),
            filterOperators: [matchString]
        },
        {
            field: 'status',
            headerName: 'Status',
            flex: 1,
            minWidth: 150,
            sortable: true,
            valueGetter: getStatusValue,
            renderCell: getStatus,
            filterOperators: [equalsOperator]
        },
        {
            field: 'requestDate',
            headerName: 'Creation Date',
            flex: 1,
            minWidth: 130,
            sortable: true,
            filterable: false,
            valueGetter: getCreatedDate,
            sortComparator: (v1, v2) => SortDates(v1, v2),

        },
        {
            field: 'updatedDate',
            headerName: 'Last Modified',
            flex: 1,
            minWidth: 130,
            sortable: true,
            filterable: false,
            valueGetter: getLastModifiedDate,
            sortComparator: (v1, v2) => SortDates(v1, v2),
        },
        {
            field: 'action',
            headerName: 'Actions',
            flex: 1,
            minWidth: 80,
            sortable: false,
            disableColumnMenu: true,
            renderCell: getAction,
        },
    ]

    return (
        <>
            <h1 className="page-title-service-request">Service Requests</h1>
            <div className="service-requests-grid-container">
                <DaTXDataGrid
                    isLoading={isLoading}
                    emptyRowMessage="No Service Requests Found"
                    columns={columns}
                    rows={serviceRequests}
                    getRowId={(serviceRequest: ServiceRequest) => serviceRequest?.id}
                >
                    <ExportServiceRequests serviceRequests={serviceRequests}/>
                </DaTXDataGrid>
                <ServiceRequestDialog
                    className="admin-service-request-dialog"
                    title="Request Details"
                    open={open}
                    onClose={() => setOpen(false)}
                    selectedServiceRequest={selectedServiceRequest}/>
                <UpdateQuotationDialog
                    className="admin-update-quotation-dialog"
                    title="Quotation"
                    open={openQuotationDialog}
                    onClose={() => setOpenQuotationDialog(false)}
                    selectedServiceRequest={serviceRequestToUpdate}/>
            </div>
        </>
    )
}

export default NewServiceRequestTable
