export enum CADModelTypeEnum {
    'FOOTPRINT_CADENCE' = 'FOOTPRINT_CADENCE',
    'SYMBOL_CADENCE' = 'SYMBOL_CADENCE',
    'FOOTPRINT_AND_SYMBOL_CADENCE' = 'FOOTPRINT_AND_SYMBOL_CADENCE',
    'MODEL_CATIA' = 'MODEL_CATIA',
    'MODEL_PTC' = 'MODEL_PTC'
}

export const getCadModelDisplayName: {[key in CADModelTypeEnum]: string} = {
    [CADModelTypeEnum.FOOTPRINT_CADENCE]: 'FOOTPRINT CADENCE',
    [CADModelTypeEnum.SYMBOL_CADENCE]: 'SYMBOL CADENCE',
    [CADModelTypeEnum.FOOTPRINT_AND_SYMBOL_CADENCE]: 'Footprint And Symbol (Cadence)',
    [CADModelTypeEnum.MODEL_CATIA]: 'MODEL CATIA',
    [CADModelTypeEnum.MODEL_PTC]: 'MODEL PTC'
}
