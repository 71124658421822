import {GridValueGetterParams} from '@mui/x-data-grid'
import {formatDate, formatMilliseconds, formatMonth} from '../genericComponents/utils/DateService'

export const getFormattedDate = (date: GridValueGetterParams) => {
    const formattedDate = [...date.value]
    const dateToDisplay = formatMilliseconds(formattedDate)
    formatMonth(dateToDisplay)
    if (formattedDate) {
        return formatDate(formattedDate)
    }
}