import {createApi} from '@reduxjs/toolkit/dist/query/react'
import {axiosBaseQuery} from '../rtkQueryUtils'
import {SEARCH_API_ENDPOINT, SEARCH_TRANSLATOR_API_ENDPOINT} from '../util/constants/backendUrls'
import {PreferredAttributeDefinition, TechnicalNodeModel} from './model/TechnicalNodeModel'
import {DisplaySetModel} from '../displaySets/model/DisplaySetModel'
import {GET_DISPLAY_SETS} from '../displaySets/rtkDisplaySetsApi'
import {ClassificationNode} from './model/ClassificationNode'

export const GET_TECHNICAL_CLASSIFICATION_NODES = 'GetTechnicalClassificationNodes'

export const classificationNodesApi = createApi({
    reducerPath: 'classificationNodes',
    keepUnusedDataFor: 0,
    tagTypes: [GET_TECHNICAL_CLASSIFICATION_NODES],
    baseQuery: axiosBaseQuery({baseUrl: `${SEARCH_API_ENDPOINT}`}),
    endpoints: (builder) => ({
        getTechnicalNodeTree: builder.query<ClassificationNode, void>({
            query: () => ({
                url: '/datx/classification/datxtree',
                method: 'GET'
            }),
            transformResponse: (response: ClassificationNode) => response,
            transformErrorResponse: (response, meta, arg) => 'An error occurred while retrieving classification tree',
            providesTags: [GET_TECHNICAL_CLASSIFICATION_NODES]
        })
    })
})

export const {useGetTechnicalNodeTreeQuery} = classificationNodesApi