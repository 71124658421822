export type UserRoleType = UserRoleEnum | string

export enum UserRoleEnum {
    VALIDATOR = 'VALIDATOR',
    PROVIDER = 'PROVIDER'
}

export const getUserTypeDisplayName: { [key in UserRoleEnum]: string } = {
    [UserRoleEnum.VALIDATOR]: 'Data Validator',
    [UserRoleEnum.PROVIDER]: 'Data Provider'
}
