import axios, {AxiosPromise} from 'axios'
import {ContainerClient} from '@azure/storage-blob'
import FileSaver from 'file-saver'
import {ServiceRequest} from './models/ServiceRequest'
import {Dispatch} from 'redux'
import {errorWrapper, GenericAnswer} from '../util/axios/apiCallUtils'
import {setSnackbarMessage} from '../util/commonSlice'

export const updateServiceRequestFile = async (id: string, file: File, dispatch: Dispatch) => {
    const response = await errorWrapper<ServiceRequest>(
        axios.put(
            `${process.env.REACT_APP_GUILD_API_URL}/datx/serviceRequests/${id}`,
            {name: file.name, size: file.size}
        ),
        dispatch
    )
    const serviceRequest: ServiceRequest = response?.data?.data

    if(!serviceRequest?.file) {
        throw new Error('This service does not have a file to update')
    }

    // upload file on storage account
    const {saskey, blobName, containerURL} = serviceRequest.file
    const containerClient = new ContainerClient(`${containerURL}?${saskey}`)
    const arrayBufferFile = await file.arrayBuffer()

    if (!blobName) {
        const error = new Error('Error in updating service request file: blobName is missing from guild-api response')
        dispatch(setSnackbarMessage(error.message))
        throw error
    }

    await containerClient
        .getBlockBlobClient(blobName)
        .uploadData(arrayBufferFile)

    // update file status
    await errorWrapper<ServiceRequest>(
        axios.patch(
            `${process.env.REACT_APP_GUILD_API_URL}/datx/serviceRequests/${response?.data.data?.id}/file/status/UPLOAD_SUCCEED`
        ),
        dispatch
    )
}

export const getServiceRequestFileEndpoint = (requestId: string) => {
    return `${process.env.REACT_APP_GUILD_API_URL}/datx/serviceRequests/${requestId}/file`
}

export const getServiceRequestQuotationFileEndpoint = (requestId: string) => {
    return `${process.env.REACT_APP_GUILD_API_URL}/datx/serviceRequests/${requestId}/quotation-file`
}

export const getServiceRequestFile = async (requestId: string, fileName: string, dispatch: Dispatch): Promise<string> => {
    //sends request to get serviceRequest details corresponding to file
    const response = await errorWrapper<ServiceRequest>(
        axios.get(getServiceRequestFileEndpoint(requestId)),
        dispatch
    )

    const serviceRequest: ServiceRequest = response?.data?.data
    //extracts variables from serviceRequest needed to make request to Microsoft Azure Blob storage to download file
    const {saskey, blobName, containerURL} = serviceRequest
    const containerClient = new ContainerClient(`${containerURL}?${saskey}`)
    const downloadBlockBlobResponse = await containerClient
        .getBlockBlobClient(blobName)
        .download()
    const data = await downloadBlockBlobResponse.blobBody
    if (!data) {
        const error = new Error('File Not found')
        dispatch(setSnackbarMessage(error.message))
        throw error
    }

    FileSaver.saveAs(data, fileName)
    return requestId
}

export const getServiceRequestQuotationFile = async (requestId: string, dispatch: Dispatch): Promise<string> => {
    //sends request to get serviceRequest details corresponding to file
    const response = await errorWrapper<ServiceRequest>(
        axios.get(getServiceRequestQuotationFileEndpoint(requestId)),
        dispatch
    )

    const serviceRequest: ServiceRequest = response?.data?.data
    //extracts variables from serviceRequest needed to make request to Microsoft Azure Blob storage to download file
    const {saskey, blobName, containerURL} = serviceRequest
    const containerClient = new ContainerClient(`${containerURL}?${saskey}`)
    const downloadBlockBlobResponse = await containerClient
        .getBlockBlobClient(blobName)
        .download()
    const data = await downloadBlockBlobResponse.blobBody
    if (!data) {
        const error = new Error('Quotation file Not found')
        dispatch(setSnackbarMessage(error.message))
        throw error
    }

    FileSaver.saveAs(data, serviceRequest.name)
    return requestId
}
