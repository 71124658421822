import React from 'react'
import ItemNavigationMenu from '../headerGenericComponents/ItemNavigationMenu'
import {ItemNavigation} from '../headerGenericComponents/ItemNavigation'
import {ROUTES} from '../../util/constants/routing'

const FactoryUserMenu = () => {
    const data: ItemNavigation[] = [
        {
            label: 'Create Factory User',
            objId: '1',
            url: ROUTES.createFactoryUser.path ?? '',
            dataTestId: 'create-factory-user'
        },
        {
            label: 'Active Factory Users',
            objId: '2',
            url: ROUTES.factoryUsers.path ?? '',
            dataTestId: 'active-factory-users'
        },
    ]

    return (
        <ItemNavigationMenu
            label="Datx Factory"
            subRoute="/factoryUsers"
            itemList={data}
        />
    )
}

export default FactoryUserMenu
