import {GridColDef} from '@mui/x-data-grid'

import {equalsOperator, matchString} from '../../genericComponents/grid/gridFilterOperators'
import {
    formatEmail,
    formatRole, getFormattedDate,
    getRequesterEmail,
    getRoleLabel
} from '../../genericComponents/factoryUser/FactoryUserFormatter'
import {SortDates} from '../../genericComponents/utils/DateService'
import DaTXDataGrid from '../../genericComponents/grid/DaTXDataGrid'
import React from 'react'
import {useGetActiveFactoryUsersQuery} from '../rtkFactoryApi'
import {FactoryUser} from '../CreateFactoryUser/model/FactoryUser'

const ActiveFactoryUsersTable = () => {

    const {data: factoryUsers = [], isLoading} = useGetActiveFactoryUsersQuery()

    const columns: GridColDef[] = [
        {
            field: 'id',
            headerName: 'User ID',
            flex: 1,
            minWidth: 120,
            sortable: true,
            renderCell: formatEmail,
            valueGetter: getRequesterEmail,
            filterOperators: [matchString]
        },
        {
            field: 'role',
            headerName: 'Role',
            flex: 1,
            minWidth: 150,
            sortable: true,
            valueGetter: getRoleLabel,
            renderCell: formatRole,
            filterOperators: [equalsOperator]
        },
        {
            field: 'updatedDate',
            headerName: 'Last Modified',
            flex: 1,
            minWidth: 150,
            sortable: true,
            filterable: false,
            valueGetter: getFormattedDate,
            sortComparator: (v1, v2) => SortDates(v1, v2),

        }
    ]
    return (
        <>
            <h1 className="page-title-service-request">User Management</h1>
            <div className="service-requests-grid-container">
                <DaTXDataGrid
                    rowHeight={42}
                    isLoading={isLoading}
                    emptyRowMessage="No factory users found"
                    columns={columns}
                    rows={factoryUsers}
                    getRowId={(factoryUser: FactoryUser) => factoryUser?.id}
                >
                    <></>
                </DaTXDataGrid>
            </div>
        </>
    )}

export default ActiveFactoryUsersTable
