export class LocalStorage {

    static keys = {
        userEmail: 'email',
    }

    static get(key: string) {
        const data = localStorage.getItem(key)

        if (data) {
            return JSON.parse(data)
        }

        return null
    }

    static set(key: string, data: any) {
        localStorage.setItem(key, JSON.stringify(data))
    }

    static remove(key: string) {
        localStorage.removeItem(key)
    }

    static getUserEmail() {
        return this.get(this.keys.userEmail) || ''
    }

    static setUserEmail(email: string) {
        this.set(this.keys.userEmail, email)
    }
}