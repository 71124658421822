import NewsForm from './common/NewsForm'
import {useGetNewsByIdQuery, useUpdateNewsMutation} from '../rtkNewsApi'
import {News} from '../model/News'
import {useParams} from 'react-router'
import {useDispatch} from 'react-redux'
import {setSnackbarMessage} from '../../util/commonSlice'
import {useNavigate} from 'react-router-dom'
import {ROUTES} from '../../util/constants/routing'

const EditNews = () => {

    const newsTitle = 'Edit News & Announcements'
    const {newsId} = useParams()
    const {data : initialNews} = useGetNewsByIdQuery(newsId ?? '')
    const [useUpdateNews] = useUpdateNewsMutation()
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const HandleUpdate = async (news: News) => {
        const initialStatus = initialNews?.enabled ? 'Updated' : 'Published'
        const action = news.enabled? initialStatus : 'Saved as Draft'
        const result = await useUpdateNews(news)
        if ('data' in result) {
            dispatch(setSnackbarMessage('News & Announcement ' + action))
        }
        else {
            dispatch(setSnackbarMessage('Failed to Update News & Announcement'))
        }
        navigate(ROUTES.news.path ?? '')
    }

    return<>
        {initialNews !== undefined &&
            <NewsForm
                formTitle = {newsTitle}
                handlePublish = {HandleUpdate}
                initialNews = {initialNews}
            />}
    </>
}

export default EditNews