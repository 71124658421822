import {GridCellParams} from '@mui/x-data-grid/models/params/gridCellParams'
import {
    formatEmail,
    formatService, getContractId,
    getCreatedDate, getGuildName,
    getLastModifiedDate, getQuotationCost,
    GetQuotationFile, GetRequestDetails,
    getStatus
} from '../../genericComponents/serviceRequest/ServiceRequestFormatter'
import {CREDITS} from '../../constants/credits'

function ServiceRequestContent({
                                        selectedServiceRequest,
                                    }: {
    selectedServiceRequest: GridCellParams,
}) {
    return (
        <div className="body">
            <p>
                <span className="title" title="Service">Service: </span>
                <span className="body-value">{formatService(selectedServiceRequest)}</span>
            </p>
            <p>
                <span className="title" title="Requester Email">Requester Email: </span>
                <span className="body-value">{formatEmail(selectedServiceRequest)}</span>
            </p>
            <p>
                <span className="title" title="Guild Name">Guild Name: </span>
                <span className="body-value">{getGuildName(selectedServiceRequest)}</span>
            </p>
            <p>
                <span className="title" title="Contract ID">Contract ID: </span>
                <span className="body-value">{getContractId(selectedServiceRequest)}</span>
            </p>
            <p>
                <span className="title" title="Request Details">Request Details: </span>
                <span className="body-value"><GetRequestDetails row={selectedServiceRequest.row}/></span>
            </p>
            <div className="quotation-section">
                <div className="title" title="Quotation Amount">
                    <div>
                    Quotation Amount: 
                    </div>
                    <div className="caption">
                    ({CREDITS}) 
                    </div>
                </div>
                <span className="body-value">{getQuotationCost(selectedServiceRequest)}</span>
            </div>
            <p>
                <span className="title" title="Quotation Document">Quotation Document: </span>
                <span className="body-value"><GetQuotationFile row={selectedServiceRequest.row}/></span>
            </p>
            <p>
                <span className="title" title="Status">Status: </span>
                <span className="body-value">{getStatus(selectedServiceRequest)}</span>
            </p>
            <p>
                <span className="title" title="Creation Date">Creation Date: </span>
                <span className="body-value">{getCreatedDate(selectedServiceRequest)}</span>
            </p>
            <p>
                <span className="title" title="Last Modified">Last Modified: </span>
                <span className="body-value">{getLastModifiedDate(selectedServiceRequest)}</span>
            </p>
        </div>
    )}

export default ServiceRequestContent