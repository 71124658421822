import QuotationRuleTypeEnum from '../QuotationRuleTypeEnum'
import React from 'react'
import {ServiceRequest} from '../ServiceRequest'
import {MinQuantityRuleComponent} from './displayComponents/MinQuantityRuleComponent'
import {MultiplyPricePerQuantityRuleComponent} from './displayComponents/MultiplyPricePerQuantityRuleComponent'
import {FixedPriceRuleComponent} from './displayComponents/FixedPriceRuleComponent'
import {AdjustmentRuleComponent} from './displayComponents/AdjustmentRuleComponent'
import {UpdateMinQuantityRuleComponent} from './updateComponents/UpdateMinQuantityRuleComponent'
import {InputAttributes} from 'react-number-format'
import {UpdateAdjustmentRuleComponent} from './updateComponents/UpdateAdjustementRuleComponent'
import {FieldArrayRenderProps} from 'formik'
import {
    UpdateMultiplyPricePerQuantityRuleComponent
} from './updateComponents/UpdateMultiplyPricePerQuantityRuleComponent'
import {UpdateFixedPriceRuleComponent} from './updateComponents/UpdateFixedPriceRuleComponent'
import {GroupByPackRuleComponent} from './displayComponents/GroupByPackRuleComponent'
import {UpdateGroupByPackRuleComponent} from './updateComponents/UpdateGroupByPackRuleComponent'
import {EditQuotationFormikValues} from '../../ServiceRequestsTable/UpdateQuotation/EditQuotation'
import {validate as uuidValidate} from 'uuid'
import {ItemPriceRuleComponent} from './displayComponents/ItemPriceRuleComponent'
import {UpdateItemPriceRuleComponent} from './updateComponents/UpdateItemPriceRuleComponent'

export interface QuotationRule {
    ruleType: QuotationRuleTypeEnum,
    price?: number,
    adjustmentReason?: string,
    minQuantity?: number,
    quantity?: number,
    subRule?: QuotationRule,
    optionName?: string
    packSize?: number,
    itemName?: string,

    calculate(previousPrice: number, numberOfObjects: number): number

    getDisplayComponent(serviceRequest: ServiceRequest, quantity: number, key: string): React.ReactNode

    getUpdateComponent(serviceRequest: ServiceRequest, keySuffix: string, index: number,
                       handleChange: InputAttributes['onChange'],
                       formikArrayHelper: FieldArrayRenderProps,
                       setFieldValue: (field: string, value: any, shouldValidate?: (boolean | undefined)) => void,
                       values: EditQuotationFormikValues): React.ReactNode


}

export class MinQuantityRule implements QuotationRule {
    ruleType: QuotationRuleTypeEnum = QuotationRuleTypeEnum.MIN_QUANTITY
    minQuantity: number
    subRule: QuotationRule

    constructor(minQuantity: number, subRule: QuotationRule) {
        this.minQuantity = minQuantity
        this.subRule = getTypedQuotationRule(subRule)
    }

    calculate(previousPrice: number, numberOfObjects: number): number {
        const quantity = Math.max(numberOfObjects, this.minQuantity)
        return this.subRule.calculate(previousPrice, quantity)
    }

    getDisplayComponent(serviceRequest: ServiceRequest, quantity: number, key: string): React.ReactNode {
        return <MinQuantityRuleComponent rule={this} quantity={quantity} key={key}/>
    }

    getUpdateComponent(serviceRequest: ServiceRequest, keySuffix: string, index: number, handleChange: InputAttributes['onChange'],
                       formikArrayHelper: FieldArrayRenderProps,
                       setFieldValue: (field: string, value: any, shouldValidate?: (boolean | undefined)) => void,
                       values: EditQuotationFormikValues): React.ReactNode {
        return <UpdateMinQuantityRuleComponent serviceRequest={serviceRequest} rule={this} key={keySuffix + index}
                                               values={values}
                                               index={index} handleChange={handleChange}/>
    }

}


export class RuleWithQuantity implements QuotationRule {
    ruleType: QuotationRuleTypeEnum = QuotationRuleTypeEnum.RULE_WITH_QUANTITY
    quantity: number
    optionName: string
    subRule: QuotationRule

    constructor(quantity: number, subRule: QuotationRule, optionName: string) {
        this.quantity = quantity
        this.optionName = optionName
        this.subRule = getTypedQuotationRule(subRule,this.optionName)
    }

    calculate(previousPrice: number): number {
        return this.subRule?.calculate(
            previousPrice,
            this.quantity
        )
    }

    getDisplayComponent(
        serviceRequest: ServiceRequest,
        quantity: number,
        key: string,
    ): React.ReactNode {
        return this.subRule.getDisplayComponent(serviceRequest, this.quantity, key)
    }

    getUpdateComponent(
        serviceRequest: ServiceRequest,
        keySuffix: string,
        index: number,
        handleChange: InputAttributes['onChange'],
        formikArrayHelper: FieldArrayRenderProps,
        setFieldValue: (field: string, value: any, shouldValidate?: (boolean | undefined)) => void,
        values: EditQuotationFormikValues
    ): React.ReactNode {
        return this.subRule.getUpdateComponent(serviceRequest, keySuffix, index, handleChange, formikArrayHelper, setFieldValue, values)
    }
}

export class AdjustmentRule implements QuotationRule {
    ruleType: QuotationRuleTypeEnum = QuotationRuleTypeEnum.ADJUSTMENT
    price: number
    adjustmentReason: string

    constructor(price: number, adjustmentReason: string) {
        this.price = price
        this.adjustmentReason = adjustmentReason
    }

    calculate(previousPrice: number): number {
        return floatRounding(previousPrice + this.price)
    }

    getDisplayComponent(serviceRequest: ServiceRequest, quantity: number, key: string): React.ReactNode {
        return <AdjustmentRuleComponent rule={this} key={key}/>
    }

    getUpdateComponent(serviceRequest: ServiceRequest, keySuffix: string, index: number, handleChange: InputAttributes['onChange'],
                       formikArrayHelper: FieldArrayRenderProps): React.ReactNode {
        return <UpdateAdjustmentRuleComponent serviceRequest={serviceRequest} rule={this} key={keySuffix + index}
                                              index={index} handleChange={handleChange}
                                              formikArrayHelper={formikArrayHelper}/>
    }

}

export class MultiplyPricePerQuantityRule implements QuotationRule {
    ruleType: QuotationRuleTypeEnum = QuotationRuleTypeEnum.MULTIPLY_PRICE_PER_QUANTITY
    price: number
    optionName:string

    constructor(price: number, optionName?: string) {
        this.price = price
        this.optionName = optionName?? ''
    }

    calculate(previousPrice: number, numberOfObjects: number): number {
        return floatRounding(previousPrice + (this.price * numberOfObjects))
    }

    isValidUUID(uuid: string): boolean {
        return uuidValidate(uuid)
    }
    getOptionName(): { optionName?: string }{
        const optionName = this.optionName
        return (
            optionName &&
            optionName !== 'Any' &&
            optionName !== '' &&
            !this.isValidUUID(optionName)
        ) ? { optionName } : {}
    }
    getDisplayComponent(serviceRequest: ServiceRequest, quantity: number, key: string): React.ReactNode {
        return <MultiplyPricePerQuantityRuleComponent serviceRequest={serviceRequest} rule={this} quantity={quantity} key={key} {...this.getOptionName()}/>
    }

    getUpdateComponent(serviceRequest: ServiceRequest, keySuffix: string, index: number,
                       handleChange: InputAttributes['onChange'],
                       formikArrayHelper: FieldArrayRenderProps,
                       setFieldValue: (field: string, value: any, shouldValidate?: (boolean | undefined)) => void,
                       values: EditQuotationFormikValues): React.ReactNode {
        return <UpdateMultiplyPricePerQuantityRuleComponent serviceRequest={serviceRequest} rule={this}
                                                            key={keySuffix + index} values={values}
                                                            index={index} handleChange={handleChange}
                                                            {...this.getOptionName()}
        />
    }


}

export class GroupByPackRule implements QuotationRule {
    ruleType: QuotationRuleTypeEnum = QuotationRuleTypeEnum.GROUP_BY_PACK
    subRule: QuotationRule
    packSize: number

    constructor(subRule: QuotationRule, packSize: number) {
        this.subRule = getTypedQuotationRule(subRule)
        this.packSize = packSize
    }

    getNumberOfPack(numberOfObjects: number): number {
        if (numberOfObjects % this.packSize === 0) {
            return Math.trunc((numberOfObjects / this.packSize))
        } else {
            return Math.trunc((numberOfObjects / this.packSize) + 1)
        }
    }

    calculate(previousPrice: number, numberOfObjects: number): number {
        const packNumber = this.getNumberOfPack(numberOfObjects)
        return this.subRule.calculate(previousPrice, packNumber)
    }


    getDisplayComponent(serviceRequest: ServiceRequest, quantity: number, key: string): React.ReactNode {
        return <GroupByPackRuleComponent rule={this} quantity={quantity} key={key}/>
    }

    getUpdateComponent(serviceRequest: ServiceRequest, keySuffix: string, index: number, handleChange: InputAttributes['onChange'],
                       formikArrayHelper: FieldArrayRenderProps,
                       setFieldValue: (field: string, value: any, shouldValidate?: (boolean | undefined)) => void,
                       values: EditQuotationFormikValues): React.ReactNode {
        return <UpdateGroupByPackRuleComponent serviceRequest={serviceRequest} rule={this} key={keySuffix + index}
                                               index={index} handleChange={handleChange} setFieldValue={setFieldValue}
                                               values={values}/>
    }

}


export class FixedPriceRule implements QuotationRule {
    ruleType: QuotationRuleTypeEnum = QuotationRuleTypeEnum.FIXED_PRICE
    price: number

    constructor(fixedPrice: number) {
        this.price = fixedPrice
    }

    calculate(previousPrice: number): number {
        return floatRounding(previousPrice + this.price)
    }

    getDisplayComponent(serviceRequest: ServiceRequest, quantity: number, key: string): React.ReactNode {
        return <FixedPriceRuleComponent rule={this} key={key}/>
    }

    getUpdateComponent(serviceRequest: ServiceRequest, keySuffix: string, index: number, handleChange: InputAttributes['onChange']): React.ReactNode {
        return <UpdateFixedPriceRuleComponent serviceRequest={serviceRequest} rule={this} key={keySuffix + index}
                                              index={index} handleChange={handleChange}/>
    }

}

export class ItemPriceRule implements QuotationRule {
    ruleType: QuotationRuleTypeEnum = QuotationRuleTypeEnum.ITEM_PRICE
    price: number
    itemName: string

    constructor(price: number, itemName: string) {
        this.price = price
        this.itemName = itemName
    }

    calculate(previousPrice: number): number {
        return floatRounding(previousPrice + this.price)
    }

    getDisplayComponent(serviceRequest: ServiceRequest, quantity: number, key: string): React.ReactNode {
        return <ItemPriceRuleComponent rule={this} key={key}/>
    }

    getUpdateComponent(serviceRequest: ServiceRequest, keySuffix: string, index: number, handleChange: InputAttributes['onChange']): React.ReactNode {
        return <UpdateItemPriceRuleComponent serviceRequest={serviceRequest} rule={this} key={keySuffix + index}
                                              index={index} handleChange={handleChange}/>
    }

}


export const getTypedQuotationRule = (quotationRule: QuotationRule | undefined, optionName?: string): QuotationRule => {
    const rulePrice = quotationRule?.price ?? 0

    switch (quotationRule?.ruleType) {
        case QuotationRuleTypeEnum.ADJUSTMENT:
            return new AdjustmentRule(
                rulePrice,
                quotationRule.adjustmentReason ?? ''
            )

        case QuotationRuleTypeEnum.MULTIPLY_PRICE_PER_QUANTITY: {
            return new MultiplyPricePerQuantityRule(rulePrice,optionName)
        }

        case QuotationRuleTypeEnum.FIXED_PRICE: {
            const fixedRulePrice = quotationRule?.price ?? 0
            return new FixedPriceRule(fixedRulePrice)
        }

        case QuotationRuleTypeEnum.MIN_QUANTITY: {
            const minQuantity = quotationRule?.minQuantity ?? 0
            const subRule =
                quotationRule?.subRule ?? new MultiplyPricePerQuantityRule(0)
            return new MinQuantityRule(minQuantity, subRule)
        }

        case QuotationRuleTypeEnum.RULE_WITH_QUANTITY: {
            const quantity = quotationRule?.quantity ?? 0
            const optionName = quotationRule?.optionName ?? 'any'
            const subRule =
                quotationRule?.subRule ?? new MultiplyPricePerQuantityRule(0)
            return new RuleWithQuantity(quantity, subRule, optionName)
        }

        case QuotationRuleTypeEnum.GROUP_BY_PACK: {
            const rule = getTypedQuotationRule(quotationRule?.subRule)
            const packSize = quotationRule?.packSize ?? 1
            return new GroupByPackRule(rule, packSize)
        }

        case QuotationRuleTypeEnum.ITEM_PRICE: {
            const itemName = quotationRule?.itemName ?? 'Missing item name'
            return new ItemPriceRule(rulePrice, itemName)
        }
        default:
            return new FixedPriceRule(0)
    }
}

const floatRounding = (number: number): number => {
    try {
        return parseFloat((number).toFixed(2))
    } catch (e) {
        return number
    }
}
