import React from 'react'
import {ROUTES} from '../../util/constants/routing'
import ItemNavigationMenu from '../headerGenericComponents/ItemNavigationMenu'
import {ItemNavigation} from '../headerGenericComponents/ItemNavigation'

const ManagementMenu = () => {

    const data: ItemNavigation[] = [
        {
            label: 'Banners',
            objId: '1',
            url: ROUTES.banner.path ?? '',
            dataTestId: 'banners'
        },
        {
            label: 'News & Announcements',
            objId: '2',
            url: ROUTES.news.path ?? '',
            dataTestId: 'news'
        },
    ]

    return (
        <ItemNavigationMenu
            label="Portal Management"
            subRoute="/portal-management"
            itemList={data}
        />
    )
}

export default ManagementMenu
