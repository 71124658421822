enum UserRequestStatusEnum {
  CREATION_PENDING = 'CREATION_PENDING',
  CREATION_ERROR = 'CREATION_ERROR',
  AWAITING_APPROVAL = 'AWAITING_APPROVAL',
  AWAITING_QUOTATION = 'AWAITING_QUOTATION',
  REQUEST_REJECTED = 'REQUEST_REJECTED',
  AWAITING_QUOTATION_APPROVAL = 'AWAITING_QUOTATION_APPROVAL',
  QUOTATION_APPROVED = 'QUOTATION_APPROVED',
  QUOTATION_REJECTED = 'QUOTATION_REJECTED',
  DELIVERED = 'DELIVERED',
}

export default UserRequestStatusEnum
