import React from 'react'
import {GroupByPackRule} from '../QuotationRule'
import {ServiceRequest} from '../../ServiceRequest'
import {FormControl, TextField} from '@mui/material'
import {InputAttributes} from 'react-number-format'
import {EditQuotationFormikValues} from '../../../ServiceRequestsTable/UpdateQuotation/EditQuotation'

export const UpdateGroupByPackRuleComponent = ({rule, serviceRequest, index, handleChange, setFieldValue, values}: {
    rule: GroupByPackRule,
    serviceRequest: ServiceRequest,
    index: number,
    handleChange: InputAttributes['onChange'],
    values: EditQuotationFormikValues,
    setFieldValue: (field: string, value: any, shouldValidate?: (boolean | undefined)) => void

}) => {
    const quantity = values.genericRules[index]?.quantity ?? 0
    const subTotal = rule.calculate(0, quantity)
    const packNumber = rule.getNumberOfPack(quantity)

    const updatePackNumber = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        try {
            const updatedNumberOfObjects = Number(event?.target?.value) * rule.packSize - 1
            setFieldValue(`genericRules.${index}.quantity`, updatedNumberOfObjects)
        } catch (error) {
            console.log('error during calculation of pack number:', error)
        }

    }
    return <div className="quotation-detail-rule-structure">
        <p className="quotation-detail-description">Extraction of 100 items</p>
        <FormControl className="quotation-detail-unit-price">

            <TextField
                inputProps={{style: {textAlign: 'right'}}}
                name={`genericRules.${index}.subRule.subRule.subRule.price`}
                type="number"
                value={rule.subRule?.subRule?.price}
                sx={{width: 1}}
                size="small"
                required
                placeholder="eg. 10"
                error={(rule?.subRule?.subRule?.price ?? 0) < 0}
                helperText={(rule?.subRule?.subRule?.price ?? 0) < 0 ? 'Price should be >= 0' : ''}
                onChange={handleChange}/>
        </FormControl>
        <FormControl className="quotation-detail-nb-object">
            <TextField
                inputProps={{style: {textAlign: 'right'}}}
                name={'packNumber'}
                type="number"
                value={packNumber}
                sx={{width: 1}}
                size="small"
                required
                placeholder="eg. 10"
                error={quantity < 0}
                helperText={quantity < 0 ? 'Number of pack should be >= 0' : ''}
                onChange={updatePackNumber}/>
        </FormControl>
        <p className="quotation-detail-total">{subTotal}</p>
    </div>
}