import {Checkbox, FormControlLabel, InputAdornment, MenuItem, Select, TextField} from '@mui/material'
import {GridCellParams, GridColDef, GridFilterItem, GridFilterOperator, GridSearchIcon} from '@mui/x-data-grid'
import {useMemo, useState} from 'react'

const EqualsOperatorInput = ({colDef, getPossibleValues, filters, setFilter, operator}: 
    {colDef : GridColDef, getPossibleValues: (col : GridColDef) => any[], filters: any[], setFilter : (filter :GridFilterItem) => void, operator : string} ) => {
    
    const [sub, setSub] = useState<string>('')
    
    const existingValues = useMemo( () => getPossibleValues(colDef).filter((value) =>{
        return value.toString().toLowerCase().includes(sub.toLowerCase())
    }), [colDef, getPossibleValues, sub])

    return <div className="datx-list-filter">
        <TextField
            value={sub}
            onChange={(e) => setSub(e.target.value)}
            variant="outlined"
            margin="normal"
            InputLabelProps={{
                classes: {
                    root: 'subFilterInput',
                },
            }}
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        <GridSearchIcon className="search-icon"/>
                    </InputAdornment>
                ),
            }}
            placeholder={colDef.headerName ?? colDef.field}
        />
        <div className="filter-checkboxes"> 
            {existingValues.map(fil => {
                return <FormControlLabel
                    key={fil}
                    control={<Checkbox
                        value={fil}
                        checked={filters.includes(fil)}
                        onChange={(e, checked) => {
                            setFilter({
                                id: colDef.field,
                                field: colDef.field,
                                operator: operator,
                                value: checked ? [...filters, fil] : filters.filter((filter: string) => filter !== fil)
                            })
                    }}/>}
                    label={fil}
                />})
            }
        </div>
    </div>
}

const SelectOperatorInput = ({colDef, getPossibleValues, filters, setFilter, operator}: 
    {colDef : GridColDef, getPossibleValues: (col : GridColDef) => any[], filters: any[], setFilter : (filter :GridFilterItem) => void, operator: string} ) => {
    
        const existingValues = useMemo( () => getPossibleValues(colDef), [colDef, getPossibleValues])

        return (
            <Select
                onChange={(e) => {
                    setFilter({
                        id: colDef.field,
                        field: colDef.field,
                        operator: operator,
                        value: [e.target.value]
                    })}}
                
                value={filters.length ? filters[0] : ''}
                displayEmpty
            >
                <MenuItem value="">No Filter</MenuItem>
                {existingValues.map(value => {
                    return <MenuItem key={value} value={value}>{value}</MenuItem>
                })}
            </Select>
    )
}

const MatchStringOperatorInput = ({colDef, getPossibleValues, filters, setFilter, operator}: 
    {colDef : GridColDef, getPossibleValues: (col : GridColDef) => any[], filters: any[], setFilter : (filter :GridFilterItem) => void, operator: string} ) => {
    return (
        <TextField
            value={filters[0] || ''}
            onChange={(e) => setFilter({
                id: colDef.field,
                field: colDef.field,
                operator: operator,
                value: [e.target.value]
            })}
            variant="outlined"
            margin="normal"
            InputLabelProps={{
                classes: {
                    root: 'subFilterInput',
                },
            }}
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        <GridSearchIcon className="search-icon"/>
                    </InputAdornment>
                ),
            }}
            placeholder={colDef.headerName ?? colDef.field}
        />
    )
}

export const equalsOperator : GridFilterOperator = {
    label : 'equals',
    value : 'equals',
    getApplyFilterFn: (filterItem, column) => {
        if(!filterItem.field || ! filterItem.value || !filterItem.operator || !filterItem.value.length){
            return null
        }

       return (params: GridCellParams) => {
            return filterItem.value.some( (filterValue : string) => String(params.value) === filterValue)      
       }
    },
    InputComponent: EqualsOperatorInput
}

export const comboBoxSelect : GridFilterOperator = {
    label: 'comboSelect',
    value: 'comboSelect',
    getApplyFilterFn: (filterItem, column) => {
        if(!filterItem.field || !filterItem.value || !filterItem.operator || !filterItem.value.length || !filterItem.value[0]){
            return null
        }
        return (params : GridCellParams) => {
           return filterItem.value === String(params.value)
        }
    },
    InputComponent: SelectOperatorInput
}

export const matchString : GridFilterOperator = {
    label: 'matchString',
    value: 'matchString',
    getApplyFilterFn: (filterItem, column) => {
        if(!filterItem.field || !filterItem.value || !filterItem.operator || !filterItem.value.length || !filterItem.value[0]){
            return null
        }

        return (params: GridCellParams) => {
            return String(params.value).toLowerCase().includes(filterItem.value[0].toLowerCase())
        }
    },
    InputComponent : MatchStringOperatorInput
}


 export const operatorMap : { [key:string] : any} = {
    'equals' : equalsOperator,
    'comboSelect' : comboBoxSelect,
    'matchString' : matchString
}