import CustomModal from '../../../genericComponents/customModal/CustomModal'
import InfoRoundedIcon from '@mui/icons-material/InfoRounded'
import {LoadingButton} from '@mui/lab'

import '../ServiceRequestActions/ServiceRequestActions.scss'
import {useState} from 'react'
import {Button} from '@mui/material'
import {ServiceRequest} from '../../models/ServiceRequest'
import {useDeliverServiceRequestMutation} from '../../rtkServiceRequestApi'

const SendDeliveredModal = ({
                                afterClose,
                                serviceRequest
                            }: {
    afterClose: () => void,
    serviceRequest: ServiceRequest
}) => {
    const [open, setOpen] = useState<boolean>(true)
    const [updateServiceRequestStatusToDelivered, updateServiceRequestStatusToDeliveredResult] = useDeliverServiceRequestMutation()

    const handleDeliveredForm = () => {
        updateServiceRequestStatusToDelivered(serviceRequest.id).then(() => {
            close()
        })
    }

    const close = () => {
        setOpen(false)
        afterClose()
    }

    return <>
        {open && <CustomModal handleClose={close}
                              title={<>
                                  <InfoRoundedIcon color={'primary'}/>
                                  <span>Mark as Delivered</span>
                              </>}

                              body={'Confirm mark as delivered?'}

                              actions={<>
                                  <Button color="primary" type="button" className="cancel" onClick={close}>
                                      Cancel
                                  </Button>
                                  <LoadingButton variant="contained" color="primary" loading={updateServiceRequestStatusToDeliveredResult.isLoading} onClick={handleDeliveredForm}>
                                      Confirm
                                  </LoadingButton>
                              </>}
        >

        </CustomModal>
        }
    </>
}

export default SendDeliveredModal
