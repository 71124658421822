import React, {useEffect, useState} from 'react'
import {Menu} from '@mui/material'
import {useAccount, useMsal} from '@azure/msal-react'
import {getEmailFromMsal} from '../../authentication/AuthHooksAndFunctions'
import {LocalStorage} from '../../util/localStorage'
import CustomHeaderButton from '../headerGenericComponents/CustomHeaderButton'

import {ExitToApp} from '@mui/icons-material'
import IconMenuItem from '../../genericComponents/customMenu/IconMenuItem'

const UserMenu = () => {
    const { instance } = useMsal()
    const accountInfo = useAccount()
    const [email, setEmail] = useState(getEmailFromMsal(instance))
    const [anchorEl, setAnchorEl] = useState<Element|null>(null)
    const open = Boolean(anchorEl)
    const handleClick = (e: React.MouseEvent) => setAnchorEl(e.currentTarget)
    const handleClose = () => setAnchorEl(null)

    useEffect(() => {
        setEmail(getEmailFromMsal(instance))
    }, [instance, accountInfo])

    const logoutRequest = {
        idTokenHint: instance?.getActiveAccount()?.idToken,
    }

    const handleLogout = () => {
        LocalStorage.setUserEmail('')
        instance.logoutRedirect(logoutRequest)
        handleClose()
    }

    return (
        <div>
            <CustomHeaderButton
                onClick={handleClick}
            >
                <div className="dropDownButton" >
                    <p className="emailLabel" data-testid="user-email-label">{email}</p>
                </div>

            </CustomHeaderButton>
            <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
                <IconMenuItem
                    onClick={handleLogout}
                    leftIcon={<ExitToApp />}
                    label="Logout"
                />
            </Menu>
        </div>
    )
}

export default UserMenu
