import React, {useState} from 'react'
import UpdateDetailsModal from '../Modals/UpdateDetailsModal'
import ServiceRequestActionsOptions from '../../models/ServiceRequestActionsOptions'
import {ServiceRequest} from '../../models/ServiceRequest'
import SendDeliveredModal from '../Modals/SendDeliveredModal'
import UserRequestStatusEnum from '../../models/UserRequestStatusEnum'
import {IconButton, Menu, MenuItem} from '@mui/material'
import {MoreHoriz} from '@mui/icons-material'
import ServiceSubscriptionEnum from '../../models/ServiceEnum'
import {useAwaitingQuotationApprovalMutation} from '../../rtkServiceRequestApi'
import {useDispatch} from 'react-redux'

const ServiceRequestActions = ({
                                   serviceRequest
                               }: {
    serviceRequest: ServiceRequest;
}) => {
    const [option, setOption] = useState<string>('')
    const [anchorEl, setAnchorEl] = useState<Element | null>(null)
    const [updateQuotationStatusAwaitingApproval, updateQuotationResult] = useAwaitingQuotationApprovalMutation()
    const dispatch = useDispatch()

    const open = Boolean(anchorEl)

    const afterClose = () => {
        setOption('')
        closeMenu()
    }

    const handleServiceRequestStatusUpdate = () => {
        updateQuotationStatusAwaitingApproval(serviceRequest?.id).then(closeMenu)
    }

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget)
    }

    const closeMenu = () => {
        setAnchorEl(null)
    }

    const isNotExtractionService = serviceRequest.service !== ServiceSubscriptionEnum.ONE_TIME_EXTRACTION &&
        serviceRequest.service !== ServiceSubscriptionEnum.RECURRING_EXTRACTION

    return <>
        <IconButton onClick={handleClick}>
            <MoreHoriz />
        </IconButton>
        <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={closeMenu}
        >
            {isNotExtractionService &&
                <MenuItem
                    onClick={() => setOption(ServiceRequestActionsOptions.UPDATE_DETAILS)}
                    disabled={serviceRequest.status !== UserRequestStatusEnum.AWAITING_QUOTATION}
                >
                    Update Details
                </MenuItem>
            }
            <MenuItem
                onClick={() => handleServiceRequestStatusUpdate()}
                disabled={serviceRequest.status !== UserRequestStatusEnum.AWAITING_QUOTATION || updateQuotationResult.isLoading}
            >
                Send Quotation
            </MenuItem>
            <MenuItem
                onClick={() => setOption(ServiceRequestActionsOptions.DELIVERED)}
                disabled={serviceRequest.status !== UserRequestStatusEnum.QUOTATION_APPROVED}
            >
                Mark as Delivered
            </MenuItem>
        </Menu>
        {option === ServiceRequestActionsOptions.UPDATE_DETAILS &&
            <UpdateDetailsModal afterClose={afterClose} serviceRequest={serviceRequest} />}
        {option === ServiceRequestActionsOptions.DELIVERED &&
            <SendDeliveredModal afterClose={afterClose} serviceRequest={serviceRequest} />}
    </>
}

export default ServiceRequestActions
