import {
    Attachment,
    CalendarMonth,
    Category,
    FolderShared,
    ListAlt,
    Lock,
    PlusOne,
    QuestionMark,
    Rule,
    Share,
    TextFields
} from '@mui/icons-material'

export interface DefinitionModel {
    id: string,
    name: string,
    code: string,
    templateId?: string,
    unit?: string,
    type: DefinitionTypeModel,
    isPublic: boolean
}

export interface DefinitionTypeModel {
    attributeDefinitionType: string
}


const iconDefinitionTypeMap = new Map([
    ['COMPLEX', <ListAlt></ListAlt>],
    ['STRING', <TextFields></TextFields>],
    ['TEXT', <TextFields></TextFields>],
    ['INTEGER', <PlusOne></PlusOne>],
    ['NUMBER', <PlusOne></PlusOne>],
    ['LINK', <Share></Share>],
    ['BOOLEAN', <Rule></Rule>],
    ['ARTIFACT', <Attachment></Attachment>],
    ['CLASSIFICATION', <Category></Category>],
    ['SHARED_PROFILE', <FolderShared></FolderShared>],
    ['DATE', <CalendarMonth></CalendarMonth>]
])

export const IconForDefinitionType = ({attributeDefinitionType}: { attributeDefinitionType: string }) => {
    return <span title={attributeDefinitionType}>{iconDefinitionTypeMap.get(attributeDefinitionType) ??
        <QuestionMark></QuestionMark>}</span>
}

export const IconForPrivacy = ({isPublic}: { isPublic: boolean }) => {
    if (isPublic) return <></>
    return <Lock></Lock>
}