import {DefinitionModel, IconForDefinitionType, IconForPrivacy} from '../../definitions/model/DefinitionModel'
import {InformationPackageTreeItem} from '../../informationPackages/model/InformationPackageModel'


export const DefinitionForDisplaySetTree = ({node, definition}:
                                                {
                                                    node: InformationPackageTreeItem,
                                                    definition: DefinitionModel
                                                }) => {

    let template = definition?.templateId ?? ''
    if (definition?.templateId === 'DEFAULT')
        template = ''

    return <div className={'definition-for-tree'} title={definition.code + ' - ' + definition.name}>
        <IconForDefinitionType
            attributeDefinitionType={definition.type.attributeDefinitionType}></IconForDefinitionType>
        <IconForPrivacy isPublic={definition.isPublic}></IconForPrivacy>
        <span className="definition-name">
            {definition.name} - {definition.code}
            {definition?.unit ? ` (unit: ${definition.unit})` : ''}
            {template ? `  -  ${definition.templateId}` : ''}
        </span>
    </div>
}