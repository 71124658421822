import './EditGuild.scss'
import CardContainer from '../../genericComponents/cardContainer/CardContainer'
import DaTXDataGrid from '../../genericComponents/grid/DaTXDataGrid'
import {useGetGuildChangelogQuery} from '../rtkGuildApi'
import {useParams} from 'react-router'
import {GridColDef} from '@mui/x-data-grid'
import React from 'react'
import {formatDateTime} from '../../genericComponents/utils/DateService'
import {ChangeLog} from '../models/ChangeLog'
import {getTransactionTypeName} from '../models/TransactionTypeEnum'

const GuildChangeLog = () => {
    const {guildId} = useParams()
    const {data: changelogs, isLoading} = useGetGuildChangelogQuery({ guildId })

    const getLastUpdated = ({row}: { row: ChangeLog }) => {
        if (!row.createdDate) return '-'
        return formatDateTime(row.createdDate)
    }

    const getUserEmail = ({row}: { row: ChangeLog }) => {
        return row?.admin?.email
    }

    const getTransactionType = ({row}: { row: ChangeLog }) => {
        return getTransactionTypeName(row.type)
    }

    const getPreviousValue = ({row}: { row: ChangeLog }) => {
        return row?.previousBudget.toLocaleString('fr') + ' Credits'
    }

    const getNewValue = ({row}: { row: ChangeLog }) => {
        return row?.newBudget.toLocaleString('fr') + ' Credits'
    }

    const getReason = ({row}: { row: ChangeLog }) => {
        if (!row.reason || row.reason?.length === 0) return '-'
        return row.reason
    }

    const columns: GridColDef[] = [
        {
            field: 'createdDate',
            headerName: 'Last Updated',
            minWidth: 90,
            valueGetter: getLastUpdated,
            sortable: false,
            filterable: false
        },
        {
            field: 'user',
            headerName: 'Admin ID',
            minWidth: 240,
            valueGetter: getUserEmail,
            sortable: false,
            filterable: false
        },
        {
            field: 'type',
            headerName: 'Updated Data',
            minWidth: 216,
            valueGetter: getTransactionType,
            sortable: false,
            filterable: false
        },
        {
            field: 'previousBudget',
            headerName: 'Previous Value',
            flex: 1,
            minWidth: 184,
            valueGetter: getPreviousValue,
            sortable: false,
            filterable: false
        },
        {
            field: 'newBudget',
            headerName: 'New Value',
            flex: 1,
            minWidth: 184,
            valueGetter: getNewValue,
            sortable: false,
            filterable: false
        },
        {
            field: 'reason',
            headerName: 'Reason',
            flex: 1,
            minWidth: 150,
            valueGetter: getReason,
            sortable: false,
            filterable: false
        }
    ]

    return <CardContainer contentClassName="guild-change-log" fullWidth>
        <div className="change-log-container">
            <label className="card-title-large">Change Log</label>
            <DaTXDataGrid
                isLoading={isLoading}
                emptyRowMessage="No transactions found"
                columns={columns}
                rows={changelogs ?? []}
                getRowId={(changelog: ChangeLog) => changelog.id}
                rowHeight={70}
            >
            </DaTXDataGrid>
        </div>
    </CardContainer>
}

export default GuildChangeLog