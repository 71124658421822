import './common/BannerForm.scss'
import BannerType from '../model/BannerType'
import {Banner} from '../model/Banner'
import BannerForm from './common/BannerForm'
import {useCreateBannerMutation} from '../rtkBannerApi'

const CreateBanner = () => {

    const bannerTitle = 'Add Banner'
    const dialogText = 'Banner has been successfully created, you may edit or add banner post under Portal Management. Newly created Banners are automatically published.'

    const [useCreateBanner, useCreateBannerResult] = useCreateBannerMutation()

    const HandleUpdate = (banner: Banner) => {
        useCreateBanner(banner)
    }

    const initialBanner: Banner = {
        id : '',
        title : '',
        content : '',
        type: '' as BannerType,
        enabled: true,
        displayOrder: 1
    }

    return<>
        <BannerForm
            selectParams = {{}}
            formTitle = {bannerTitle}
            dialogBodyText = {dialogText}
            handlePublish = {HandleUpdate}
            initialBanner = {initialBanner}
            loading = {useCreateBannerResult.isLoading}
        />
    </>
}

export default CreateBanner