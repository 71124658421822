import CardContainer from '../../genericComponents/cardContainer/CardContainer'
import EditButton from '../../genericComponents/EditButton/EditButton'
import React, { useState } from 'react'
import EditLinkOrganization from './linkOrganization/EditLinkOrganization'
import { Guild } from '../models/guild'
import { useGetTCISOrganizationQuery } from '../../Search/rtkSearchApi'
import { Skeleton } from '@mui/material'
import { EditGuildDetailTypeEnum } from '../models/EditGuildDetailTypeEnum'

const GuildLinkedOrganisationCard = ({guild}: {guild: Guild}) => {
    const [showEditModal, setShowEditModal] = useState(false)

    const {data:organizations = [] , isFetching: isOrganizationsLoading} = useGetTCISOrganizationQuery()

    const findOrganization = (organizationId: string) => {
        if (organizationId) {
            return organizations.find(org => org.id === organizationId)
        }
    }

    const openEditModal = () => setShowEditModal(true)
    const closeEditModal = () => setShowEditModal(false)

    const onEditButtonClick = () => {
        openEditModal()
    }

    const currentLinkedOrganisation = findOrganization(guild.defaultOrganization)
    const currentLinkedOrganisationName = currentLinkedOrganisation?.label ?? '-'

    return <CardContainer contentClassName="guild-linked-organisation-edit-group" fullWidth>
        <div className="guild-linked-organisation">
            <label className="card-title">Linked Organisation</label>
            {isOrganizationsLoading ?
                <Skeleton variant="rounded" className="guild-linked-organisation-skeleton" /> :
                <label className="card-value">{currentLinkedOrganisationName}</label>
            }
        </div>
        <EditButton onEditClick={onEditButtonClick} />
        <EditLinkOrganization guild={guild} isOpen={showEditModal}
                              onClose={closeEditModal} detailType={EditGuildDetailTypeEnum.LINKED_ORGANISATION}
                              currentLinkedOrganisation={currentLinkedOrganisation}
        />
    </CardContainer>
}

export default GuildLinkedOrganisationCard