import React from 'react'
import {MinQuantityRule} from '../QuotationRule'
import {ServiceRequest} from '../../ServiceRequest'
import {FormControl, TextField} from '@mui/material'
import {NumericFormat, InputAttributes} from 'react-number-format'
import {EditQuotationFormikValues} from '../../../ServiceRequestsTable/UpdateQuotation/EditQuotation'

export const UpdateMinQuantityRuleComponent = ({rule, serviceRequest, index, handleChange, values}: {
    rule: MinQuantityRule,
    serviceRequest: ServiceRequest,
    index: number,
    handleChange: InputAttributes['onChange'],
    values: EditQuotationFormikValues
}) => {
    const quantity = values.genericRules[index]?.quantity ?? 0
    const subTotal = rule.calculate(0, quantity)

    return <div className="quotation-detail-rule-structure">
        <p className="quotation-detail-description">Number of items</p>
        <FormControl className="quotation-detail-unit-price">
            <TextField
                inputProps={{style: {textAlign: 'right'}}}
                name={`genericRules.${index}.subRule.subRule.price`}
                type="number"
                value={rule.subRule?.price}
                sx={{width: 1}}
                size="small"
                required
                placeholder="eg. 10"
                error={(rule.subRule?.price ?? 0) < 0}
                helperText={(rule.subRule?.price ?? 0) < 0 ? 'Price should be >= 0': ''}
                onChange={handleChange}/>
        </FormControl>
        <FormControl className="quotation-detail-nb-object">
            <TextField
                inputProps={{style: {textAlign: 'right'}}}
                name={`genericRules.${index}.quantity`}
                type="number"
                value={quantity}
                sx={{width: 1}}
                size="small"
                required
                placeholder="eg. 10"
                error={quantity < 0}
                helperText={quantity < 0 ? 'Number of object should be >= 0': ''}
                onChange={handleChange}/>
        </FormControl>
        <p className="quotation-detail-min-quantity">{rule.minQuantity}</p>
        <p className="quotation-detail-total">{subTotal}</p>
    </div>
}