import {Dialog as MuiDialog, IconButton,} from '@mui/material'
import {Close} from '@mui/icons-material'
import './UpdateQuotationDialog.scss'
import QuotationDetail from './QuotationDetail'
import {ServiceRequest} from '../../models/ServiceRequest'

function UpdateQuotationDialog({
                          className,
                          title,
                          open,
                          onClose,
                          selectedServiceRequest,
                      }: {
    className: string,
    title: string,
    open: boolean,
    onClose: () => void,
    selectedServiceRequest?: ServiceRequest,
}) {

    return (
        <MuiDialog
            open={open}
            onClose={onClose}
            classes={{
                root: 'update-quotation-dialog-root',
                paper: 'update-quotation-dialog-container',
            }}
            className={className}
            onClick = {(event)=>
                event.stopPropagation()
            }
            fullWidth
            maxWidth="lg"
        >
            <div className="update-quotation-dialog">
                <div className="dialog-header">
                    <div className="dialog-title">{title}</div>
                    <IconButton className="dialog-close" onClick={onClose}>
                        <Close/>
                    </IconButton>
                </div>
                <div>
                    {selectedServiceRequest && (
                        <QuotationDetail serviceRequest={selectedServiceRequest}/>
                    )}
                </div>
            </div>
        </MuiDialog>
    )
}

export default UpdateQuotationDialog
