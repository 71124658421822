import {GridValueGetterParams} from '@mui/x-data-grid'
import React from 'react'
import {FactoryUser} from '../../factoryUser/CreateFactoryUser/model/FactoryUser'
import {formatDate, formatMilliseconds, formatMonth, formatUtcDateToLocal} from '../utils/DateService'
import {getUserTypeDisplayName, UserRoleEnum} from '../../factoryUser/CreateFactoryUser/model/UserRoleEnum'


export const getRoleLabel = (params: GridValueGetterParams): string => {
    if (!params.row?.roles || params.row.roles.length === 0) {
        return ''
    }
    const role = params.row.roles[0] as keyof typeof UserRoleEnum
    const displayName = getUserTypeDisplayName[UserRoleEnum[role]]
    return displayName ?? '-'
}


export const formatRole = ({ row }: { row: { roles: UserRoleEnum  } }) => {
    const role = row.roles[0] as keyof typeof UserRoleEnum
    const displayName = getUserTypeDisplayName[UserRoleEnum[role]]
    return formatString(displayName ?? '-')
}

const formatString = ( label: string ) => {
    if (!label) {
        return '-'
    }
    return <span title={label} className="label-format">{label}</span>
}

export const formatEmail = ({row}: { row: FactoryUser }) => {
    return formatString(row?.email)
}

export const getRequesterEmail = (params : GridValueGetterParams) => {
    return params.row?.email
}
export const getFormattedDate = (date: GridValueGetterParams) => {
    const formattedDate = [...date.value]
    const dateToDisplay = formatMilliseconds(formattedDate)
    formatMonth(dateToDisplay)
    if (formattedDate) {
        return formatDate(formattedDate)
    }
}

