import './ActiveGuildsTable.scss'
import React, {useState} from 'react'
import {GridColDef, GridRenderCellParams, GridValueGetterParams} from '@mui/x-data-grid'
import {useNavigate} from 'react-router-dom'
import {IconButton, Menu, MenuItem} from '@mui/material'
import {Guild} from '../models/guild'
import {ROUTES} from '../../util/constants/routing'
import {MoreHoriz} from '@mui/icons-material'
import {NumericFormat} from 'react-number-format'
import {CREDITS} from '../../constants/credits'
import {useGetActiveGuildsQuery} from '../rtkGuildApi'
import DaTXDataGrid from '../../genericComponents/grid/DaTXDataGrid'
import {matchString} from '../../genericComponents/grid/gridFilterOperators'

const ActiveGuildRequestTable = () => {
    const {data: guilds = [], isLoading} = useGetActiveGuildsQuery()

    const getEmail = (params: GridValueGetterParams) => {
        return params?.row?.guildUsers[0]?.user?.email
    }

    const getCreditHeader = (headerName: string) => (<>
        <strong>{headerName}</strong>
        <div className="caption">({CREDITS})</div>
    </>)

    const getRemainingBudget = (params: GridRenderCellParams) => {
        return (
            <NumericFormat
                value={params?.row?.budget}
                displayType={'text'}
                thousandSeparator={' '}
                decimalSeparator="."
            />
        )
    }

    const getMembersTotal = (params: GridRenderCellParams) => {
        const membersCount = params?.row.numberOfUsers
        const maxUsersInGuild = params?.row.maxUsersInGuild
        return <div data-testid="members-count">{membersCount} / {maxUsersInGuild}</div>
    }

    const sortMember = (v1: any, v2: any ): number => {
        return v1.props.children[0]-v2.props.children[0]
    }

    const getGuildId = ({row}: { row: Guild }) => {
        return row?.id
    }

    const getContractId = ({row}: { row: Guild }) => {
        return row?.contractId
    }

    const getContractOwner = ({row}: { row: Guild }) => {
        return row?.contractOwner
    }

    const getGuildName = ({row}: { row: Guild }) => {
        return row?.name
    }

    const columns: GridColDef[] = [
        {
            field: 'id',
            headerName: 'Guild Id',
            flex: 2,
            minWidth: 250,
            sortable: true,
            valueGetter: getGuildId,
            filterOperators: [matchString]
        },
        {
            field: 'contractId',
            headerName: 'Contract Id',
            flex: 1,
            minWidth: 200,
            sortable: true,
            valueGetter: getContractId,
            filterOperators: [matchString]
        },
        {
            field: 'contractOwner',
            headerName: 'Contract Owner',
            flex: 1,
            minWidth: 200,
            sortable: true,
            valueGetter: getContractOwner,
            filterOperators: [matchString]
        },
        {
            field: 'admin',
            headerName: 'Guild Admin',
            flex: 1,
            minWidth: 150,
            sortable: true,
            valueGetter: getEmail,
            filterOperators: [matchString]
        },
        {
            field: 'name',
            headerName: 'Guild Name',
            flex: 1,
            minWidth: 150,
            sortable: true,
            valueGetter: getGuildName,
            filterOperators: [matchString]
        },
        {
            field: 'numberOfUsers',
            headerName: 'Guild Capacity',
            flex: 1,
            minWidth: 150,
            sortable: true,
            filterable: false,
            renderCell: getMembersTotal,
            valueGetter: getMembersTotal,
            sortComparator: (v1, v2) => sortMember(v1,v2)
        },
        {
            field: 'budget',
            renderHeader: () => getCreditHeader('Remaining Budget'),
            flex: 0.5,
            minWidth: 150,
            align: 'right',
            sortable: true,
            filterable: false,
            sortComparator: (v1, v2) => v1 - v2,
            renderCell: getRemainingBudget,
        },
        {
            field: 'actions',
            headerName: 'Actions',
            flex: 0.5,
            minWidth: 50,
            sortable: false,
            disableColumnMenu: true,
            renderCell: (params) => <ActionMenu row={params?.row}/>,
        },
    ]

    return (
        <>
            <h1 className="page-title">Active Guilds</h1>

            <div className="active-guilds-grid-container">
                <DaTXDataGrid
                    emptyRowMessage="No Active Guilds Found"
                    isLoading={isLoading}
                    columns={columns}
                    rows={guilds}
                    getRowId={(guild: Guild) => guild?.id}
                >
                </DaTXDataGrid>
            </div>
        </>
    )
}

const ActionMenu = ({row}: { row: any }) => {
    const [anchorEl, setAnchorEl] = useState<Element | null>(null)
    const open = Boolean(anchorEl)
    const navigate = useNavigate()

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget)
    }

    const closeMenu = () => {
        setAnchorEl(null)
    }

    return (<>
            <IconButton onClick={handleClick}>
                <MoreHoriz/>
            </IconButton>
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={closeMenu}
            >
                <MenuItem onClick={() => navigate(ROUTES.editGuild.path + row?.id)}>
                    Edit
                </MenuItem>
            </Menu>
        </>
    )
}

export default ActiveGuildRequestTable
