import React from 'react'
import {FixedPriceRule, MinQuantityRule} from '../QuotationRule'
import {ServiceRequest} from '../../ServiceRequest'
import {FormControl, TextField} from '@mui/material'
import {NumericFormat, InputAttributes} from 'react-number-format'

export const UpdateFixedPriceRuleComponent = ({rule, serviceRequest, index, handleChange}: {
    rule: FixedPriceRule,
    serviceRequest: ServiceRequest,
    index: number,
    handleChange: InputAttributes['onChange']
}) => {
    const subTotal = rule.calculate(0)

    return <div className="quotation-detail-rule-structure">
        <p className="quotation-detail-description">Fixed Price</p>
        <FormControl className="quotation-detail-unit-price">
            <TextField
                inputProps={{style: {textAlign: 'right'}}}
                name={`genericRules.${index}.price`}
                type="number"
                value={rule.price}
                sx={{width: 1}}
                size="small"
                required
                placeholder="eg. 10"
                error={rule.price < 0}
                helperText={rule.price < 0 ? 'Price should be >= 0': ''}
                onChange={handleChange}/>
        </FormControl>
        <p className="quotation-detail-total">{subTotal}</p>
    </div>
}