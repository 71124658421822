import { IconButton } from '@mui/material'
import { ArrowBackIosNew } from '@mui/icons-material'
import './BackButton.scss'

const BackButton = ({text, onClick}: {text: string, onClick: () => void}) => {
    return <div className="back-button-container" onClick={onClick}>
        <IconButton><ArrowBackIosNew className="back-button" /></IconButton>
        <span className="back-button-text">{text}</span>
    </div>
}

export default BackButton