import ServiceSubscriptionEnum, { getServiceName } from '../../ServiceRequests/models/ServiceEnum'

enum TransactionAdditionalTypes {
    ADD_CREDITS = 'ADD_CREDITS'
}

const transactionTypeNames = {
    [TransactionAdditionalTypes.ADD_CREDITS]: {
        title: 'Credit Top Up'
    }
}

export type TransactionTypeEnum = ServiceSubscriptionEnum | TransactionAdditionalTypes

export const getTransactionTypeName = (value: TransactionTypeEnum | string): string => {
    return (transactionTypeNames as any)[value]?.title ?? getServiceName(value)
}