import React from 'react'
import {ROUTES} from '../../util/constants/routing'
import ItemNavigationMenu from '../headerGenericComponents/ItemNavigationMenu'
import {ItemNavigation} from '../headerGenericComponents/ItemNavigation'

const GuildMenu = () => {
    const data: ItemNavigation[] = [
        {
            label: 'Creation Requests',
            objId: '1',
            url: ROUTES.guildRequest.path ?? '',
            dataTestId: 'creation-requests'
        },
        {
            label: 'Active Guilds',
            objId: '2',
            url: ROUTES.activeGuilds.path ?? '',
            dataTestId: 'active-guilds'
        },
    ]

    return (
        <ItemNavigationMenu
            label="Guild Management"
            // icon={<SupervisorAccountOutlined />}
            subRoute="/guilds"
            itemList={data}
        />
    )
}

export default GuildMenu
