import {MoreHoriz} from '@mui/icons-material'
import {IconButton, Menu, MenuItem} from '@mui/material'
import React, {useState} from 'react'
import {ROUTES} from '../../util/constants/routing'
import {generatePath, Link} from 'react-router-dom'
import {News} from '../model/News'
import {useDeleteNewsMutation, useUpdateNewsMutation} from '../rtkNewsApi'
import {useTypedDispatch} from '../../util/store'
import {setSnackbarMessage} from '../../util/commonSlice'

const NewsActions = (
    {
        currentNews,
    }: {
        currentNews: News
    }
) => {
    const dispatch = useTypedDispatch()
    const [anchorEl, setAnchorEl] = useState<Element | null>(null)
    const open = Boolean(anchorEl)
    const [publishDisable, setPublishDisable] = useState(false)
    const [deleteDisable, setDeleteDisable] = useState(false)
    const [useUpdateNews ] = useUpdateNewsMutation()
    const [useDeleteNews] = useDeleteNewsMutation()

    const publishStatus = (currentNews.enabled) ? 'Unpublish' : 'Publish'

    const handleClose= (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget)
    }
    const closeMenu = () => {
        setAnchorEl(null)
    }

    const HandleStatus = async () => {
        const newNews = {
            ...currentNews,
            enabled: !currentNews.enabled
        }
        setPublishDisable(true)
        const result = await useUpdateNews(newNews)
        if ('data' in result) {
            setPublishDisable(false)
            dispatch(setSnackbarMessage('News & Announcement '+ publishStatus + 'ed'))
        }
        else {
            setPublishDisable(false)
            dispatch(setSnackbarMessage('Failed to ' + publishStatus + ' News & Announcement'))
        }
        closeMenu()
    }

    const HandleDelete = async () => {
        const result = await useDeleteNews(currentNews.id ?? '')
        setDeleteDisable(true)
        if ('data' in result) {
            setDeleteDisable(false)
            dispatch(setSnackbarMessage('News & Announcement Deleted'))
        }
        else {
            setDeleteDisable(false)
            dispatch(setSnackbarMessage('Failed to Delete News & Announcement'))
        }
        closeMenu()
    }

    return <>
        <IconButton
            onClick = {handleClose}
            data-testid = "news-actions-button"
        >
            <MoreHoriz />
        </IconButton>
        <Menu
            anchorEl={anchorEl}
            open = {open}
            onClose = {closeMenu}
        >
            <MenuItem
                onClick = {HandleStatus}
                disabled = {publishDisable}
                data-testid = "news-menuItem-status"
            >
                {publishStatus}
            </MenuItem>
            <MenuItem
                component = {Link}
                to = {generatePath(ROUTES.editNews.path ?? '', {newsId: currentNews.id})}
                data-testid = "news-menuItem-edit"
            >
                Edit
            </MenuItem>
            <MenuItem
                disabled = {deleteDisable}
                onClick = {HandleDelete}
                data-testid = "news-menuItem-delete"
            >
                Delete
            </MenuItem>
        </Menu>
    </>
}

export default NewsActions