import {Delete, FileUpload} from '@mui/icons-material'
import {Button} from '@mui/material'
import CancelRoundedIcon from '@mui/icons-material/CancelRounded'
import Alert from '@mui/material/Alert'

import {ChangeEvent, useState} from 'react'
import {MAX_FILE_SIZE} from '../../../util/constants/requests'

const UploadFile = ({file, setFile} : {file : File | undefined, setFile: (file: File | undefined) => void}) => {
    const [fileSizeExceedDialog, setFileSizeExceedDialog] = useState(false)

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        if (!event.target?.files?.length) return

        const tempFile = event.target.files[0]
        if (tempFile.size > MAX_FILE_SIZE) {
            setFileSizeExceedDialog(true)
            setFile(undefined)
            return
        }
        setFile(tempFile)
        setFileSizeExceedDialog(false)
    }

    return <>
        {file ?
            <div className="service-update-details-step-upload-file">
                <p>{file.name}</p>
                <CancelRoundedIcon
                    className="service-update-upload-delete-icon"
                    onClick={() => setFile(undefined)}
                >
                    <Delete/>
                </CancelRoundedIcon>
            </div>
            :
            <Button startIcon={<FileUpload/>} color="primary" component="label">
                <div className="service-update-details-file-upload-text">
                    Upload File
                </div>
                <input
                    name="upload-file"
                    id="upload-file"
                    onChange={handleChange}
                    hidden
                    type="file"
                />
            </Button>
        }
        {fileSizeExceedDialog && (
            <Alert className="file-size-exceed-alert" severity="error">
                Your file is too big. Max size is 10 Mo
            </Alert>
        )}
    </>
}

export default UploadFile
