import {DefinitionModel, IconForDefinitionType, IconForPrivacy} from '../../definitions/model/DefinitionModel'
import {TreeItem} from 'react-complex-tree'
import {Checkbox} from '@mui/material'
import {CustomTreeItemForPreferredDefinitionsTree} from '../model/TechnicalNodeModel'
import {TreeInformation} from 'react-complex-tree/src/types'
import {technicalNodeTreeName} from './preferredDefinitionsStructureUpdate'

export const DefinitionForPreferredDefinitionTree = ({node, definition, treeInfo}:
                                                {
                                                    node: TreeItem<CustomTreeItemForPreferredDefinitionsTree>,
                                                    definition: DefinitionModel,
                                                    treeInfo: TreeInformation
                                                }) => {
    return <div className={'definition-for-tree'} title={definition.code + ' - ' + definition.name}>
        <IconForDefinitionType attributeDefinitionType={definition.type.attributeDefinitionType}></IconForDefinitionType>
        <IconForPrivacy isPublic={definition.isPublic}></IconForPrivacy>
        <span className="definition-name">
            {definition.name} - {definition.code}
            {definition?.unit ? ` (unit: ${definition.unit})` : ''}
        </span>
    </div>
}