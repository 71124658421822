import {DefinitionModel, IconForDefinitionType, IconForPrivacy} from '../../definitions/model/DefinitionModel'
import {TreeItem} from 'react-complex-tree'
import {CustomTreeItem} from '../../genericComponents/tree/CustomTreeModel'
import {Checkbox} from '@mui/material'
import {
    CustomTreeItemForInformationPackageTree,
    InformationPackageModel,
    InformationPackDefinitionPositionModel
} from '../model/InformationPackageModel'
import {TreeInformation} from 'react-complex-tree/src/types'
import {informationPackageTreeName} from './informationPackagesStructureUpdate'

export const DefinitionForInformationPackTree = ({node, definition, treeInfo}:
                                                {
                                                    node: TreeItem<CustomTreeItemForInformationPackageTree>,
                                                    definition: DefinitionModel,
                                                    treeInfo: TreeInformation
                                                }) => {

    const requiredChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        node.data = {...node.data, required: e.target.checked}
    }

    const isInInformationPackageTree = treeInfo.treeId === informationPackageTreeName

    return <div className={'definition-for-tree'} title={definition.code + ' - ' + definition.name}>
        <IconForDefinitionType attributeDefinitionType={definition.type.attributeDefinitionType}></IconForDefinitionType>
        <IconForPrivacy isPublic={definition.isPublic}></IconForPrivacy>
        <span className="definition-name">
            {definition.name} - {definition.code}
            {definition?.unit ? ` (unit: ${definition.unit})` : ''}
        </span>
        {isInInformationPackageTree && <Checkbox checked={node.data.required} onChange={requiredChange}/>}
    </div>
}