import {MoreHoriz} from '@mui/icons-material'
import {IconButton, Menu, MenuItem} from '@mui/material'
import React, {useState} from 'react'
import {ROUTES} from '../../util/constants/routing'
import {generatePath, Link} from 'react-router-dom'
import {useDeleteBannerMutation, useUpdateBannerMutation} from '../rtkBannerApi'
import {Banner} from '../model/Banner'

const BannerActions = (
    {
        currentBanner,
        openSnack
    }: {
        currentBanner: Banner
        openSnack: (values:{ open: boolean, message: string}) => void
    }
) => {
    const [anchorEl, setAnchorEl] = useState<Element | null>(null)
    const open = Boolean(anchorEl)
    const [useUpdateBanner, useUpdateBannerResult] = useUpdateBannerMutation()
    const [useDeleteBanner, useDeleteBannerResult] = useDeleteBannerMutation()

    const handleClose= (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget)
    }
    const closeMenu = () => {
        setAnchorEl(null)
    }

    let changeStatus = 'Publish'
    if (currentBanner.enabled) {
        changeStatus = 'Unpublish'
    }

    const HandleStatus = () => {
        const newBanner = {
            ...currentBanner,
            enabled: !currentBanner.enabled
        }
        useUpdateBanner(newBanner)
        closeMenu()
        openSnack({open: true, message: 'Banner Status Changed'})
    }

    const HandleDelete = () => {
        useDeleteBanner(currentBanner.id ?? '')
        closeMenu()
        openSnack({open: true, message: 'Banner Deleted'})
    }

    return <>
        <IconButton onClick = {handleClose}>
            <MoreHoriz />
        </IconButton>
        <Menu
            anchorEl={anchorEl}
            open = {open}
            onClose = {closeMenu}
        >
            <MenuItem
                onClick = {HandleStatus}>
                {changeStatus}
            </MenuItem>
            <MenuItem
                component = {Link}
                disabled={useUpdateBannerResult.isLoading}
                to = {generatePath(ROUTES.editBanner.path ?? '', {bannerId: currentBanner.id})}
            >
                Edit
            </MenuItem>
            <MenuItem
                onClick = {HandleDelete}
                disabled={useDeleteBannerResult.isLoading}>
                Delete
            </MenuItem>
        </Menu>
    </>
}

export default BannerActions