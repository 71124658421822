export interface ClassificationNode {
    label: string,
    id: string,
    code: string,
    iconName: string,
    children: ClassificationNode[],
    parentId?: string
}

export const getAllNodesFromTree = (rootNode: ClassificationNode | undefined): ClassificationNode[] => {
    if(!rootNode) return []
    const children = rootNode.children.flatMap(subNode => getAllNodesFromTree(subNode))
    return [...children, rootNode]
}
