import React from 'react'
import {AdjustmentRule, MinQuantityRule} from '../QuotationRule'
import {ServiceRequest} from '../../ServiceRequest'
import {FormControl, IconButton, TextField} from '@mui/material'
import {NumericFormat, InputAttributes} from 'react-number-format'
import { DeleteForever } from '@mui/icons-material'
import {FieldArrayRenderProps} from 'formik'

export const UpdateAdjustmentRuleComponent = ({rule, serviceRequest, index, handleChange, formikArrayHelper}: {
    rule: AdjustmentRule,
    serviceRequest: ServiceRequest,
    index: number,
    handleChange: InputAttributes['onChange'],
    formikArrayHelper: FieldArrayRenderProps
}) => {
    return <div className="quotation-detail-rule-structure">
        <p className="quotation-detail-description">Adjustment</p>
        <FormControl className="quotation-detail-adjustment-reason">
            <TextField
                size="small" required
                placeholder="Enter Adjustement Reason"
                name={`adjustmentRules.${index}.adjustmentReason`}
                value={rule.adjustmentReason}
                onChange={handleChange}
                error={!rule.adjustmentReason}
                helperText={!rule.adjustmentReason ? 'You need a reason': ''}
            />
        </FormControl>
        <FormControl>
            <TextField
                inputProps={{style: {textAlign: 'right'}}}
                name={`adjustmentRules.${index}.price`}
                type="number"
                value={rule.price}
                sx={{width: 1}}
                size="small"
                required
                placeholder="eg. 10"
                className="quotation-detail-adjustment-price"
                onChange={handleChange}/>
        </FormControl>
        <IconButton
            aria-label="delete"
            disabled={false}
            onClick={() => formikArrayHelper.remove(index)}
            color="primary"
            className="quotation-detail-delete"
        >
            <DeleteForever/>
        </IconButton>
    </div>
}