import {Link, useNavigate} from 'react-router-dom'
import {useParams} from 'react-router'
import TextField from '@mui/material/TextField'
import {InputLabel, MenuItem, Select} from '@mui/material'
import {SubmitButton} from '../../genericComponents/button/submitButton'
import {object, string} from 'yup'
import {useFormik} from 'formik'
import './informationPackageEdit.scss'
import LoadingSpinner from '../../genericComponents/spinner/LoadingSpinner'
import React from 'react'
import {setSnackbarMessage} from '../../util/commonSlice'
import {useDispatch} from 'react-redux'
import {useGetInformationPackageQuery, useUpdateInformationPackagesMutation} from '../rtkInformationPackagesApi'
import {BillingTypeEnum, InformationPackageModel} from '../model/InformationPackageModel'
import {ROUTES} from '../../util/constants/routing'

export const InformationPackageEdit = () => {
    const navigate = useNavigate()
    const {informationPackageId} = useParams()
    const dispatch = useDispatch()

    const {data: informationPackage, isFetching} = useGetInformationPackageQuery(informationPackageId ?? '', {skip: !informationPackageId})
    const [updateInformationPackages, updateInformationPackagesResult] = useUpdateInformationPackagesMutation()

    const validationSchema = object({
        label: string().required().max(100).min(3),
        code: string().required().max(100).min(1),
        billingType: string()
            .required()
            .oneOf(Object.values(BillingTypeEnum))
    })

    const handleSubmit = () => {
        let updatedInformationPackage: InformationPackageModel = {
            label: '',
            code: '',
            billingType: BillingTypeEnum.TO_BUY,
            displayOrder: 0,
            attributeDefinitions: []
        }
        if (informationPackage)
            updatedInformationPackage = informationPackage

        updateInformationPackages([{
            ...updatedInformationPackage,
            label: formik.values.label,
            code: formik.values.code,
            billingType: formik.values.billingType
        }]).then(() => dispatch(setSnackbarMessage('The InformationPackage has been saved')))
            .catch(() => dispatch(setSnackbarMessage('An error happens saving the InformationPackage')))
    }


    const formik = useFormik({
            initialValues: {
                label: informationPackage?.label ?? '',
                code: informationPackage?.code ?? '',
                billingType: informationPackage?.billingType ?? ''
            },
            enableReinitialize: true,
            validationSchema: validationSchema,
            onSubmit: handleSubmit
        }
    )


    return <>
        <Link className="back-button" to={ROUTES.informationPackage.path ?? ''}> &lt; Back to Information Package Structure</Link>

        <h1>Information Package Details</h1>

        {isFetching && <LoadingSpinner/>}

        {!isFetching &&
            <form className="edit-information-package-form" onSubmit={formik.handleSubmit}>
                <div className="field">
                    <InputLabel className="information-package-input-label" id="label-label">
                        Name <span className="asterisk">*</span>
                    </InputLabel>
                    <TextField fullWidth
                               id="label"
                               name="label"
                               value={formik.values.label}
                               onChange={formik.handleChange}
                               className="information-package-input-text-field"
                               placeholder="Name of display set"
                               required
                               aria-invalid={formik.touched.label && Boolean(formik.errors.label)}
                    />
                </div>

                <div className="field">
                    <InputLabel className="information-package-input-label" id="code-label">
                        Code <span className="asterisk">*</span>
                    </InputLabel>
                    <TextField fullWidth
                               id="code"
                               name="code"
                               value={formik.values.code}
                               onChange={formik.handleChange}
                               className="information-package-input-text-field"
                               placeholder="Code of information package"
                               required
                               aria-invalid={formik.touched.code && Boolean(formik.errors.code)}
                    />
                </div>

                <div className="field">
                    <InputLabel className="information-package-input-label" id="label-widgetId">
                        Widget Id (which widget to use in Item page)
                        <span className="asterisk">*</span>
                    </InputLabel>
                    <Select fullWidth
                            labelId="widgetId"
                            id="billing-type"
                            name="billingType"
                            defaultValue={formik.values.billingType}
                            value={formik.values.billingType}
                            displayEmpty
                            onChange={formik.handleChange}
                    >
                        <MenuItem value="" disabled>Select a Billing Type</MenuItem>

                        {Object.values(BillingTypeEnum).sort().map((value) =>
                            <MenuItem key={value} value={value}>{value}</MenuItem>
                        )}
                    </Select>
                </div>

                <SubmitButton disabled={!formik.dirty || !formik.isValid}
                              loading={updateInformationPackagesResult.isLoading}>Save</SubmitButton>
            </form>}
    </>
}