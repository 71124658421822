import React from 'react'
import './NewsTable.scss'
import {Button} from '@mui/material'
import {useGetAllNewsQuery} from '../rtkNewsApi'
import {Link} from 'react-router-dom'
import {ROUTES} from '../../util/constants/routing'
import CustomNewsSortableTable from './customSortableTable/CustomNewsSortableTable'

const CreateNewsLink = () => {
    return <>
        <Button
            className = "news-header-button"
            variant = "contained"
            disableElevation
            component={Link}
            size = "small"
            to = {ROUTES.createNews.path ?? ''}
            data-testid = "news-add-button"
        >
            Add New
        </Button>
    </>
}

const NewsTable = () => {
    const {data : newsList, isLoading} = useGetAllNewsQuery()

    return <>
        <div className = "news-page-container">
            <h1 className="page-title">News & Announcements</h1>
            <CreateNewsLink/>

            <div className = "news-table-container">
                <CustomNewsSortableTable
                    isLoading={isLoading}
                    newsList={newsList ?? []}
                />
            </div>
        </div>
    </>
}

export default NewsTable