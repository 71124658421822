import React from 'react'
import {formatDate, formatMilliseconds, formatMonth} from '../../../genericComponents/utils/DateService'

const GetStatus = ({isPublished}: {isPublished: boolean}) => {
    if (isPublished) {
        return <div className="news-status-publish">Published</div>
    }
    return <div className="news-status-draft">Draft</div>
}

export const getFormattedDate = (date: number[]) => {
    const formattedDate = formatMilliseconds([...date])
    formatMonth(formattedDate)
    if (formattedDate) {
        return formatDate(formattedDate)
    }
}

export default GetStatus