import './common/NewsForm.scss'
import {News} from '../model/News'
import NewsForm from './common/NewsForm'
import {useCreateNewsMutation} from '../rtkNewsApi'
import {setSnackbarMessage} from '../../util/commonSlice'
import {ROUTES} from '../../util/constants/routing'
import {useDispatch} from 'react-redux'
import {useNavigate} from 'react-router-dom'

const CreateNews = () => {

    const newsTitle = 'Add News & Announcements'
    const [useCreateNews] = useCreateNewsMutation()
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const HandleUpdate = async (news: News) => {
        const action = news.enabled? 'Published' : 'Saved as Draft'
        const result = await useCreateNews(news)
        if ('data' in result) {
            dispatch(setSnackbarMessage('News & Announcement ' + action))
        }
        else {
            dispatch(setSnackbarMessage('Failed to Create News & Announcement'))
        }
        navigate(ROUTES.news.path ?? '')
    }

    const initialNews: News = {
        id : '',
        title : '',
        body : '',
        enabled: false,
        displayOrder: 0
    }

    return<>
        <NewsForm
            formTitle = {newsTitle}
            handlePublish = {HandleUpdate}
            initialNews = {initialNews}
        />
    </>
}

export default CreateNews