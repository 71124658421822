import React from 'react'
import { Link } from 'react-router-dom'

const Logo = ({ logoTitle = 'DATX' }) => {
    return (
        <Link to="/">
            <img
                src="/images/DATX_Logo.svg"
                alt={logoTitle}
                style={{ height: '32px', paddingTop: '8px' }}
            />
            <span className="datx-version">ADMIN</span>
        </Link>
    )
}

export default Logo
