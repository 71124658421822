import {DefinitionModel} from '../../definitions/model/DefinitionModel'
import {TreeItem, TreeItemIndex} from 'react-complex-tree'
import {informationPackageRootName} from '../structure-update/informationPackagesStructureUpdate'
import {CustomTreeItem, TreeMap} from '../../genericComponents/tree/CustomTreeModel'

export interface CustomTreeItemForInformationPackageTree extends CustomTreeItem {
    informationPackage?: InformationPackageModel,
    definition?: DefinitionModel
    required?: boolean
}

export interface InformationPackageModel extends CustomTreeItemForInformationPackageTree {
    code: string,
    billingType: BillingTypeEnum | string,
    displayOrder: number,
    attributeDefinitions: InformationPackDefinitionPositionModel[],
}

export enum BillingTypeEnum {
    TO_BUY = 'TO_BUY',
    PRIVATE = 'PRIVATE',
}

export interface InformationPackDefinitionPositionModel {
    displayOrder: number,
    attributeDefinitionId: string
    required: boolean
}

export type InformationPackageTreeItem = TreeItem<CustomTreeItemForInformationPackageTree>
export type InformationPackageTree = TreeMap<CustomTreeItemForInformationPackageTree>

export const buildInformationPackageFromNode = (node: TreeItem<CustomTreeItemForInformationPackageTree>,
                                                parentByChildIds: Map<TreeItemIndex, TreeItem<CustomTreeItemForInformationPackageTree>>,
                                                allNodesByIds: Map<TreeItemIndex, TreeItem<CustomTreeItemForInformationPackageTree>>): InformationPackageModel => {
    const informationPackage = node.data.informationPackage
    if (!informationPackage) return {
        id: '',
        label: '',
        code: '',
        billingType: BillingTypeEnum.TO_BUY,
        displayOrder: 0,
        attributeDefinitions: []
    }

    // extract parent information
    const parent = parentByChildIds.get(node.data.id ?? '')
    let parentId = parent?.index
    if (parentId === informationPackageRootName) parentId = undefined

    const displayOrder = parent?.children?.findIndex((child) => child === informationPackage.id)
    return {
        ...informationPackage,
        displayOrder: displayOrder ?? 0,
        attributeDefinitions: node.children?.map((id, index) => buildDefinitionPosition(id, index, allNodesByIds))
                .filter(def => def.attributeDefinitionId) // remove null definitions
            ?? []
    }
}

const buildDefinitionPosition = (id: string | number, displayOrder: number, allNodesByIds: Map<TreeItemIndex, TreeItem<CustomTreeItemForInformationPackageTree>>): InformationPackDefinitionPositionModel => {
    const definitionNode = allNodesByIds.get(id)
    if(!definitionNode)
        return {attributeDefinitionId:'', displayOrder: 0, required: true}

    return {
        attributeDefinitionId: id.toString(),
        displayOrder: displayOrder,
        required: definitionNode.data?.required ?? false
    }
}


const definitionWasUpdated = (newDefinition: InformationPackDefinitionPositionModel, oldDefinition: InformationPackDefinitionPositionModel) => {
    if(newDefinition.required !== oldDefinition.required) return true
    return newDefinition.displayOrder !== oldDefinition.displayOrder

}

const packageWasUpdated = (newPackage: InformationPackageModel, oldPackage: InformationPackageModel) => {
    if(oldPackage.billingType !== newPackage.billingType) return true
    if(oldPackage.displayOrder !== newPackage.displayOrder) return true
    if(oldPackage.attributeDefinitions?.length !== newPackage.attributeDefinitions?.length) return true

    const oldDefinitionsById = new Map(oldPackage.attributeDefinitions.map(def => [def.attributeDefinitionId, def]))

    return newPackage.attributeDefinitions.some(newDef => {
        const oldDef = oldDefinitionsById.get(newDef.attributeDefinitionId)
        if(!oldDef) return true
        return definitionWasUpdated(newDef, oldDef)
    })
}

export const keepOnlyUpdatedPackages = (newInformationPackages: InformationPackageModel[], informationPackages: InformationPackageModel[]): InformationPackageModel[] => {
    const oldPackagesById = new Map(informationPackages.map(pack => [pack.id, pack]))
    return newInformationPackages.filter(newInformationPackage => {
        const oldPackage = oldPackagesById.get(newInformationPackage.id)
        if(!oldPackage) return true
        return packageWasUpdated(newInformationPackage, oldPackage)
    })
}