import {createApi} from '@reduxjs/toolkit/dist/query/react'
import {axiosBaseQuery} from '../rtkQueryUtils'
import {SEARCH_TRANSLATOR_API_ENDPOINT} from '../util/constants/backendUrls'
import {PreferredAttributeDefinition, TechnicalNodeModel} from './model/TechnicalNodeModel'
import {DisplaySetModel} from '../displaySets/model/DisplaySetModel'
import {GET_DISPLAY_SETS} from '../displaySets/rtkDisplaySetsApi'

export const GET_TECHNICAL_NODES_PREFERRED_DEFINITIONS = 'GetTechnicalNodesPreferredDefinitions'

export const technicalNodesPreferredDefinitionsApi = createApi({
    reducerPath: 'technicalNodesPreferredDefinitions',
    keepUnusedDataFor: 0,
    tagTypes: [GET_TECHNICAL_NODES_PREFERRED_DEFINITIONS],
    baseQuery: axiosBaseQuery({baseUrl: `${SEARCH_TRANSLATOR_API_ENDPOINT}`}),
    endpoints: (builder) => ({
        getAllTechnicalNodesPreferredDefinitions: builder.query<PreferredAttributeDefinition[], void>({
            query: () => ({
                url: '/datx/preferred-definitions',
                method: 'GET'
            }),
            transformResponse: (response: PreferredAttributeDefinition[]) => response,
            transformErrorResponse: (response, meta, arg) => `An error occurred while retrieving preferred definitions of technical nodes ${arg}`,
            providesTags: [GET_TECHNICAL_NODES_PREFERRED_DEFINITIONS]
        }),
        replaceAllTechnicalNodesPreferredDefinitions: builder.mutation<PreferredAttributeDefinition[], PreferredAttributeDefinition[]>({
            query: (technicalNodes) => ({
                url: '/datx/preferred-definitions/all',
                method: 'POST',
                data: technicalNodes
            }),
            transformResponse: (response: PreferredAttributeDefinition[]) => response,
            transformErrorResponse: (response: any) => response?.data?.errorMessage || 'Something went wrong when updating the technical nodes preferred definitions',
            invalidatesTags: [GET_TECHNICAL_NODES_PREFERRED_DEFINITIONS]
        }),
    })
})

export const {useGetAllTechnicalNodesPreferredDefinitionsQuery, useReplaceAllTechnicalNodesPreferredDefinitionsMutation} = technicalNodesPreferredDefinitionsApi