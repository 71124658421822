import { Guild } from '../models/guild'
import CardContainer from '../../genericComponents/cardContainer/CardContainer'
import { CircularProgressbarWithChildren } from 'react-circular-progressbar'
import EditButton from '../../genericComponents/EditButton/EditButton'
import { useState } from 'react'
import { useUpdateGuildMutation } from '../rtkGuildApi'
import EditGuildDetailsModal from './commonForm/EditGuildDetailsModal'
import './GuildMaxMemberCapacityCard.scss'
import { useDispatch } from 'react-redux'
import { setSnackbarMessage } from '../../util/commonSlice'
import { EditGuildDetailTypeEnum } from '../models/EditGuildDetailTypeEnum'

const GuildMaxMemberCapacityCard = ({guild}: {guild: Guild}) => {
    const guildCapacityPercentage = (Number(guild.numberOfUsers) / Number(guild.maxUsersInGuild)) * 100

    const [showEditModal, setShowEditModal] = useState(false)

    const [updateGuild, updateGuildResult] = useUpdateGuildMutation()

    const dispatch = useDispatch()

    const openEditModal = () => setShowEditModal(true)
    const closeEditModal = () => setShowEditModal(false)

    const onEditButtonClick = () => {
        openEditModal()
    }

    const submitUpdatedGuildMaxCapacity = (newGuildMaxCapacity: number) => {
        if (!guild) return

        const updatedGuild: Guild = {
            ...guild,
            maxUsersInGuild: newGuildMaxCapacity
        }

        updateGuild(updatedGuild).unwrap()
            .then(() => dispatch(setSnackbarMessage('Guild member capacity has been updated')))
            .catch((error) => dispatch(setSnackbarMessage(error)))
    }

    return <CardContainer containerClassName="high-row-span" contentClassName="guild-member-capacity" fullWidth fullHeight>
        <CircularProgressbarWithChildren
            className="guild-member-donut"
            value={guildCapacityPercentage}
            strokeWidth={16}
        >
            <div className="circular-progress-text">
                <div className="current-max-guild-members">
                    <span id="current-guild-member">{guild.numberOfUsers}</span>
                    <span>{` / ${guild.maxUsersInGuild}`}</span>
                </div>
                <label>Members</label>
            </div>
        </CircularProgressbarWithChildren>
        <div className="guild-member-max-capacity">
            <label className="card-title">Maximum Guild Member Capacity</label>
            <label className="card-value">{guild.maxUsersInGuild}</label>
        </div>
        <EditButton onEditClick={onEditButtonClick}/>
        <EditGuildDetailsModal isOpen={showEditModal} onClose={closeEditModal}
                               detailType={EditGuildDetailTypeEnum.GUILD_CAPACITY}
                               existingValue={guild.maxUsersInGuild.toString()}
                               onSubmit={submitUpdatedGuildMaxCapacity} isSubmitLoading={updateGuildResult.isLoading}
        />
    </CardContainer>
}

export default GuildMaxMemberCapacityCard