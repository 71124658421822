import {createApi} from '@reduxjs/toolkit/dist/query/react'
import {axiosBaseQuery} from '../rtkQueryUtils'
import {SEARCH_TRANSLATOR_API_ENDPOINT} from '../util/constants/backendUrls'
import {DisplaySetModel} from './model/DisplaySetModel'

export const GET_DISPLAY_SETS = 'GetDisplaySets'

export const displaySetsApi = createApi({
    reducerPath: 'displaySetsApi',
    keepUnusedDataFor: 0,
    tagTypes: [GET_DISPLAY_SETS],
    baseQuery: axiosBaseQuery({baseUrl: `${SEARCH_TRANSLATOR_API_ENDPOINT}`}),
    endpoints: (builder) => ({
        getAllDisplaySets: builder.query<DisplaySetModel[], void>({
            query: () => ({
                url: '/datx/displaySet',
                method: 'GET'
            }),
            transformResponse: (response: DisplaySetModel[]) => response,
            transformErrorResponse: (response, meta, arg) => `An error occurred while retrieving display sets ${arg}`,
            providesTags: [GET_DISPLAY_SETS]
        }),
        getDisplaySet: builder.query<DisplaySetModel, string>({
            query: (id) => ({
                url: '/datx/displaySet/' + id,
                method: 'GET'
            }),
            transformResponse: (response: DisplaySetModel) => response,
            transformErrorResponse: (response, meta, arg) => `An error occurred while retrieving display set ${arg}`,
            providesTags: [GET_DISPLAY_SETS]
        }),
        updateDisplaySets: builder.mutation<DisplaySetModel[], DisplaySetModel[]>({
            query: (displaySets) => ({
                url: '/datx/displaySet',
                method: 'POST',
                data: displaySets
            }),
            transformResponse: (response: DisplaySetModel[]) => response,
            transformErrorResponse: (response: any) => response?.data?.errorMessage || 'Something went wrong when updating the display sets',
            invalidatesTags: [GET_DISPLAY_SETS]
        }),
    })
})

export const {useGetAllDisplaySetsQuery, useUpdateDisplaySetsMutation, useGetDisplaySetQuery} = displaySetsApi