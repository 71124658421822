import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import utc from 'dayjs/plugin/utc'

dayjs.extend(customParseFormat)
dayjs.extend(utc)

const dateTimeFormat  = 'DD/MM/YY h:mm A'
const dateFormat = 'DD/MM/YYYY'

    export const formatDate = (formattedDate: number[]): string => {
        return dayjs(createDate(formattedDate)).format(dateFormat)
    }

    export const formatDateTime = (formattedDate: number[]): string => {
        const dDate = formatMilliseconds([...formattedDate])
        formatMonth(dDate)

        return dayjs(createDate(dDate)).format(dateTimeFormat)
    }

    export const formatUtcDateToLocal = (date: Date): string => {
        return dayjs.utc(date)?.local().format('DD/MM/YY h:mm A')
    }

    export const compareDates = (v1: string, format1: string, v2: string, format2: string) => {
        return dayjs(v2, format2).isBefore(dayjs(v1, format1)) ? 1 : -1
    }

    const createDate = (dateToDisplay : number[]) => {
        return new Date(...dateToDisplay as [number, number, number, number, number, number])
    }

    /*
    Returns 1 if "v2" before "v1", -1 otherwise
    */
    export const SortDates = (v1 : string, v2 : string) => {
        const format1 = v1.includes('AM') || v1.includes('PM') ? dateTimeFormat : dateFormat
        const format2 = v2.includes('AM') || v2.includes('PM') ? dateTimeFormat : dateFormat

        return compareDates(v1, format1,v2, format2)
    }

    export const formatMilliseconds = (entryDate: number[]) => {
        //The milliseconds value is not well formatted. As it is not used for display, it can be ignored.
        if (entryDate.length >= 6) {
            entryDate[6] = 0
        }
        return entryDate
    }

    export const formatMonth = (dateToDisplay: number[]) => {
        // dayjs accepts numbers from 0 to 11
        dateToDisplay[1] = dateToDisplay[1] - 1
    }
