import './EditQuotation.scss'
import {ROUTES} from '../../../util/constants/routing'
import {useNavigate} from 'react-router-dom'
import {useParams} from 'react-router'
import {useGetServiceRequestQuery, useUpdateQuotationMutation} from '../../rtkServiceRequestApi'
import ConfirmationDialog from '../../../genericComponents/confirmationDialog/ConfirmationDialog'
import getServiceSubscriptionType from '../../models/ServiceType'
import {Button, Divider} from '@mui/material'
import {CREDITS} from '../../../constants/credits'
import React, {useState} from 'react'
import LoadingSpinner from '../../../genericComponents/spinner/LoadingSpinner'
import {FieldArray, Form, Formik, FormikValues} from 'formik'
import QuotationRuleTypeEnum from '../../models/QuotationRuleTypeEnum'
import {AdjustmentRule, getTypedQuotationRule, QuotationRule} from '../../models/QuotationRules/QuotationRule'
import {Add} from '@mui/icons-material'
import {getTotalFromRequest, getTotalQuotation, Quotation} from '../../models/Quotation'
import UserRequestStatusEnum from '../../models/UserRequestStatusEnum'
import {array, number, object, string} from 'yup'
import {LoadingButton} from '@mui/lab'
import {ServiceRequest} from '../../models/ServiceRequest'

export type EditQuotationFormikValues = {
    genericRules: QuotationRule[],
    adjustmentRules: QuotationRule[],
    serviceRequest: ServiceRequest
}

const EditQuotation = () => {
    const {serviceRequestId} = useParams()

    const navigate = useNavigate()
    const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false)
    const {data: serviceRequest, isFetching} = useGetServiceRequestQuery(serviceRequestId!, {skip: !serviceRequestId})
    const [updateQuotation, updateQuotationResult] = useUpdateQuotationMutation()

    const handleSubmit = (values: FormikValues) => {
        if (!serviceRequest?.quotation)
            return
        const updatedQuotation: Quotation = {
            ...serviceRequest.quotation,
            quotationRules: [...values.genericRules, ...values.adjustmentRules]
        }

        updateQuotation({
            serviceRequestId: serviceRequestId ?? '',
            quotation: updatedQuotation
        }).then(() => setIsConfirmDialogOpen(true))
    }

    const backToPreviousPage = () => {
        navigate('' + ROUTES.serviceRequest.path)
    }

    if (isFetching || !serviceRequest) return <LoadingSpinner/>

    const genericRules = serviceRequest?.quotation?.quotationRules
        ?.filter((rule) => rule.ruleType !== QuotationRuleTypeEnum.ADJUSTMENT)
        .map((rule) => getTypedQuotationRule(rule)) ?? []

    const adjustmentRules = serviceRequest?.quotation?.quotationRules
        ?.filter((rule) => rule.ruleType === QuotationRuleTypeEnum.ADJUSTMENT)
        .map((rule) => getTypedQuotationRule(rule)) ?? []


    const validationSchema = object().shape({
        genericRules: array().of(object().shape({
            price: number().positive(),
            minQuantity: number().positive(),
            subRule: object().shape({
                price: number().positive(),
                subRule: object().shape({
                    price: number().positive(),
                })
            })
        })),
        adjustmentRules: array().of(object().shape({
            price: number().required(),
            adjustmentReason: string().required()
        }))
    })

    const validate = (values: FormikValues) => {
        let errors = {}
        const finalCost = getTotalQuotation(
            [...values.genericRules, ...values.adjustmentRules]
        )
        if (finalCost < 0) {
            errors = {
                ...errors,
                total: 'Total should be >= 0'
            }
        }

        return errors
    }

    return <>
        <div className="back-to-service-requests" onClick={backToPreviousPage}> &lt; Back to Service Requests</div>

        <h1>Quotation</h1>
        {serviceRequest.status !== UserRequestStatusEnum.AWAITING_QUOTATION &&
            <p>Cannot update quotation: wrong service request status: {serviceRequest.status}</p>
        }
        {serviceRequest.status === UserRequestStatusEnum.AWAITING_QUOTATION && <>
            <ConfirmationDialog
                open={isConfirmDialogOpen}
                closeDialog={() => setIsConfirmDialogOpen(false)}
                confirmationMsg="Quotation Saved"/>

            <Formik
                enableReinitialize
                validate={validate}
                validationSchema={validationSchema}
                initialValues={{
                    genericRules: genericRules,
                    adjustmentRules: adjustmentRules,
                    serviceRequest: serviceRequest
                }}
                onSubmit={handleSubmit}
            >{({
                   values,
                   touched,
                   isValid,
                   errors,
                   dirty,
                   handleChange,
                   setFieldValue
               }) => (
                <Form
                    id={'form-' + serviceRequest?.id}
                    className="update-quotation-container"
                >
                    <div className="edit-quot-service-request-detail-container">
                        <div>Request ID:</div>
                        <div>{serviceRequest?.id}</div>
                        <div>Service:</div>
                        <div>{serviceRequest?.service && (getServiceSubscriptionType(serviceRequest?.service)?.label ?? '')}</div>
                    </div>
                    <Divider/>
                    <div className="quotation-detail-container">
                        <div className="quotation-detail-header quotation-detail-rule-structure">
                            <p className="quotation-detail-description"><strong>Description</strong></p>
                            <p className="quotation-detail-unit-price"><strong>Unitary Cost</strong>
                                <div
                                    className="caption">({CREDITS})
                                </div>
                            </p>
                            <p className="quotation-detail-nb-object"><strong>Qty</strong></p>
                            <p className="quotation-detail-min-quantity"><strong>Min. Qty</strong></p>
                            <p className="quotation-detail-total"><strong>Sub-Total</strong></p>
                        </div>
                        <FieldArray name="genericRules">
                            {arrayHelper => values.genericRules.map(
                                (rule, index) => rule.getUpdateComponent(values.serviceRequest, 'genericRules', index, handleChange, arrayHelper, setFieldValue, values)
                            )}
                        </FieldArray>
                    </div>
                    <Divider/>
                    <div className="quotation-detail-container">
                        <FieldArray name="adjustmentRules">
                            {arrayHelper => <>
                                {values.adjustmentRules.length <= 0 &&
                                    <div className="adjustment-button">
                                        <Button variant="outlined" color="primary" type="button"
                                                onClick={() => arrayHelper.push(new AdjustmentRule(0, ''))}>
                                            <Add></Add>
                                            Add Adjustment
                                        </Button>
                                    </div>
                                }

                                {values.adjustmentRules.length > 0 && values.adjustmentRules.map(
                                    (rule, index) => rule.getUpdateComponent(values.serviceRequest, 'genericRules', index, handleChange, arrayHelper, setFieldValue, values)
                                )}
                            </>}
                        </FieldArray>
                    </div>
                    <div className="quotation-detail-rule-structure">
                        <div className="quotation-detail-total-title"><strong>Total</strong>
                            <div className="caption">({CREDITS})</div>
                        </div>
                        <div className="quotation-detail-total-value">{getTotalQuotation(
                            [...values.genericRules, ...values.adjustmentRules]
                        )}</div>
                    </div>
                    <div>
                        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                        {/* @ts-ignore */}
                        {errors.total ? <p className="text-error">{errors.total}</p> : ''}
                    </div>
                    <div className="edit-quot-quotation-button">
                        <LoadingButton loading={updateQuotationResult?.isLoading} variant="contained" color="primary"
                                       type="submit" disabled={!dirty || !isValid}>Save
                            Quotation</LoadingButton>
                    </div>
                </Form>
            )
            }</Formik>
        </>
        }

    </>
}

export default EditQuotation