import React from 'react'
import {ROUTES} from '../../util/constants/routing'
import ItemNavigationMenu from '../headerGenericComponents/ItemNavigationMenu'

const ServiceRequestMenu = () => {
    return (
        <ItemNavigationMenu
            label="Service Requests"
            // icon={<RequestPageOutlined />}
            subRoute="/services"
            url={ROUTES.serviceRequest.path}
        />
    )
}

export default ServiceRequestMenu
