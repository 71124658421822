import './EditGuild.scss'
import {useParams} from 'react-router'
import {useNavigate} from 'react-router-dom'
import { useGetGuildQuery } from '../rtkGuildApi'
import LoadingSpinner from '../../genericComponents/spinner/LoadingSpinner'
import {ROUTES} from '../../util/constants/routing'
import GuildAvailableCreditsCard from './GuildAvailableCreditsCard'
import GuildMaxMemberCapacityCard from './GuildMaxMemberCapacityCard'
import GuildLinkedOrganisationCard from './GuildLinkedOrganisationCard'
import GuildDetailsCard from './GuildDetailsCard'
import BackButton from '../../genericComponents/button/backButton/BackButton'
import GuildChangeLog from './GuildChangeLog'

const EditGuild = () => {
    const {guildId} = useParams()

    const {data: guild, isFetching} = useGetGuildQuery(guildId!, {skip: !guildId})
    const navigate = useNavigate()

    const navigateToActiveGuilds = () => {
        navigate(ROUTES.activeGuilds.path ?? '')
    }

    if (isFetching) return <LoadingSpinner />

    return (
        <div className="edit-guild-form">
            <BackButton text="Back to Active Guilds" onClick={navigateToActiveGuilds} />
            {guild && <GuildDetailsCard guild={guild} guildId={guildId} />}
            {guild && <div className="guild-credits-capacity-linked-org-container">
                <GuildAvailableCreditsCard guild={guild} />
                <GuildMaxMemberCapacityCard guild={guild} />
                <GuildLinkedOrganisationCard guild={guild} />
            </div>}
            <GuildChangeLog/>
        </div>
    )
}


export default EditGuild
