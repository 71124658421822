import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton
} from '@mui/material'
import {ROUTES} from '../../../util/constants/routing'
import {Link} from 'react-router-dom'
import './BannerDialog.scss'
import {Close} from '@mui/icons-material'

const BannerDialog = ({
    openDialog,
    dialogBodyText
                      }:{
    openDialog: boolean
    dialogBodyText: string
}) => {

    return<>
        <Dialog
            className = "banner-dialog"
            open = {openDialog}
            fullWidth
            maxWidth="xs"
        >
            <div className = "banner-dialog-container">
            <DialogTitle className = "banner-dialog-title">{'Banner Published'}</DialogTitle>
            <IconButton
                className="dialog-close"
                component={Link}
                to = {ROUTES.banner.path ?? ''}
            >
                <Close/>
            </IconButton>
            </div>
            <DialogContent className = "banner-dialog-content">
                <DialogContentText>
                    {dialogBodyText}
                </DialogContentText>
            </DialogContent>
            <DialogActions className = "banner-dialog-actions">
                <Button
                    className = "banner-dialog-button"
                    component = {Link}
                    variant = "contained"
                    to = {ROUTES.banner.path ?? ''}
                    disableElevation
                >
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    </>
}

export default BannerDialog