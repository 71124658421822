import React, {ReactElement, useMemo, useState} from 'react'
import {DraggingPosition, StaticTreeDataProvider, Tree, UncontrolledTreeEnvironment} from 'react-complex-tree'
import 'react-complex-tree/lib/style-modern.css'
import LoadingSpinner from '../../genericComponents/spinner/LoadingSpinner'
import {useGetAllDefinitionsQuery} from '../../definitions/rtkAttributeDefinitionsApi'
import {DefinitionModel} from '../../definitions/model/DefinitionModel'
import './informationPackagesStructureUpdate.scss'
import {SubmitButton} from '../../genericComponents/button/submitButton'
import {DefinitionForDisplaySetTree} from '../../displaySets/structure-update/definitionForDisplaySetTree'
import {InformationPackageForTree} from './informationPackageForTree'
import '../../genericComponents/tree/customTree.scss'
import {
    buildInformationPackageFromNode,
    InformationPackageModel,
    InformationPackageTree,
    InformationPackageTreeItem, keepOnlyUpdatedPackages
} from '../model/InformationPackageModel'
import {useGetAllInformationPackagesQuery, useUpdateInformationPackagesMutation} from '../rtkInformationPackagesApi'
import {buildNameAndIdForTreeModel} from '../../genericComponents/tree/CustomTreeModel'
import {DefinitionForInformationPackTree} from './definitionForInformationPackTree'
import {TreeInformation, TreeItemRenderContext} from 'react-complex-tree/src/types'
import {Button} from '@mui/material'
import {Link} from 'react-router-dom'
import {ROUTES} from '../../util/constants/routing'
import {useGetAllDisplaySetsQuery} from '../../displaySets/rtkDisplaySetsApi'
import {DisplaySetModel} from '../../displaySets/model/DisplaySetModel'
import {IdType} from '../../util/models/IdType'


export const informationPackageRootName = 'informationPackageRoot'
export const informationPackageTreeName = 'informationPackageRoot'
const informationPackageRootNode: InformationPackageTreeItem = {
    index: informationPackageRootName,
    children: [],
    isFolder: true,
    data: buildNameAndIdForTreeModel(informationPackageRootName)
}

export const definitionRootName = 'definitionRoot'
export const definitionTreeName = 'lonely-definitions-tree'
const definitionRootNode: InformationPackageTreeItem = {
    index: definitionRootName,
    children: [],
    isFolder: true,
    data: buildNameAndIdForTreeModel(definitionRootName)
}


// ========== InformationPackage Nodes


const sortInformationPackChildrenOfNode = (nodeId: string, informationPackagesAsTreeItems: InformationPackageTree) => {
    const node = informationPackagesAsTreeItems.get(nodeId.toString())
    if (!node) return

    const children = node.children?.map(id => informationPackagesAsTreeItems.get(id.toString())) ?? []
    node.children = children.sort((childNode1, childNode2) => {
        return (childNode1?.data?.informationPackage?.displayOrder ?? 0) - (childNode2?.data?.informationPackage?.displayOrder ?? 0)
    })
        .map(childNode => childNode?.index?.toString() ?? '')
        .filter(child => child) // remove all empty string
}

const getInformationPackNodes = (informationPacks: InformationPackageModel[], allDefinitions: DefinitionModel[]): InformationPackageTree => {
    const allDefinitionIds = allDefinitions.map(def => def.id)

    const informationPackAsTreeItems: InformationPackageTree = new Map(informationPacks.map((informationPack) => {
        const definitionIds = [...informationPack.attributeDefinitions] // because cannot update directly the object coming from the back
            .filter(position => allDefinitionIds.includes(position.attributeDefinitionId))
            .sort((position1, position2) => position1.displayOrder - position2.displayOrder)
            .map((position) => position.attributeDefinitionId)

        return [informationPack.id ?? '', {
            index: informationPack.id ?? '',
            children: definitionIds,
            isFolder: true,
            data: {
                id: informationPack.id,
                label: informationPack.label,
                informationPackage: informationPack
            },
            canMove: true,
            canRename: false
        }]
    }))


    // add the root node
    informationPackageRootNode.children = informationPacks.map(def => def.id ?? '')
    informationPackAsTreeItems.set(informationPackageRootName, informationPackageRootNode)
    sortInformationPackChildrenOfNode(informationPackageRootName, informationPackAsTreeItems)

    return informationPackAsTreeItems
}


// ========== Definition Nodes

const getLonelyDefinitionNode = (informationPackages: InformationPackageModel[], definitions: DefinitionModel[]): string[] => {
    const definitionsOfInformationPackages = informationPackages.flatMap((informationPackage) => informationPackage.attributeDefinitions.map((def) => def.attributeDefinitionId))

    return definitions.filter((def) => !definitionsOfInformationPackages.includes(def.id))
        .sort((def1, def2) => {
            if (def1.name < def2.name) return -1
            if (def1.name > def2.name) return 1
            return 0
        })
        .map((def) => def.id)
}

const getDefinitionsInDisplaySets = (displaySets: DisplaySetModel[], definitionIds: string[]): string[] => {
    const definitionsOfDisplaySets = displaySets.flatMap((displaySet) => displaySet.attributeDefinitions.map((def) => def.attributeDefinitionId))

    return definitionIds.filter(id => definitionsOfDisplaySets.includes(id))
}

const getDefinitionNodes = (informationPackages: InformationPackageModel[], definitions: DefinitionModel[], displaySets: DisplaySetModel[]): InformationPackageTree => {
    const definitionPositionsById = new Map(informationPackages.flatMap(pack => pack.attributeDefinitions).map(def => [def.attributeDefinitionId, def]))

    const definitionsAsTreeItems: InformationPackageTree = new Map(definitions.map((definition) => {
        const definitionPosition = definitionPositionsById.get(definition.id)
        return [definition.id, {
            index: definition.id,
            isFolder: false,
            data: {
                id: definition.id,
                label: definition.name,
                definition: definition,
                required: definitionPosition?.required
            },
            canMove: true,
            canRename: false
        }]
    }))

    const definitionsNotInInformationPackages = getLonelyDefinitionNode(informationPackages, definitions)
    const defNotInInfoPackAndInDisplaySets = getDefinitionsInDisplaySets(displaySets, definitionsNotInInformationPackages)
    definitionsAsTreeItems.set(definitionRootName, {
        ...definitionRootNode,
        children: defNotInInfoPackAndInDisplaySets
    })

    return definitionsAsTreeItems
}

const createInformationPackageTree = (informationPackages: InformationPackageModel[] | undefined,
                                      definitions: DefinitionModel[] | undefined,
                                      displaySets: DisplaySetModel[] | undefined): InformationPackageTree => {
    if (!informationPackages) return new Map<string, InformationPackageTreeItem>()
    if (!definitions) return new Map<string, InformationPackageTreeItem>()
    if (!displaySets) return new Map<string, InformationPackageTreeItem>()

    const informationPackagesAsTreeItems = getInformationPackNodes(informationPackages, definitions)
    const definitionsAsTreeItems = getDefinitionNodes(informationPackages, definitions, displaySets)

    return new Map<string, InformationPackageTreeItem>([...informationPackagesAsTreeItems, ...definitionsAsTreeItems])
}


export const InformationPackagesStructureUpdate = () => {
    const {data: informationPackages, isFetching: isFetchingInformationPackages} = useGetAllInformationPackagesQuery()
    const {data: definitions, isFetching: isFetchingDefinitions} = useGetAllDefinitionsQuery()
    const {data: displaySets, isFetching: isFetchingDisplaySets} = useGetAllDisplaySetsQuery()

    const [updateInformationPackages, updateInformationPackagesResult] = useUpdateInformationPackagesMutation()
    const [nbLonelyDefinitions, setNbLonelyDefinitions] = useState(0)

    const informationPackTree: InformationPackageTree = useMemo(() => createInformationPackageTree(informationPackages, definitions, displaySets),
        [informationPackages, definitions, displaySets])

    const listener = (changedItemIds: (string | number)[]) => {
        setNbLonelyDefinitions(informationPackTree.get(definitionRootName)?.children?.length ?? 0)
    }

    const dataProvider = useMemo(
        () => {
            setNbLonelyDefinitions(informationPackTree.get(definitionRootName)?.children?.length ?? 0)

            const provider = new StaticTreeDataProvider(Object.fromEntries(informationPackTree.entries()))
            provider.onDidChangeTreeData(listener)

            return provider
        },
        [informationPackTree]
    )

    const customNodeDisplay = (node: InformationPackageTreeItem, info: TreeInformation): ReactElement | string => {
        if (!node?.data) return ''
        if (node.data?.definition) return <DefinitionForInformationPackTree node={node}
                                                                       definition={node.data.definition} treeInfo={info}></DefinitionForInformationPackTree>
        if (node?.data?.informationPackage) return <InformationPackageForTree node={node}
                                                                              informationPackage={node.data.informationPackage}></InformationPackageForTree>
        return node.data.label
    }

    // check if we can drop a definition
    // we can drop a definition in the definition tree (target.treeId === definitionTreeName)
    // or inside a folder
    // or in between other definitions
    // the problem comes from the multi select. you can multi select folders and / or definitions
    // if you multi select objects of different type we disable the drop
    const canDropDefinition = (nodes: InformationPackageTreeItem[], target: DraggingPosition) => {
        if (!(nodes?.length)) return false

        const allAreFolders = nodes.every((node) => node.isFolder)
        if (allAreFolders) return true // if it's a folder, it's an information pack, so no need to check

        const allAreLeafs = nodes.every((node) => !node.isFolder)
        if (!allAreLeafs) return false // we have some folders and some definitions, so cannot

        if (target.treeId === definitionTreeName) // if definition is drop in the lonely def tree, then can
            return true

        // can drop in the information pack tree only inside an information pack of level 1
        return (target.targetType === 'item' && target.depth >= 0) // in an information package
            || (target.targetType === 'between-items' && target.depth >= 1)
    }

    // check if we can drop a folder
    const canDropInformationPack = (nodes: InformationPackageTreeItem[], target: DraggingPosition) => {
        if (!(nodes?.length)) return false

        const allAreLeafs = nodes.every((node) => !node.isFolder)
        if (allAreLeafs) return true // if it's not a folder, it's a definition, so no need to check

        const allAreFolders = nodes.every((node) => node.isFolder)
        if (!allAreFolders) return false // we have some definitions and folders

        if (target.treeId === definitionTreeName) // if information pack is drop in the lonely def tree, should not
            return false

        return target.targetType === 'root'
            || (target.targetType === 'between-items' && target.depth === 0) // only between top information pack
    }

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()

        const allNodes = Array.from(informationPackTree.values())
        const informationPackNodes = allNodes.filter((node) => node?.data?.informationPackage)
        const parentByChildIds = new Map(allNodes.flatMap((node) => node.children?.map((child) => [child, node]) ?? []))
        const allNodesByIds = new Map(allNodes.map((node) => [node.index, node]))

        const newInformationPackages: InformationPackageModel[] = informationPackNodes.map((node) => buildInformationPackageFromNode(node, parentByChildIds, allNodesByIds))
            .filter((informationPackage) => informationPackage != null) // remove null information packages
        const informationPackagesUpdated = keepOnlyUpdatedPackages(newInformationPackages, informationPackages ?? [])
        updateInformationPackages(informationPackagesUpdated ?? [])
    }

    return <form className={'information-packages-form'} onSubmit={handleSubmit}>
        <h1 className="page-title">Information Packages structure</h1>
        <p className="alert">
            Warning: an update of an information package is starting a full re computation of all the the access rights
        on the items linked to the definitions in the package. This computation is done asynchronously and can take some time.
        </p>

        {isFetchingInformationPackages || isFetchingDefinitions || isFetchingDisplaySets || updateInformationPackagesResult.isLoading ?
            <LoadingSpinner/> :
            <div className="information-package-edit">
                <UncontrolledTreeEnvironment
                    dataProvider={dataProvider}
                    getItemTitle={node => node?.data?.label ?? ''}
                    viewState={{}}
                    canDragAndDrop={true}
                    canDropOnFolder={true}
                    canDropOnNonFolder={false}
                    canReorderItems={true}
                    renderItemTitle={({item, info }) => customNodeDisplay(item, info)}
                    canDropAt={(node, target) => canDropDefinition(node, target) && canDropInformationPack(node, target)}
                >
                    <div className="lonely-definitions-tree tree-container">
                        <h3 className="tree-title">Lonely Definitions
                            ({nbLonelyDefinitions} {nbLonelyDefinitions > 0 ? '😔' : '😄'})</h3>
                        <div className="tree">
                            <Tree treeId={definitionTreeName} rootItem={definitionRootName}
                                  treeLabel="Definitions out of information packages"/>
                        </div>
                    </div>

                    <div className="information-package-with-definitions-tree tree-container">
                        <h3 className="tree-title">
                            <span>Information Packages ({informationPackages?.length})</span>

                            <span className="action-buttons">
                                <Button className="information-package-new" component={Link} variant="outlined"
                                        to={ROUTES.informationPackageNew.path ?? ''}
                                >  New  </Button>
                                <SubmitButton loading={updateInformationPackagesResult.isLoading}>Save</SubmitButton>
                            </span>
                        </h3>
                        <div className="tree">
                            <div className="information-package-tree-legend">
                                <label className="legend-name">Name</label>
                                <label className="legend-definition">Definition Required</label>
                            </div>
                            <Tree treeId={informationPackageTreeName} rootItem={informationPackageRootName}
                                  treeLabel="Information Packages with definitions"/>
                        </div>
                    </div>


                </UncontrolledTreeEnvironment>
            </div>
        }
    </form>
}