import {Link, useNavigate} from 'react-router-dom'
import {useParams} from 'react-router'
import TextField from '@mui/material/TextField'
import {InputLabel, MenuItem, Select} from '@mui/material'
import {useGetDisplaySetQuery, useUpdateDisplaySetsMutation} from '../rtkDisplaySetsApi'
import {SubmitButton} from '../../genericComponents/button/submitButton'
import {object, string} from 'yup'
import {useFormik} from 'formik'
import './displaySetEdit.scss'
import LoadingSpinner from '../../genericComponents/spinner/LoadingSpinner'
import React from 'react'
import {setSnackbarMessage} from '../../util/commonSlice'
import {useDispatch} from 'react-redux'
import {WidgetEnum} from '../model/Widget'
import {DisplaySetIcon, DisplaySetModel} from '../model/DisplaySetModel'
import {ROUTES} from '../../util/constants/routing'

export const DisplaySetEdit = () => {
    const navigate = useNavigate()
    const {displaySetId} = useParams()
    const dispatch = useDispatch()

    const {data: displaySet, isFetching} = useGetDisplaySetQuery(displaySetId ?? '', {skip: !displaySetId})
    const [updateDisplaySets, updateDisplaySetsResult] = useUpdateDisplaySetsMutation()


    const validationSchema = object({
        label: string().required().max(100).min(3),
        widgetId: string()
            .oneOf(Object.values(WidgetEnum))
    })

    const handleSubmit = () => {
        let updatedDisplaySet: DisplaySetModel = {
            label: '',
            displayOrder: 0,
            attributeDefinitions: []
        }
        if (displaySet)
            updatedDisplaySet = displaySet

        updateDisplaySets([{
            ...updatedDisplaySet,
            label: formik.values.label,
            widgetId: formik.values.widgetId ?? undefined
        }]).then(() => dispatch(setSnackbarMessage('The display set has been saved')))
            .catch(() => dispatch(setSnackbarMessage('An error happens saving the display set')))
    }


    const formik = useFormik({
            initialValues: {
                label: displaySet?.label ?? '',
                widgetId: displaySet?.widgetId ?? ''
            },
            enableReinitialize: true,
            validationSchema: validationSchema,
            onSubmit: handleSubmit
        }
    )


    return <>
        <Link className="back-button" to={ROUTES.displaySet.path ?? ''}> &lt; Back to Back to Display Sets Structure</Link>

        <h1><DisplaySetIcon iconName={displaySet?.iconName}></DisplaySetIcon> Display Set Details</h1>

        {isFetching && <LoadingSpinner/>}

        {!isFetching &&
            <form className="edit-display-set-form" onSubmit={formik.handleSubmit}>
                <div className="field">
                    <InputLabel className="display-set-input-label" id="label-label">
                        Name <span className="asterisk">*</span>
                    </InputLabel>
                    <TextField fullWidth
                               id="label"
                               name="label"
                               value={formik.values.label}
                               onChange={formik.handleChange}
                               className="display-set-input-text-field"
                               placeholder="Name of display set"
                               required
                               aria-invalid={formik.touched.label && Boolean(formik.errors.label)}
                    />
                </div>

                <div className="field">
                    <InputLabel className="display-set-input-label" id="label-widgetId">
                        Widget Id (which widget to use in Item page)
                    </InputLabel>
                    <Select fullWidth
                            labelId="label-widgetId"
                            id="widget-id"
                            name="widgetId"
                            defaultValue={formik.values.widgetId}
                            value={formik.values.widgetId}
                            displayEmpty
                            onChange={formik.handleChange}
                    >
                        <MenuItem value="" disabled>Select a Widget Id</MenuItem>

                        {Object.values(WidgetEnum).sort().map((value) =>
                            <MenuItem key={value} value={value}>{value}</MenuItem>
                        )}
                    </Select>
                </div>

                <SubmitButton disabled={!formik.dirty || !formik.isValid}
                              loading={updateDisplaySetsResult.isLoading}>Save</SubmitButton>
            </form>}
    </>
}