import { TextField } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import CustomModal from '../../../genericComponents/customModal/CustomModal'
import React from 'react'
import { useFormik } from 'formik'
import { number, object, string } from 'yup'
import './EditGuildDetailsModal.scss'
import { EditGuildDetailTypeEnum } from '../../models/EditGuildDetailTypeEnum'

const EditGuildDetailsModal = ({ isOpen, onSubmit, isSubmitLoading, onClose, detailType, existingValue }: {
    isOpen: boolean,
    onSubmit:(newValue: number, updateReason: string) => void,
    isSubmitLoading: boolean,
    onClose:() => void,
    detailType: string,
    existingValue: string
}) => {

    const formSubmission = () => {
        if (formik.values.newValue) {
            onSubmit(Number(formik.values.newValue), formik.values.reason.trim())
            onClose()
        }
    }

    const getValidationSchema = (detailType: string) => {
        const validationSchema = object({
            newValue: number().required().positive().integer()
        })

        const guildBudgetValidationSchema = object({
            newValue: number().required().positive().integer(),
            reason: string().required().trim().max(50)
        })

        return detailType === EditGuildDetailTypeEnum.AVAILABLE_CREDITS ? guildBudgetValidationSchema : validationSchema
    }

    const formik= useFormik({
        initialValues: {
            newValue: '',
            reason: ''
        },
        validationSchema: getValidationSchema(detailType),
        onSubmit: formSubmission
    })

    if (!isOpen) return <></>

    const reasonTextfield = () => {
        return <div className="guild-details-new-value-reason">
            <label className="detail-name">
                Reason for Update:<span className="asterisk">*</span>
            </label>
            <TextField
                id="reason"
                value={formik.values.reason}
                onChange={formik.handleChange}
                className="reason-textfield"
                placeholder="e.g. Contract Renewal"
                type="text"
                rows={3}
                required
                multiline
            />
        </div>
    }

    return <CustomModal handleClose={onClose}
                        title={<span>Update {detailType}</span>}
                        body={<form onSubmit={formik.handleSubmit}>
                            <div className="edit-guild-details-modal">
                                <div className="guild-details-current-value">
                                    <label className="detail-name">Current {detailType}:</label>
                                    <label className="detail-value">{existingValue}</label>
                                </div>

                                <div className="guild-details-new-value">
                                    <label className="detail-name">Update {detailType} to: <span
                                        className="asterisk">*</span></label>
                                    <TextField
                                        id="newValue"
                                        value={formik.values.newValue}
                                        onChange={formik.handleChange}
                                        className="new-value-textfield"
                                        size="small"
                                        placeholder="e.g. 200"
                                        type="number"
                                        required
                                    />
                                </div>

                                {detailType === EditGuildDetailTypeEnum.AVAILABLE_CREDITS && reasonTextfield()}
                            </div>
                        </form>}
                        actions={<ModalActionRow formSubmission={formSubmission} isSubmitLoading={isSubmitLoading}
                                                 shouldUpdateButtonDisabled={!formik.isValid || !formik.dirty} />}
    />

}

const ModalActionRow = ({ formSubmission, isSubmitLoading, shouldUpdateButtonDisabled }: { formSubmission: () => void, isSubmitLoading: boolean, shouldUpdateButtonDisabled: boolean }) => {
    return <div className="update-button-container">
        <LoadingButton
            color="primary" type="submit"
            variant="contained" data-cy="guild-details-update-button"
            className="update-button"
            onClick={formSubmission}
            loading={isSubmitLoading}
            disabled={shouldUpdateButtonDisabled}
        >
            Update
        </LoadingButton>
    </div>
}

export default EditGuildDetailsModal
