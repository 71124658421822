import {News} from './model/News'
import {guildApi} from '../Guilds/rtkGuildApi'
import {IdType} from '../util/models/IdType'

const NEWS_TAG = 'News'

export const newsApi = guildApi.enhanceEndpoints({
    addTagTypes : [NEWS_TAG]
}).injectEndpoints({
    endpoints: (builder) => ({
        getAllNews : builder.query<News[], void>({
            query : () => ({
                url : 'news/all',
                method: 'GET'
            }),
            transformResponse: (response:any) => response.data,
            transformErrorResponse: () => 'Error getting all news',
            providesTags : [NEWS_TAG]
        }),
        getNewsById : builder.query<News, string>({
            query : (bannerId) => ({
                url : `news/${bannerId}`,
                method: 'GET'
            }),
            transformResponse: (response:any) => response.data,
            transformErrorResponse: () => 'Error getting news',
            providesTags : [NEWS_TAG]
        }),
        updateNews : builder.mutation<any, News>({
            query: (news) => ({
                url : `news/${news.id}`,
                method : 'PATCH',
                data : news
            }),
            transformResponse: (response:any) => response.data,
            transformErrorResponse: () => 'Error updating news',
            invalidatesTags : [NEWS_TAG]
        }),
        deleteNews : builder.mutation<any, IdType>({
            query: (newsId) => ({
                url : `news/${newsId}`,
                method : 'DELETE'
            }),
            transformResponse: (response:any) => response.data,
            transformErrorResponse: () => 'Error deleting news',
            invalidatesTags : [NEWS_TAG]
        }),
        createNews : builder.mutation<News, News>({
            query: (news) => ({
                url : 'news',
                method : 'POST',
                data : news
            }),
            transformResponse: (response:any) => response.data,
            transformErrorResponse: () => 'Error creating news',
            invalidatesTags : [NEWS_TAG]
        }),
        updateNewsOrder : builder.mutation<News[], string[]>({
            query: (newsList) => ({
                url : 'news/displayorder',
                method : 'PUT',
                data : newsList
            }),
            transformResponse: (response:any) => response.data,
            transformErrorResponse: () => 'Error updating news display order',
            invalidatesTags : [NEWS_TAG]
        })
    })
})

export const {
    useGetAllNewsQuery,
    useGetNewsByIdQuery,
    useUpdateNewsMutation,
    useDeleteNewsMutation,
    useCreateNewsMutation,
    useUpdateNewsOrderMutation
} = newsApi