import {DefinitionModel} from '../../definitions/model/DefinitionModel'
import {TreeItem, TreeItemIndex} from 'react-complex-tree'
import {displaySetRootName} from '../structure-update/displaySetsStructureUpdate'
import {CustomTreeItem, TreeMap} from '../../genericComponents/tree/CustomTreeModel'
import {WidgetId} from './Widget'
import {Icon} from '@mui/material'

export interface CustomTreeItemForDisplaySetTree extends CustomTreeItem {
    displaySet?: DisplaySetModel,
    definition?: DefinitionModel
}

export interface DisplaySetModel extends CustomTreeItemForDisplaySetTree {
    displayOrder: number,
    widgetId?: WidgetId,
    iconName?: string,
    attributeDefinitions: DefinitionPositionModel[],
    parentId?: string
}

export interface DefinitionPositionModel {
    displayOrder: number,
    attributeDefinitionId: string
}

export type DisplaySetTreeItem = TreeItem<CustomTreeItemForDisplaySetTree>
export type DisplaySetTree = TreeMap<CustomTreeItemForDisplaySetTree>

export const buildDisplaySetFromNode = (node: TreeItem<CustomTreeItemForDisplaySetTree>,
                                        parentByChildIds: Map<TreeItemIndex, TreeItem<CustomTreeItemForDisplaySetTree>>): DisplaySetModel => {
    const displaySet = node.data.displaySet
    if (!displaySet) return {id: '', label: '', displayOrder: 0, attributeDefinitions: []}

    // extract parent information
    const parent = parentByChildIds.get(node.data.id ?? '')
    let parentId = parent?.index?.toString()
    if (parentId === displaySetRootName) parentId = undefined

    const displayOrder = parent?.children?.findIndex((child) => child === displaySet.id)
    return {
        ...displaySet,
        parentId: parentId,
        displayOrder: displayOrder ?? 0,
        attributeDefinitions: node.children?.map(buildDefinitionPosition) ?? []
    }
}

const buildDefinitionPosition = (id: string | number, displayOrder: number): DefinitionPositionModel => {
    return {
        attributeDefinitionId: id.toString(),
        displayOrder: displayOrder
    }
}


export const DisplaySetIcon = ({iconName}: {iconName?: string | undefined}) => {
    if(!iconName) return <></>
    return <Icon className="display-set-icon">{iconName}</Icon>
}