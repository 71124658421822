import {Guild} from '../models/guild'
import CardContainer from '../../genericComponents/cardContainer/CardContainer'
import {useState} from 'react'
import {Button, IconButton} from '@mui/material'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import {getFormattedDate} from '../../News/NewsTable/customSortableTable/NewsTableStatus'

const GuildDetailsCard = ({guild, guildId}: {guild: Guild, guildId: string | undefined}) => {
    const [isEmailTruncated, setIsEmailTruncated] = useState<boolean>(true)

    const GuildStatusIcon = ({ guildStatus }: { guildStatus: string }) => {
        if (guildStatus === 'APPROVED')
            return <img src="/images/guild/status/guild-active.svg" alt="guild-status-active"
                        className="guild-status-icon" />
        return <></>
    }

    const getConcatGuildAdminsEmail = (guild: Guild) => {
        const adminUsersEmail = guild.guildUsers.filter((guildUser) => guildUser.admin)
            .map(guildUser => { if (guildUser.admin) return guildUser.user.email })
        return adminUsersEmail ? adminUsersEmail.join(', ') : ''
    }

    const truncateGuildAdminsEmail = (fullEmail: string): string => {
        const copyOfFullEmail = `${fullEmail}`
        return `${copyOfFullEmail.substring(0, 120) + '...'}`
    }

    const toggleExpandView = () => {
        setIsEmailTruncated(!isEmailTruncated)
    }

    const isGuildAdminsEmailTooLong = (): boolean => {
        const guildAdminEmail = getConcatGuildAdminsEmail(guild)
        return guildAdminEmail.length > 120
    }

    const fullEmail = getConcatGuildAdminsEmail(guild)

    const shouldDisplayShowMore = isGuildAdminsEmailTooLong()

    const guildAdminsEmailDisplay = () => {
        if (shouldDisplayShowMore)
            if (isEmailTruncated) return truncateGuildAdminsEmail(fullEmail)

        return fullEmail
    }

    const guildValidatedBy = () => {
        const guildValidator = guild.creationValidatedBy?.email
        if (!guildValidator) return '-'
        const creationValidationDate = guild.creationValidationDate ? `(${getFormattedDate(guild.creationValidationDate)})` : ''

        return `${guildValidator} ${creationValidationDate}`
    }

    return <CardContainer contentClassName="guild-details" fullWidth>
        <div className="left-container">
            <label>Guild Name</label>
            <div className="guild-name-status-container">
                <span className="guild-name-value">{guild.name}</span>
                <GuildStatusIcon guildStatus={guild.status}/>
            </div>
            <label>Guild ID</label>
            <div className="guild-id-copy-button-container">
                <span className="guild-id-value">{guildId}</span>
                <IconButton onClick={() => {navigator.clipboard.writeText(guildId ?? '')}}>
                    <ContentCopyIcon className="pasteIcon" />
                </IconButton>
            </div>
            <label>Contract ID</label><span className="contract-id-value">{guild.contractId}</span>
        </div>
        <div className="right-container">
            <label>Contract Owner</label><span className="contract-owner-value">{guild.contractOwner ?? '-'}</span>
            <label>Guild Validated By</label><span className="validated-by-value">{guildValidatedBy()}</span>
            <label>Guild Admin Name</label>
            <div className="guild-admin-value-container">
                <span className="guild-admin-value">{guildAdminsEmailDisplay()}</span>
                {shouldDisplayShowMore && <Button className="expand-button"
                                                  onClick={toggleExpandView}>
                    {isEmailTruncated ? 'Show All' : 'Collapse'}
                </Button>}
            </div>
        </div>
    </CardContainer>
}

export default GuildDetailsCard