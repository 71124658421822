import React from 'react'
import {ROUTES} from '../../util/constants/routing'
import ItemNavigationMenu from '../headerGenericComponents/ItemNavigationMenu'
import {ItemNavigation} from '../headerGenericComponents/ItemNavigation'

const StructureMenu = () => {

    const data: ItemNavigation[] = [
        {
            label: 'Display Sets',
            objId: '1',
            url: ROUTES.displaySet.path ?? '',
            dataTestId: 'displaySets'
        },
        // {
        //     label: 'Information Packages',
        //     objId: '2',
        //     url: ROUTES.informationPackage.path ?? '',
        //     dataTestId: 'informationPackages'
        // },
        {
            label: 'Preferred Definitions',
            objId: '3',
            url: ROUTES.preferredDefinitions.path ?? '',
            dataTestId: 'preferredDefinitions'
        }
    ]

    return (
        <ItemNavigationMenu
            label="Structure"
            subRoute="/portal-structure"
            itemList={data}
        />
    )
}

export default StructureMenu
