import {DefinitionModel} from '../../definitions/model/DefinitionModel'
import {TreeItem, TreeItemIndex} from 'react-complex-tree'
import {CustomTreeItem, TreeMap} from '../../genericComponents/tree/CustomTreeModel'

export interface CustomTreeItemForPreferredDefinitionsTree extends CustomTreeItem {
    parentNode?: string;
    technicalNode?: TechnicalNodeModel,
    definition?: DefinitionModel
}

export interface TechnicalNodeModel extends CustomTreeItemForPreferredDefinitionsTree {
    label: string,
    parentId?: string,
    preferredAttributeDefinitions: PreferredAttributeDefinition[],
}


export interface PreferredAttributeDefinition {
    displayOrder: number,
    definitionId: string,
    classificationNodeId: string,
    forSearch: boolean
}

export type TechnicalNodeTreeItem = TreeItem<CustomTreeItemForPreferredDefinitionsTree>
export type TechnicalNodeTree = TreeMap<CustomTreeItemForPreferredDefinitionsTree>

export const buildPreferredDefinitions = (node: TreeItem<CustomTreeItemForPreferredDefinitionsTree>, technicalNodesById: Map<TreeItemIndex, TreeItem<CustomTreeItemForPreferredDefinitionsTree>>): PreferredAttributeDefinition[] => {
    const technicalNode = node.data.technicalNode
    if (!technicalNode) return []

    return node.children?.filter((id) => !technicalNodesById.get(id)) // we don't want the nodes that are classifications, we want only definitions
            .map((id, index) => buildDefinitionPosition(id, index, technicalNode.id))
            .filter(def => def.definitionId) // remove null definitions
        ?? []

}

const buildDefinitionPosition = (id: string | number, displayOrder: number, classificationNodeId: string | undefined): PreferredAttributeDefinition => {
    const splittedId = id.toString().split('_')

    if(splittedId.length !== 2) return {
        definitionId: '',
        classificationNodeId: classificationNodeId ?? '',
        displayOrder: displayOrder,
        forSearch: true
    }

    const definitionId = splittedId[1]
    return {
        definitionId: definitionId,
        classificationNodeId: classificationNodeId ?? '',
        displayOrder: displayOrder,
        forSearch: true
    }
}
