import {File} from './File'
import {getTypedQuotationRule, QuotationRule} from './QuotationRules/QuotationRule'
import {ServiceRequest} from './ServiceRequest'

export type Quotation = {
  quotationFile?: File;
  quotationRules: QuotationRule[];
};

export const getTotalFromRequest = (service: ServiceRequest | undefined) => {
  return getTotalFromQuotation(service?.quotation)
}

export const getTotalFromQuotation = (quotation: Quotation | undefined) => {
  if(!quotation) return 0
  return getTotalQuotation(quotation.quotationRules)
}

export const getTotalQuotation = (rules: QuotationRule[]) => {
  let cost = 0
  rules.forEach((rule) => {
        cost = getTypedQuotationRule(rule).calculate(cost, 0)
      }
  )

  return cost
}
