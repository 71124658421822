import {DraggingPosition, TreeItem} from 'react-complex-tree'

export interface CustomTreeItem {
    id?: string,
    label: string
}

export type TreeMap<T> = Map<string, TreeItem<T>>


export const buildNameAndIdForTreeModel = (name: string): CustomTreeItem => {
    return {
        id: name,
        label: name
    }
}

export const removeItemFromTree = (parentItem: TreeItem, id: string) => {
    const defIndex = parentItem.children?.indexOf(id)
    if (defIndex != null && defIndex > -1) { // only splice array when item is found
        parentItem.children?.splice(defIndex, 1) // 2nd parameter means remove one item only
    }
}

// if targetItem exist, it means the user is dropping on a directory
// so the parent IS the directory, because we want to put the data inside.
// if targetItem does not exist and parentItem exist, the user drop inside the directory
export const getParentIdFromTargetItem = (target: DraggingPosition): string | undefined => {
    if ('targetItem' in target) {
        return target.targetItem.toString()
    }
    if ('parentItem' in target) {
        return target.parentItem.toString()
    }
    return undefined
}