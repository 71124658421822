import {createApi} from '@reduxjs/toolkit/dist/query/react'
import {axiosBaseQuery} from '../rtkQueryUtils'
import {SEARCH_API_ENDPOINT, SEARCH_TRANSLATOR_API_ENDPOINT} from '../util/constants/backendUrls'
import {DefinitionModel} from './model/DefinitionModel'

export const GET_DEFINITIONS = 'GetDefinitions'

export const attributeDefinitionApi = createApi({
    reducerPath: 'attributeDefinitionApi',
    keepUnusedDataFor: 0,
    tagTypes: [GET_DEFINITIONS],
    baseQuery: axiosBaseQuery({baseUrl: `${SEARCH_TRANSLATOR_API_ENDPOINT}`}),
    endpoints: (builder) => ({
        getAllDefinitions: builder.query<DefinitionModel[], void>({
            query: () => ({
                url: '/datx/definitions/root',
                method: 'GET'
            }),
            transformResponse: (response: any) => response,
            transformErrorResponse: (response, meta, arg) => `An error occurred while retrieving attribute definitions ${arg}`,
            providesTags: [GET_DEFINITIONS]
        })
    })
})

export const {useGetAllDefinitionsQuery} = attributeDefinitionApi