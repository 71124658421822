import BannerForm from './common/BannerForm'
import {useGetAllBannersQuery, useUpdateBannerMutation} from '../rtkBannerApi'
import {Banner} from '../model/Banner'
import {useParams} from 'react-router'

const EditBanner = () => {

    const bannerTitle = 'Edit Banner'
    const dialogText = 'Banner has been successfully updated, you may edit or add banner post under Portal Management.'
    const typeParams = {disabled: true}
    const {bannerId} = useParams()
    const {data : bannerList} = useGetAllBannersQuery()
    const currentBannerList = bannerList?.filter((banner) => banner.id === bannerId) ?? []
    const initialBanner = currentBannerList[0]
    const [useUpdateBanner, useUpdateBannerResult] = useUpdateBannerMutation()

    const HandleUpdate = (banner: Banner) => {
        useUpdateBanner(banner)
    }

    return<>
        {initialBanner !== undefined &&
        <BannerForm
            selectParams = {typeParams}
            formTitle = {bannerTitle}
            dialogBodyText = {dialogText}
            handlePublish = {HandleUpdate}
            initialBanner = {initialBanner}
            loading = {useUpdateBannerResult.isLoading}
        />}
    </>
}

export default EditBanner