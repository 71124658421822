import {News} from '../../model/News'
import {Table, TableBody, TableCell, TableContainer, TableHead} from '@mui/material'
import React, {useState} from 'react'
import {arrayMove, SortableContext, sortableKeyboardCoordinates, verticalListSortingStrategy} from '@dnd-kit/sortable'
import {
    closestCenter,
    DndContext,
    DragEndEvent,
    DragOverlay,
    DragStartEvent,
    KeyboardSensor,
    MouseSensor,
    PointerSensor,
    useSensor,
    useSensors
} from '@dnd-kit/core'
import {useUpdateNewsOrderMutation} from '../../rtkNewsApi'
import {restrictToVerticalAxis} from '@dnd-kit/modifiers'
import {DraggableNewsRow, NewsTableRow} from './NewsTableRow'
import {setSnackbarMessage} from '../../../util/commonSlice'
import {useTypedDispatch} from '../../../util/store'
import LoadingSpinner from '../../../genericComponents/spinner/LoadingSpinner'


const CustomNewsSortableTable = ({
    newsList,
    isLoading
                                 }:{
    isLoading: boolean
    newsList: News[]
}) => {

    const dispatch = useTypedDispatch()
    const newsTableRow = ['News Title', 'Author', 'Status', 'Date of Creation', 'Last Modified', 'Actions']

    const [useUpdateNewsOrder] = useUpdateNewsOrderMutation()
    const [currentDraggingNews, setCurrentDraggingNews] = useState<News>()

    const enabledNews = newsList.filter((news) => news.enabled)
    const disabledNews = newsList.filter((news) => !news.enabled)

    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        }),
        useSensor(MouseSensor, { activationConstraint: { distance: 5 } })
    )

    const handleDragStart = (event: DragStartEvent) => {
        setCurrentDraggingNews(newsList.find((news) => news.id === event.active.id))
    }

    const HandleDragEnd = (event: DragEndEvent) => {
        const {active, over} = event
        if (!over) return
        setCurrentDraggingNews(undefined)
        const newsIdList  = newsList.map(({id}) => id)

        if ( active.id !== over?.id) {
            const oldIndex = newsIdList.indexOf(active.id.toString())
            const newIndex = newsIdList.indexOf(over?.id.toString() ?? '')

            HandleOrderChange(arrayMove(newsIdList, oldIndex, newIndex))
        }
    }

    const HandleOrderChange = async (newsIdList: string[]) => {
        const result = await useUpdateNewsOrder(newsIdList)
        if ('data' in result) {
            dispatch(setSnackbarMessage('News Display Order Changed'))
            }
        else {
            dispatch(setSnackbarMessage('Failed to change News Display Order'))
        }
    }

    if (isLoading) return <LoadingSpinner />

    if (!isLoading && newsList.length === 0) return <div>No News & Announcements Found</div>

    return<>
        <TableContainer>
            <DndContext
                sensors={sensors}
                collisionDetection={closestCenter}
                onDragEnd={HandleDragEnd}
                onDragStart={handleDragStart}
                modifiers={[restrictToVerticalAxis]}
            >
                <Table>
                    <TableHead
                        className="table-header-column"
                    >
                        <TableCell/>
                        {newsTableRow.map((title) =>
                            <TableCell
                                className = "header-cell"
                            >
                                {title}
                            </TableCell>
                        )}
                    </TableHead>
                    <TableBody>
                        <SortableContext items={newsList} strategy={verticalListSortingStrategy}>
                        {enabledNews.map((news) =>
                            <NewsTableRow news ={news}/>
                        )}
                        </SortableContext>
                        {disabledNews.map((news) =>
                            <NewsTableRow news ={news}/>
                        )}
                        <DragOverlay
                            className="news-drag-overlay"
                            dropAnimation={null}
                        >
                            {currentDraggingNews ?
                                <DraggableNewsRow news={currentDraggingNews}/>
                                : null}
                        </DragOverlay>
                    </TableBody>
                </Table>
            </DndContext>
        </TableContainer>
    </>
}

export default CustomNewsSortableTable