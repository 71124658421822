import React, {useState} from 'react'
import './GuildRequestsTable.scss'
import {GridColDef, GridRenderCellParams,} from '@mui/x-data-grid'
import {DenyGuildRequest} from './DenyGuildRequest'
import {Guild} from '../models/guild'
import GuildStatus from '../models/guildStatus'
import getGuildStatus, {GuildCreationStatus} from '../models/GuildCreationStatus'
import AcceptGuildCreationForm from './AcceptGuildCreationForm'
import {IconButton, Menu, MenuItem} from '@mui/material'
import {MoreHoriz} from '@mui/icons-material'
import {getFormattedDate} from '../../util/table'
import {useDeleteDeniedGuildMutation, useGetInactiveGuildsQuery} from '../rtkGuildApi'
import {SortDates} from '../../genericComponents/utils/DateService'
import {useDispatch} from 'react-redux'
import {setSnackbarMessage} from '../../util/commonSlice'
import DaTXDataGrid from '../../genericComponents/grid/DaTXDataGrid'
import {equalsOperator, matchString} from '../../genericComponents/grid/gridFilterOperators'


const GuildRequestsTable = () => {
    const {data: guilds = [], isLoading} = useGetInactiveGuildsQuery()


    const getEmail = ({row}: { row: Guild }) => {
        return row?.guildUsers[0]?.user?.email
    }

    const getGuildName = ({row}: { row: Guild }) => {
        return row.name
    }


    const getStatusValue = ({row}: { row: Guild }) => {
        const type: GuildCreationStatus = getGuildStatus(row.status)
        return type.label
    }

    const getStatus = (params: GridRenderCellParams) => {
        const status = params?.row?.status
        const type: GuildCreationStatus = getGuildStatus(status)
        return <div style={{ color: type.color }}>{type.label}</div>
    }

    const columns: GridColDef[] = [

        {
            field: 'email',
            headerName: 'Requester Email',
            flex: 1,
            minWidth: 200,
            sortable: true,
            valueGetter: getEmail,
            filterOperators: [matchString]
        },
        {
            field: 'name',
            headerName: 'Guild Name',
            flex: 1,
            minWidth: 200,
            sortable: true,
            valueGetter: getGuildName,
            filterOperators: [matchString]
        },
        {
            field: 'status',
            headerName: 'Status',
            flex: 1,
            minWidth: 150,
            sortable: true,
            valueGetter: getStatusValue,
            renderCell: getStatus,
            filterOperators: [equalsOperator]
        },
        {
            field: 'createdDate',
            headerName: 'Creation Date',
            flex: 1,
            minWidth: 200,
            sortable: true,
            filterable: false,
            valueGetter: getFormattedDate,
            sortComparator: (v1, v2) => SortDates(v1, v2)
        },
       {
            field: 'updatedDate',
            headerName: 'Last Modified',
            flex: 1,
            minWidth: 200,
            sortable: true,
            filterable: false,
            valueGetter: getFormattedDate,
            sortComparator: (v1, v2) => SortDates(v1, v2)
        },
        {
            field: 'action',
            headerName: 'Actions',
            flex: 1,
            minWidth: 40,
            sortable: false,
            headerClassName: 'more-option-menu-header',
            disableColumnMenu: true,
            renderCell: (params) => <GetActions params={params}/>,
        },
    ]

    return (
        <>
            <h1 className="page-title">Guild Creation Requests</h1>

            <div className="guild-requests-grid-container">
                <DaTXDataGrid
                    isLoading={isLoading}
                    emptyRowMessage="No Guild Creation Requests found"
                    columns={columns}
                    rows={guilds}
                    getRowId={(guild: Guild) => guild?.id}
                >
                </DaTXDataGrid>
            </div>
        </>
    )
}

export default GuildRequestsTable

const GetActions = ({params}: { params: GridRenderCellParams}) => {
    const currentGuild = params?.row
    const [status, setStatus] = useState<string>('')
    const [anchorEl, setAnchorEl] = useState<Element | null>(null)
    const open = Boolean(anchorEl)
    const dispatch = useDispatch()
    const [deleteGuild] = useDeleteDeniedGuildMutation()


    const deleteDeniedGuildRequest = (guildToDelete : Guild) => {
        deleteGuild(guildToDelete).unwrap()
        .then(() => dispatch(setSnackbarMessage('The \"denied\" request has been deleted')))
            .catch(() => dispatch(setSnackbarMessage('Failed to delete guild')))
    }


    const afterClose = () => {
        setStatus('')
        closeMenu()
    }

    const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget)
    }

    const handleOptionClick = (status: GuildStatus) => {
        setStatus(status)
        closeMenu()
    }
    
    const closeMenu = () => {
        setAnchorEl(null)
    }

    return <>
        <IconButton
            onClick={handleMenuClick}
            disabled={currentGuild.status == GuildStatus.APPROVED}
        >
            <MoreHoriz />
        </IconButton>
        <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={closeMenu}
        >
            {currentGuild.status !== GuildStatus.DENIED &&
                <>
                <MenuItem onClick={() => handleOptionClick(GuildStatus.APPROVED)}>Approve request</MenuItem>
                <MenuItem onClick={() => handleOptionClick(GuildStatus.DENIED)}>Deny request</MenuItem>
                </>
            }
            {currentGuild.status === GuildStatus.DENIED &&
                <MenuItem  onClick={() => deleteDeniedGuildRequest(currentGuild)}>Delete</MenuItem>
            }
        </Menu>
        {status === GuildStatus.DENIED && <DenyGuildRequest afterClose={afterClose} currentGuild={currentGuild}/>}
        {status === GuildStatus.APPROVED && <AcceptGuildCreationForm currentGuild={currentGuild}/>}

    </>
}

