import React, {ReactElement, useMemo} from 'react'
import {DraggingPosition, StaticTreeDataProvider, Tree, UncontrolledTreeEnvironment} from 'react-complex-tree'
import 'react-complex-tree/lib/style-modern.css'
import LoadingSpinner from '../../genericComponents/spinner/LoadingSpinner'
import {useGetAllDefinitionsQuery} from '../../definitions/rtkAttributeDefinitionsApi'
import {DefinitionModel} from '../../definitions/model/DefinitionModel'
import './preferredDefinitionsStructureUpdate.scss'
import {ClassificationNodeForTree} from './classificationNodeForTree'
import '../../genericComponents/tree/customTree.scss'
import {
    buildPreferredDefinitions,
    PreferredAttributeDefinition,
    TechnicalNodeTree,
    TechnicalNodeTreeItem
} from '../model/TechnicalNodeModel'
import {
    useGetAllTechnicalNodesPreferredDefinitionsQuery,
    useReplaceAllTechnicalNodesPreferredDefinitionsMutation
} from '../rtkPreferredDefinitionApi'
import {
    buildNameAndIdForTreeModel,
    getParentIdFromTargetItem,
    removeItemFromTree
} from '../../genericComponents/tree/CustomTreeModel'
import {DefinitionForPreferredDefinitionTree} from './definitionForPreferredDefinitionTree'
import {TreeInformation} from 'react-complex-tree/src/types'
import {DisplaySetTreeItem} from '../../displaySets/model/DisplaySetModel'
import {useGetTechnicalNodeTreeQuery} from '../rtkClassificationNodes'
import {ClassificationNode, getAllNodesFromTree} from '../model/ClassificationNode'
import {SubmitButton} from '../../genericComponents/button/submitButton'


export const technicalNodeRootName = 'technicalNodeRoot'
export const technicalNodeTreeName = 'technicalNodeRoot'
const technicalNodeRootNode: TechnicalNodeTreeItem = {
    index: technicalNodeRootName,
    children: [],
    isFolder: true,
    data: buildNameAndIdForTreeModel(technicalNodeRootName)
}

export const definitionRootName = 'definitionRoot'
export const definitionTreeName = 'definitions-tree'
const definitionRootNode: TechnicalNodeTreeItem = {
    index: definitionRootName,
    children: [],
    isFolder: true,
    data: buildNameAndIdForTreeModel(definitionRootName)
}


// ========== Technical Nodes

const createDefinitionNodeForClassificationNode = (definition: DefinitionModel | undefined, classificationNodeId: string): TechnicalNodeTreeItem => {
    return {
        index: classificationNodeId + '_' + definition?.id,
        isFolder: false,
        data: {
            id: definition?.id,
            label: definition?.name ?? '',
            definition: definition,
            parentNode: classificationNodeId
        },
        canMove: true,
        canRename: false
    }
}

// for every preferred definition, we create a duplicate with an id composed of the definition id and the classification id
// like that we can have multiple time the same definition in the tree
const getDefinitionNodesForClassificationNode = (preferredDefinitions: PreferredAttributeDefinition[], definitionsById: Map<string, DefinitionModel>): TechnicalNodeTreeItem[] => {
    const sortedPreferredDefinitions = preferredDefinitions.sort((position1, position2) => position1.displayOrder - position2.displayOrder)
    return sortedPreferredDefinitions.map((preferredDefinition) => {
        const definition = definitionsById.get(preferredDefinition.definitionId)
        return createDefinitionNodeForClassificationNode(definition, preferredDefinition.classificationNodeId)
    }).filter(node => node.data?.definition)
}

const getOneTechnicalNodeNodeAndItsDefinitions = (classificationNode: ClassificationNode, preferredDefinitions: PreferredAttributeDefinition[], definitionsById: Map<string, DefinitionModel>): TechnicalNodeTreeItem[] => {
    const definitionNodesOfTechnicalNode = getDefinitionNodesForClassificationNode(preferredDefinitions, definitionsById)
    const definitionIds = definitionNodesOfTechnicalNode
        .map((definition) => definition.index.toString())

    const childNodeIds = [...classificationNode.children] // need to duplicate the array because it's on readOnly (coming from the back)
        .sort((childNode1, childNode2) => { // sort on alphabetical order
            if (childNode1.label < childNode2.label) return -1
            if (childNode1.label > childNode2.label) return 1
            return 0
        }).map(subNode => subNode.id)

    const technicalNode = {
        index: classificationNode.id,
        children: [...childNodeIds, ...definitionIds],
        isFolder: true,
        data: {
            id: classificationNode.id,
            label: classificationNode.label,
            technicalNode: {
                label: classificationNode.label,
                parentId: classificationNode.parentId,
                id: classificationNode.id,
                preferredAttributeDefinitions: preferredDefinitions,
            }
        },
        canMove: true,
        canRename: false
    }
    return [technicalNode, ...definitionNodesOfTechnicalNode]

}

const getTechnicalNodeNodes = (preferredDefinitions: PreferredAttributeDefinition[], technicalNodes: ClassificationNode[], definitions: DefinitionModel[]): TechnicalNodeTree => {
    const definitionsById = new Map<string, DefinitionModel>(definitions.map(def => [def.id, def]))
    const preferredDefinitionsByNodeId = preferredDefinitions.reduce((result: Map<string, PreferredAttributeDefinition[]>, currentValue: PreferredAttributeDefinition) => {
        const classificationId = currentValue.classificationNodeId
        const existingClassification = result.get(classificationId)
        if (existingClassification) existingClassification.push(currentValue)
        else result.set(classificationId, [currentValue])

        return result
    }, new Map())

    const technicalNodesAsTreeItems: TechnicalNodeTree = new Map(
        technicalNodes.flatMap(node => {
            const preferredDefinitions = preferredDefinitionsByNodeId.get(node.id) ?? []
            return getOneTechnicalNodeNodeAndItsDefinitions(node, preferredDefinitions, definitionsById).map(item => [item.index.toString(), item])
        })
    )


    // add the root node
    technicalNodeRootNode.children = technicalNodes.filter(node => !node.parentId).map(node => node.id)
    technicalNodesAsTreeItems.set(technicalNodeRootName, technicalNodeRootNode)

    return technicalNodesAsTreeItems
}


// ========== Definition Nodes

const sortDefinitionByName = (definitions: DefinitionModel[]): string[] => {
    return [...(definitions ?? [])]
        .sort((def1, def2) => {
            if (def1.name < def2.name) return -1
            if (def1.name > def2.name) return 1
            return 0
        })
        .map((def) => def.id)
}

const createDefinitionNode = (definition: DefinitionModel): TechnicalNodeTreeItem => {
    return {
        index: definition.id,
        isFolder: false,
        data: {
            id: definition.id,
            label: definition.name,
            definition: definition
        },
        canMove: true,
        canRename: false
    }
}

const getDefinitionNodes = (definitions: DefinitionModel[]): TechnicalNodeTree => {
    const definitionsAsTreeItems: TechnicalNodeTree = new Map(definitions.map((definition) => {
        return [definition.id, createDefinitionNode(definition)]
    }))

    definitionRootNode.children = sortDefinitionByName(definitions)
    definitionsAsTreeItems.set(definitionRootName, definitionRootNode)

    return definitionsAsTreeItems
}

const createTechnicalNodePreferredDefinitionsTree = (preferredDefinitions: PreferredAttributeDefinition[] | undefined, definitions: DefinitionModel[] | undefined, technicalNodes: ClassificationNode[]): TechnicalNodeTree => {
    if (!preferredDefinitions) return new Map<string, TechnicalNodeTreeItem>()
    if (!definitions) return new Map<string, TechnicalNodeTreeItem>()

    const definitionsAsTreeItems = getDefinitionNodes(definitions)
    const technicalNodesAsTreeItems = getTechnicalNodeNodes(preferredDefinitions, technicalNodes, definitions)

    return new Map<string, TechnicalNodeTreeItem>([...technicalNodesAsTreeItems, ...definitionsAsTreeItems])
}


export const PreferredDefinitionsStructureUpdate = () => {
    const {
        data: preferredDefinitions,
        isFetching: isFetchingPreferredDefinitions
    } = useGetAllTechnicalNodesPreferredDefinitionsQuery()
    const {data: definitions, isFetching: isFetchingDefinitions} = useGetAllDefinitionsQuery()
    const {data: technicalNodeTree, isFetching: isFetchingTechnicalNodeTree} = useGetTechnicalNodeTreeQuery()
    const [updatePreferredDefinitions, updatePreferredDefinitionsResult] = useReplaceAllTechnicalNodesPreferredDefinitionsMutation()

    const technicalNodes = getAllNodesFromTree(technicalNodeTree)

    const preferredDefinitionsTree: TechnicalNodeTree = useMemo(() => createTechnicalNodePreferredDefinitionsTree(preferredDefinitions, definitions, technicalNodes),
        [preferredDefinitions, definitions, technicalNodes])
    const treeEntries = Object.fromEntries(preferredDefinitionsTree.entries())

    const dataProvider = useMemo(
        () => new StaticTreeDataProvider(treeEntries),
        [treeEntries]
    )

    const customNodeDisplay = (node: TechnicalNodeTreeItem, info: TreeInformation): ReactElement | string => {
        if (!node?.data) return ''
        if (node.data?.definition) return <DefinitionForPreferredDefinitionTree node={node}
                                                                                definition={node.data.definition}
                                                                                treeInfo={info}></DefinitionForPreferredDefinitionTree>
        if (node?.data?.technicalNode) return <ClassificationNodeForTree node={node}
                                                                         technicalNode={node.data.technicalNode}></ClassificationNodeForTree>
        return node.data.label
    }


    // check if we can drop a definition
    // inside a folder (a technical node) >= 1
    // or in between other definitions
    // the problem comes from the multi select. you can multi select folders and / or definitions
    // if you multi select objects of different type we disable the drop
    const canDropDefinition = (nodes: DisplaySetTreeItem[], target: DraggingPosition) => {
        if (!(nodes?.length)) return false

        // as we can drag only leaf, in theory we have only definitions that we can drop, so no need to check

        if (target.treeId === definitionTreeName) // if definition is drop in the def tree, then can (remove a definition)
            return true

        // can drop in the technicalNode tree only inside an technicalNode >= level 2
        return (target.targetType === 'item' && target.depth >= 1) // in a child display set
            || (target.targetType === 'between-items' && target.depth >= 2)
    }

    const canDrag = (nodes: TechnicalNodeTreeItem[]) => {
        if (!(nodes?.length)) return false

        const allAreFolders = nodes.every((node) => node.isFolder)
        if (allAreFolders) return false // if it's a folder, it's a technical node, so cannot move

        const allAreLeafs = nodes.every((node) => !node.isFolder)
        if (!allAreLeafs) return false // we have some folders and some definitions, so cannot

        // we have only leaf, so only definitions, so can drag
        return true
    }


    const onDropInDefinitionTree = (items: TechnicalNodeTreeItem[], target: DraggingPosition) => {
        items.forEach(item => {
            // delete the definitionForNode of the technicalNode tree
            delete treeEntries[item.index.toString()]
            dataProvider.onDidChangeTreeDataEmitter.emit([item.data.parentNode ?? technicalNodeRootName])
        })
    }


    const onDropInTechnicalNodeTree = (items: TechnicalNodeTreeItem[], target: DraggingPosition) => {
        const classificationId = getParentIdFromTargetItem(target)
        if (!classificationId) return
        const classificationNode = preferredDefinitionsTree.get(classificationId)
        if (!classificationNode) return

        items.forEach(item => {
            const definition = item.data.definition
            if (!definition) return
            // the element is moved to a node, so we need to add it back to the definition list
            // for that we can simply reset the children to the sorted list of all definitions
            definitionRootNode.children = sortDefinitionByName(definitions ?? [])
            // and remove the previous node
            delete treeEntries[item.index.toString()]

            // remove the definitionNode from the list to let only the definitionForTechnicalNode
            removeItemFromTree(classificationNode, item.index.toString())

            // add the correct new element in the tree
            const newDefinitionNodeForTechnicalNode = createDefinitionNodeForClassificationNode(definition, classificationId)
            // do nothing if already in the children
            const defForNodeIndex = classificationNode.children?.indexOf(newDefinitionNodeForTechnicalNode.index.toString())
            if (defForNodeIndex != null && defForNodeIndex > -1) return

            // else add it to the tree and to the parent
            treeEntries[newDefinitionNodeForTechnicalNode.index.toString()] = newDefinitionNodeForTechnicalNode
            classificationNode.children?.push(newDefinitionNodeForTechnicalNode.index)
        })
        dataProvider.onDidChangeTreeDataEmitter.emit([classificationId])
    }

    const onDrop = (items: TechnicalNodeTreeItem[], target: DraggingPosition) => {
        if (target.treeId === definitionTreeName) onDropInDefinitionTree(items, target)
        if (target.treeId === technicalNodeTreeName) onDropInTechnicalNodeTree(items, target)
    }

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()

        const allNodes = Array.from(preferredDefinitionsTree.values())
        const technicalNodes = allNodes.filter((node) => node?.data?.technicalNode)
        const technicalNodesById = new Map(technicalNodes.map((node) => [node.index, node]))

        const newPreferredDefinitions: PreferredAttributeDefinition[] = technicalNodes.flatMap((node) => buildPreferredDefinitions(node, technicalNodesById))
            .filter((preferredDefinition) => preferredDefinition != null) // remove null preferredDefinition
        updatePreferredDefinitions(newPreferredDefinitions ?? [])
    }


    return <form className={'preferred-definitions-form'} onSubmit={handleSubmit}>
        <h1 className="page-title">Preferred Definitions structure</h1>

        {isFetchingPreferredDefinitions || isFetchingDefinitions || isFetchingTechnicalNodeTree || updatePreferredDefinitionsResult.isLoading ?
            <LoadingSpinner/> :
            <div className="preferred-definitions-edit">
                <UncontrolledTreeEnvironment
                    dataProvider={dataProvider}
                    getItemTitle={node => node?.data?.label ?? ''}
                    viewState={{}}
                    canDrag={(items) => canDrag(items)}
                    canDragAndDrop={true}
                    canDropOnFolder={true}
                    canDropOnNonFolder={false}
                    canReorderItems={true}
                    canDropAt={(nodes, target) => canDropDefinition(nodes, target)}
                    renderItemTitle={({item, info}) => customNodeDisplay(item, info)}
                    onDrop={(items, target) => onDrop(items, target)}
                >
                    <div className="definitions-tree tree-container">
                        <h3 className="tree-title">All Root Definitions
                            ({definitions?.length})</h3>
                        <div className="tree">
                            <Tree treeId={definitionTreeName} rootItem={definitionRootName}
                                  treeLabel="All Definitions"/>
                        </div>
                    </div>

                    <div className="technical-nodes-with-definitions-tree tree-container">
                        <h3 className="tree-title">
                            <span>Technical Nodes ({technicalNodes?.length})</span>
                            <span className="action-buttons">
                                <SubmitButton loading={updatePreferredDefinitionsResult.isLoading}>Save</SubmitButton>
                            </span>
                        </h3>
                        <div className="tree">
                            <Tree treeId={technicalNodeTreeName} rootItem={technicalNodeRootName}
                                  treeLabel="Technical nodes with definitions"/>
                        </div>
                    </div>


                </UncontrolledTreeEnvironment>
            </div>
        }
    </form>
}