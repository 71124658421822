import {Divider, InputLabel, MenuItem, Select, TextField, Typography} from '@mui/material'
import BannerType from '../../model/BannerType'
import {Banner} from '../../model/Banner'
import {object, string} from 'yup'
import {useFormik} from 'formik'
import {useState} from 'react'
import BannerDialog from './BannerDialog'
import {LoadingButton} from '@mui/lab'

const BannerForm = (
    {
        formTitle,
        handlePublish,
        initialBanner,
        dialogBodyText,
        selectParams,
        loading
    }:{
        formTitle: string
        handlePublish : (banner: Banner) => void
        initialBanner : Banner
        dialogBodyText : string
        selectParams : object
        loading : boolean
    }
) => {
    const [openDialog, setOpenDialog] = useState(false)

    const validationSchema = object({
        content: string().required().max(200,),
        title: string().required().max(100,),
        type: string().required()
    })

    const handleSubmit = () => {
        handlePublish({...initialBanner,
            title :formik.values.title,
            content :formik.values.content,
            type :formik.values.type,
        })
        setOpenDialog(true)
    }

    const formik = useFormik({
            initialValues: {
                title : initialBanner?.title ?? '',
                content : initialBanner?.content ?? '',
                type : initialBanner?.type
            },
            validationSchema: validationSchema,
            onSubmit: handleSubmit
        }
    )

    const wordCount = formik.values.content.length

    const Asterisk = ({
        currentComponent,
        currentPage
                      }:{
        currentComponent : string
        currentPage : string
    }) => {
        return <>{ (currentPage === 'Add Banner' || currentComponent !== 'type') &&
            <span className="asterisk">*</span>}
        </>
    }

    return <>
        <form onSubmit={formik.handleSubmit}>
        <div className="banner-actions-form">
            <div className="banner-form-title-container">
                <h1 className="banner-form-title">
                    {formTitle}
                </h1>
                <Divider className="divider-title"/>
            </div>
            <div>
                <InputLabel className="banner-input-label">
                    Banner Title<Asterisk currentComponent= "title" currentPage={formTitle}/>
                </InputLabel>
                <TextField
                    id = "title"
                    value={formik.values.title}
                    onChange={formik.handleChange}
                    className="banner-title-textfield"
                    size="small"
                    placeholder="Write your banner title here"
                    inputProps={{maxLength: 100}}
                    required
                    aria-invalid= {formik.touched.title && Boolean(formik.errors.title)}
                />
            </div>
            <div>
                <div className="text-count-container">
                    <InputLabel className="banner-input-label-count">
                        Banner Text<Asterisk currentComponent= "content" currentPage={formTitle}/>
                    </InputLabel>
                    <Typography className="text-count" variant="caption">
                        {wordCount}
                    </Typography>
                </div>
                <TextField
                    id = "content"
                    value={formik.values.content}
                    onChange={formik.handleChange}
                    inputProps={{maxLength: 200}}
                    className="banner-text-textfield"
                    multiline
                    rows={2}
                    placeholder="Write your banner text here"
                    helperText="Max. 200 Characters"
                    required
                    aria-invalid= {formik.touched.content && Boolean(formik.errors.content)}
                />
            </div>
            <div>
                <InputLabel className="banner-input-label" {...selectParams}>
                    Banner Status<Asterisk currentComponent= "type" currentPage={formTitle}/>
                </InputLabel>
                <Select
                    id = "type"
                    name = "type"
                    className="banner-status-select"
                    size="small"
                    value={formik.values.type}
                    displayEmpty
                    onChange={formik.handleChange}
                    required
                    aria-invalid= {formik.touched.type && Boolean(formik.errors.type)}
                    {...selectParams}
                >
                    <MenuItem value="" disabled>Select a Banner Status</MenuItem>
                    <MenuItem value={BannerType.WARNING}>Yellow- Warning</MenuItem>
                    <MenuItem value={BannerType.SUCCESS}>Green- Success</MenuItem>
                    <MenuItem value={BannerType.ALERT}>Red- Urgent</MenuItem>
                    <MenuItem value={BannerType.INFO}>Blue- Informational</MenuItem>
                </Select>
            </div>
            <Divider className="banner-bottom-divider"/>
            <LoadingButton
                className = "publish-button"
                variant="contained"
                type = "submit"
                disabled={!formik.dirty || !formik.isValid}
                disableElevation
                loading={loading}
            >
                Publish
            </LoadingButton>
        </div>
        </form>
        <BannerDialog openDialog={openDialog} dialogBodyText={dialogBodyText}/>
    </>
}


export default BannerForm