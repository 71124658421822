import {CustomTreeItemForDisplaySetTree, DisplaySetIcon, DisplaySetModel} from '../model/DisplaySetModel'
import {TreeItem} from 'react-complex-tree'
import {EditButtonForTree} from '../../genericComponents/tree/EditButtonForTree'
import {ROUTES} from '../../util/constants/routing'


export const DisplaySetForTree = ({node, displaySet}: {
    node: TreeItem<CustomTreeItemForDisplaySetTree>,
    displaySet: DisplaySetModel
}) => {

    let widget = displaySet?.widgetId ?? ''
    if (displaySet?.widgetId === 'DEFAULT')
        widget = ''

    return <div className={'display-set-for-tree'}>
        <DisplaySetIcon iconName={displaySet.iconName}></DisplaySetIcon>
        {displaySet.label} ({node.children?.length}) {widget ? displaySet.widgetId : ''}
        <EditButtonForTree path={ROUTES.displaySet.path + '/' + displaySet.id}></EditButtonForTree>
    </div>
}