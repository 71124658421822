import CustomModal from '../../../genericComponents/customModal/CustomModal'
import InfoRoundedIcon from '@mui/icons-material/InfoRounded'
import {Delete, FileUpload} from '@mui/icons-material'
import {LoadingButton} from '@mui/lab'
import {Button, DialogActions, DialogContent, DialogTitle} from '@mui/material'
import CancelRoundedIcon from '@mui/icons-material/CancelRounded'
import Alert from '@mui/material/Alert'

import '../ServiceRequestActions/ServiceRequestActions.scss'
import {ChangeEvent, useState} from 'react'
import {updateServiceRequestFile} from '../../serviceRequestAsyncActions'
import {useDispatch} from 'react-redux'
import {ServiceRequest} from '../../models/ServiceRequest'
import {MAX_FILE_SIZE} from '../../../util/constants/requests'
import UploadFile from '../../Files/UploadFile/UploadFile'

const UpdateDetailsModal = ({
                                afterClose,
                                serviceRequest
                            }: {
    afterClose: () => void,
    serviceRequest: ServiceRequest
}) => {
    const [file, setFile] = useState<File>()
    const [open, setOpen] = useState<boolean>(true)
    const dispatch = useDispatch()

    const handleCompleteUploadForm = (file: File) => {
        updateServiceRequestFile(serviceRequest.id, file, dispatch).then(() => {
            close()
        })
    }

    const close = () => {
        setOpen(false)
        afterClose()
    }


    return <>
        {open &&
            <CustomModal handleClose={close}
                         title={<>
                             <InfoRoundedIcon color={'primary'}/>
                             <span>Update Details</span>
                         </>}

                         body={<UploadFile file={file} setFile={setFile}/>}

                         actions={<>
                             <Button type="button" color="primary" className="cancel" onClick={close}>
                                 Cancel
                             </Button>
                             <LoadingButton
                                 variant="contained"
                                 color="primary"
                                 disabled={!file}
                                 onClick={() => file && handleCompleteUploadForm(file)}
                             >
                                 Next
                             </LoadingButton>
                         </>}
            >
            </CustomModal>
        }
    </>
}

export default UpdateDetailsModal
