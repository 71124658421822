import { BaseQueryFn} from '@reduxjs/toolkit/query/react'
import axios, { AxiosError, AxiosRequestConfig } from 'axios'
import { isRejectedWithValue } from '@reduxjs/toolkit'
import type { MiddlewareAPI, Middleware } from '@reduxjs/toolkit'
import { setSnackbarMessage } from './util/commonSlice'

//From rtk documentation : https://redux-toolkit.js.org/rtk-query/usage/customizing-queries#axios-basequery
 export const axiosBaseQuery =
  ({ baseUrl }: { baseUrl: string } = { baseUrl: '' }): BaseQueryFn<
    {
      url: string
      method: AxiosRequestConfig['method']
      data?: AxiosRequestConfig['data']
      params?: AxiosRequestConfig['params']
    },
    unknown,
    unknown
  > =>
  async ({ url, method, data, params }) => {
    try {
      const result = await axios({ url: baseUrl + url, method, data, params })
      return { data: result.data }
    } catch (axiosError) {
      const err = axiosError as AxiosError
      return {
        error: {
          status: err.response?.status,
          data: err.response?.data || err.message,
        },
      }
    }
  }



  //From rtk documentation: https://redux-toolkit.js.org/rtk-query/usage/error-handling#handling-errors-at-a-macro-level
  export const rtkQueryErrorLogger: Middleware =
    (api: MiddlewareAPI) => (next) => (action) => {
      if (isRejectedWithValue(action) && typeof action.payload === 'string') {
        api.dispatch(setSnackbarMessage(action.payload))
      }
  
      return next(action)
    }