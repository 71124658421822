import {createApi} from '@reduxjs/toolkit/dist/query/react'
import {axiosBaseQuery} from '../rtkQueryUtils'
import {SEARCH_TRANSLATOR_API_ENDPOINT} from '../util/constants/backendUrls'
import {InformationPackageModel} from './model/InformationPackageModel'
import {DisplaySetModel} from '../displaySets/model/DisplaySetModel'
import {GET_DISPLAY_SETS} from '../displaySets/rtkDisplaySetsApi'

export const GET_INFORMATION_PACKAGES = 'GetInformationPackages'

export const informationPackagesApi = createApi({
    reducerPath: 'informationPackagesApi',
    keepUnusedDataFor: 0,
    tagTypes: [GET_INFORMATION_PACKAGES],
    baseQuery: axiosBaseQuery({baseUrl: `${SEARCH_TRANSLATOR_API_ENDPOINT}`}),
    endpoints: (builder) => ({
        getAllInformationPackages: builder.query<InformationPackageModel[], void>({
            query: () => ({
                url: '/datx/informationPackage',
                method: 'GET'
            }),
            transformResponse: (response: InformationPackageModel[]) => response,
            transformErrorResponse: (response, meta, arg) => `An error occurred while retrieving information packages ${arg}`,
            providesTags: [GET_INFORMATION_PACKAGES]
        }),
        getInformationPackage: builder.query<InformationPackageModel, string>({
            query: (id) => ({
                url: '/datx/informationPackage/' + id,
                method: 'GET'
            }),
            transformResponse: (response: InformationPackageModel) => response,
            transformErrorResponse: (response, meta, arg) => `An error occurred while retrieving information package ${arg}`,
            providesTags: [GET_INFORMATION_PACKAGES]
        }),
        updateInformationPackages: builder.mutation<InformationPackageModel[], InformationPackageModel[]>({
            query: (informationPackages) => ({
                url: '/datx/informationPackage',
                method: 'POST',
                data: informationPackages
            }),
            transformResponse: (response: InformationPackageModel[]) => response,
            transformErrorResponse: (response: any) => response?.data?.errorMessage || 'Something went wrong when updating the information packages',
            invalidatesTags: [GET_INFORMATION_PACKAGES]
        }),
    })
})

export const {useGetAllInformationPackagesQuery, useUpdateInformationPackagesMutation, useGetInformationPackageQuery} = informationPackagesApi