import {configureStore} from '@reduxjs/toolkit'

import {TypedUseSelectorHook, useDispatch, useSelector} from 'react-redux'
import commonSlice from './commonSlice'
import {searchApi} from '../Search/rtkSearchApi'
import {guildApi} from '../Guilds/rtkGuildApi'
import {serviceRequestApi} from '../ServiceRequests/rtkServiceRequestApi'
import {displaySetsApi} from '../displaySets/rtkDisplaySetsApi'
import {attributeDefinitionApi} from '../definitions/rtkAttributeDefinitionsApi'
import {informationPackagesApi} from '../informationPackages/rtkInformationPackagesApi'
import {technicalNodesPreferredDefinitionsApi} from '../preferredDefinitions/rtkPreferredDefinitionApi'
import {classificationNodesApi} from '../preferredDefinitions/rtkClassificationNodes'
import {factoryApi} from '../factoryUser/rtkFactoryApi'

const store = configureStore({
    reducer: {
        common: commonSlice,
        [searchApi.reducerPath]: searchApi.reducer,
        [guildApi.reducerPath]: guildApi.reducer,
        [serviceRequestApi.reducerPath]: serviceRequestApi.reducer,
        [displaySetsApi.reducerPath]: displaySetsApi.reducer,
        [informationPackagesApi.reducerPath]: informationPackagesApi.reducer,
        [technicalNodesPreferredDefinitionsApi.reducerPath]: technicalNodesPreferredDefinitionsApi.reducer,
        [classificationNodesApi.reducerPath]: classificationNodesApi.reducer,
        [attributeDefinitionApi.reducerPath]: attributeDefinitionApi.reducer,
        [factoryApi.reducerPath]: factoryApi.reducer
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware()
        .concat(searchApi.middleware)
        .concat(guildApi.middleware)
        .concat(serviceRequestApi.middleware)
        .concat(displaySetsApi.middleware)
        .concat(informationPackagesApi.middleware)
        .concat(technicalNodesPreferredDefinitionsApi.middleware)
        .concat(classificationNodesApi.middleware)
        .concat(attributeDefinitionApi.middleware)
        .concat(factoryApi.middleware)
})

export type RootStateType = ReturnType<typeof store.getState>
export type AppDispatchType = typeof store.dispatch

export const useTypedDispatch = () => useDispatch<AppDispatchType>()
export const useTypedSelector: TypedUseSelectorHook<RootStateType> = useSelector

export default store
