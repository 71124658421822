import React, {ReactElement, useMemo, useState} from 'react'
import {DraggingPosition, StaticTreeDataProvider, Tree, UncontrolledTreeEnvironment} from 'react-complex-tree'
import 'react-complex-tree/lib/style-modern.css'
import {useGetAllDisplaySetsQuery, useUpdateDisplaySetsMutation} from '../rtkDisplaySetsApi'
import LoadingSpinner from '../../genericComponents/spinner/LoadingSpinner'
import {buildDisplaySetFromNode, DisplaySetModel, DisplaySetTree, DisplaySetTreeItem} from '../model/DisplaySetModel'
import {useGetAllDefinitionsQuery} from '../../definitions/rtkAttributeDefinitionsApi'
import {DefinitionModel} from '../../definitions/model/DefinitionModel'
import '../../genericComponents/tree/customTree.scss'
import './displaySetsStructureUpdate.scss'
import {SubmitButton} from '../../genericComponents/button/submitButton'
import {DisplaySetForTree} from './displaySetForTree'
import {buildNameAndIdForTreeModel} from '../../genericComponents/tree/CustomTreeModel'
import {TreeInformation} from 'react-complex-tree/src/types'
import {DefinitionForDisplaySetTree} from './definitionForDisplaySetTree'
import {Link} from 'react-router-dom'
import {ROUTES} from '../../util/constants/routing'
import {Button} from '@mui/material'


export const displaySetRootName = 'displaySetRoot'
export const displaySetTreeName = 'displaySetRoot'
const displaySetRootNode: DisplaySetTreeItem = {
    index: displaySetRootName,
    children: [],
    isFolder: true,
    data: buildNameAndIdForTreeModel(displaySetRootName)
}

export const definitionRootName = 'definitionRoot'
export const definitionTreeName = 'lonely-definitions-tree'
const definitionRootNode: DisplaySetTreeItem = {
    index: definitionRootName,
    children: [],
    isFolder: true,
    data: buildNameAndIdForTreeModel(definitionRootName)
}


// ========== Display Set Nodes

const populateChildrenDisplaySets = (displaySets: DisplaySetModel[], displaySetsAsTreeItems: DisplaySetTree) => {
    displaySets.forEach((displaySet) => {
        const parentTreeItem = displaySetsAsTreeItems.get(displaySet.parentId ?? displaySetRootName)
        if (!parentTreeItem) return

        if (!parentTreeItem.children)
            parentTreeItem.children = []

        if (!parentTreeItem.children.includes(displaySet?.id ?? ''))
            parentTreeItem.children.push(displaySet?.id ?? '')
    })
}

const sortDisplaySetChildrenOfNode = (nodeId: string, displaySetsAsTreeItems: DisplaySetTree) => {
    const node = displaySetsAsTreeItems.get(nodeId.toString())
    if (!node) return

    const children = node.children?.map(id => displaySetsAsTreeItems.get(id.toString())) ?? []
    node.children = children.sort((childNode1, childNode2) => {
        return (childNode1?.data?.displaySet?.displayOrder ?? 0) - (childNode2?.data?.displaySet?.displayOrder ?? 0)
    })
        .map(childNode => childNode?.index?.toString() ?? '')
        .filter(child => child) // remove all empty string
}

const sortDisplaySetChildrenInTree = (displaySetsAsTreeItems: DisplaySetTree) => {
    sortDisplaySetChildrenOfNode(displaySetRootName, displaySetsAsTreeItems)
    const childrenOfRootDisplaySet = displaySetsAsTreeItems.get(displaySetRootName)?.children ?? []

    // do the sorting only on the level one
    childrenOfRootDisplaySet.forEach(nodeId => sortDisplaySetChildrenOfNode(nodeId.toString(), displaySetsAsTreeItems))
}

const getDisplaySetNodes = (displaySets: DisplaySetModel[], allDefinitions: DefinitionModel[]): DisplaySetTree => {
    const allDefinitionIds = allDefinitions.map(def => def.id)

    const displaySetsAsTreeItems: DisplaySetTree = new Map(displaySets.map((displaySet) => {
        const definitionIds = [...displaySet.attributeDefinitions] // because cannot update directly the object coming from the back
            .filter(position => allDefinitionIds.includes(position.attributeDefinitionId))
            .sort((position1, position2) => position1.displayOrder - position2.displayOrder)
            .map((position) => position.attributeDefinitionId)

        return [displaySet.id ?? '', {
            index: displaySet.id ?? '',
            children: definitionIds,
            isFolder: true,
            data: {
                id: displaySet.id,
                label: displaySet.label,
                displaySet: displaySet
            },
            canMove: true,
            canRename: false
        }]
    }))

    // add the root node
    displaySetsAsTreeItems.set(displaySetRootName, displaySetRootNode)

    populateChildrenDisplaySets(displaySets, displaySetsAsTreeItems)
    sortDisplaySetChildrenInTree(displaySetsAsTreeItems)

    return displaySetsAsTreeItems
}


// ========== Definition Nodes

const getLonelyDefinitionNode = (displaySets: DisplaySetModel[], definitions: DefinitionModel[]): string[] => {
    const definitionsOfDisplaySets = displaySets.flatMap((displaySet) => displaySet.attributeDefinitions.map((def) => def.attributeDefinitionId))

    return definitions.filter((def) => !definitionsOfDisplaySets.includes(def.id))
        .sort((def1, def2) => {
            if (def1.name < def2.name) return -1
            if (def1.name > def2.name) return 1
            return 0
        })
        .map((def) => def.id)

}

const getDefinitionNodes = (displaySets: DisplaySetModel[], definitions: DefinitionModel[]): DisplaySetTree => {
    const definitionsAsTreeItems: DisplaySetTree = new Map(definitions.map((definition) => {
        return [definition.id, {
            index: definition.id,
            isFolder: false,
            data: {
                id: definition.id,
                label: definition.name,
                definition: definition
            },
            canMove: true,
            canRename: false
        }]
    }))

    const definitionsNotInDisplaySets = getLonelyDefinitionNode(displaySets, definitions)
    definitionsAsTreeItems.set(definitionRootName, {...definitionRootNode, children: definitionsNotInDisplaySets})

    return definitionsAsTreeItems
}

const createDisplaySetTree = (displaySets: DisplaySetModel[] | undefined, definitions: DefinitionModel[] | undefined): DisplaySetTree => {
    if (!displaySets) return new Map<string, DisplaySetTreeItem>()
    if (!definitions) return new Map<string, DisplaySetTreeItem>()

    const displaySetsAsTreeItems = getDisplaySetNodes(displaySets, definitions)
    const definitionsAsTreeItems = getDefinitionNodes(displaySets, definitions)

    return new Map<string, DisplaySetTreeItem>([...displaySetsAsTreeItems, ...definitionsAsTreeItems])
}


export const DisplaySetsStructureUpdate = () => {
    const {data: displaySets, isFetching: isFetchingDisplaySets} = useGetAllDisplaySetsQuery()
    const {data: definitions, isFetching: isFetchingDefinitions} = useGetAllDefinitionsQuery()
    const [updateDisplaySets, updateDisplaySetsResult] = useUpdateDisplaySetsMutation()
    const [nbLonelyDefinitions, setNbLonelyDefinitions] = useState(0)

    const displaySetTree: DisplaySetTree = useMemo(() => createDisplaySetTree(displaySets, definitions),
        [displaySets, definitions])

    const listener = (changedItemIds: (string | number)[]) => {
        setNbLonelyDefinitions(displaySetTree.get(definitionRootName)?.children?.length ?? 0)
    }

    const dataProvider = useMemo(
        () => {
            setNbLonelyDefinitions(displaySetTree.get(definitionRootName)?.children?.length ?? 0)

            const provider = new StaticTreeDataProvider(Object.fromEntries(displaySetTree.entries()))
            provider.onDidChangeTreeData(listener)

            return provider
        },
        [displaySetTree]
    )

    const customNodeDisplay = (node: DisplaySetTreeItem, info: TreeInformation): ReactElement | string => {
        if (!node?.data) return ''
        if (node.data?.definition) return <DefinitionForDisplaySetTree node={node}
                                                                       definition={node.data.definition}></DefinitionForDisplaySetTree>
        if (node?.data?.displaySet) return <DisplaySetForTree node={node}
                                                              displaySet={node.data.displaySet}></DisplaySetForTree>
        return node.data.label
    }

    // check if we can drop a definition
    // we can drop a definition in the definition tree (target.treeId === definitionTreeName)
    // or inside a folder at the last level (level 1 of the tree)
    // or in between other definitions (so at the level 2 of the tree)
    // the problem comes from the multi select. you can multi select folders and / or definitions
    // if you multi select objects of different type we disable the drop
    const canDropDefinition = (nodes: DisplaySetTreeItem[], target: DraggingPosition) => {
        if (!(nodes?.length)) return false

        const allAreFolders = nodes.every((node) => node.isFolder)
        if (allAreFolders) return true // if it's a folder, it's a display set, so no need to check

        const allAreLeafs = nodes.every((node) => !node.isFolder)
        if (!allAreLeafs) return false // we have some folders and some definitions, so cannot

        if (target.treeId === definitionTreeName) // if definition is drop in the lonely def tree, then can
            return true

        // can drop in the displaySet tree only inside a displaySet of level 2
        return (target.targetType === 'item' && target.depth >= 1) // in a child display set
            || (target.targetType === 'between-items' && target.depth >= 2)
    }

    // check if we can drop a folder
    const canDropDisplaySet = (nodes: DisplaySetTreeItem[], target: DraggingPosition) => {
        if (!(nodes?.length)) return false

        const allAreLeafs = nodes.every((node) => !node.isFolder)
        if (allAreLeafs) return true // if it's not a folder, it's a definition, so no need to check

        const allAreFolders = nodes.every((node) => node.isFolder)
        if (!allAreFolders) return false // we have some definitions and folders

        if (target.treeId === definitionTreeName) // if displaySet is drop in the lonely def tree, should not
            return false

        return target.targetType === 'root'
            || (target.targetType === 'item' && target.depth === 0) // in a top display set
            || (target.targetType === 'between-items' && target.depth < 2)
    }

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()

        const allNodes = Array.from(displaySetTree.values())
        const displaySetNodes = allNodes.filter((node) => node?.data?.displaySet)
        const parentByChildIds = new Map(allNodes.flatMap((node) => node.children?.map((child) => [child, node]) ?? []))

        const newDisplaySets: DisplaySetModel[] = displaySetNodes.map((node) => buildDisplaySetFromNode(node, parentByChildIds))
            .filter((displaySet) => displaySet != null) // remove null displaySets
        updateDisplaySets(newDisplaySets ?? [])
    }

    return <div className={'display-sets-form'}>
        <h1 className="page-title">Display Sets structure</h1>
        {isFetchingDisplaySets || isFetchingDefinitions || updateDisplaySetsResult.isLoading ? <LoadingSpinner/> :
            <form className="display-set-edit" onSubmit={handleSubmit}>
                <UncontrolledTreeEnvironment
                    dataProvider={dataProvider}
                    getItemTitle={node => node?.data?.label ?? ''}
                    viewState={{}}
                    canDragAndDrop={true}
                    canDropOnFolder={true}
                    canDropOnNonFolder={false}
                    canReorderItems={true}
                    renderItemTitle={({item, info}) => customNodeDisplay(item, info)}
                    canDropAt={(node, target) => canDropDefinition(node, target) && canDropDisplaySet(node, target)}
                >
                    <div className="lonely-definitions-tree tree-container">
                        <h3 className="tree-title">Lonely Definitions
                            ({nbLonelyDefinitions} {nbLonelyDefinitions > 0 ? '😔' : '😄'})</h3>
                        <div className="tree">
                            <Tree treeId={definitionTreeName} rootItem={definitionRootName}
                                  treeLabel="Definitions out of display sets"/>
                        </div>
                    </div>

                    <div className="displaysets-with-definitions-tree tree-container">
                        <h3 className="tree-title">
                            <span>Display Sets ({displaySets?.length})</span>
                            <span className="action-buttons">
                                <Button className="display-set-new" component={Link} variant="outlined"
                                        to={ROUTES.displaySetNew.path ?? ''}
                                >  New  </Button>
                                <SubmitButton loading={updateDisplaySetsResult.isLoading}>Save</SubmitButton>
                            </span>
                        </h3>
                        <div className="tree">
                            <Tree treeId={displaySetTreeName} rootItem={displaySetRootName}
                                  treeLabel="DisplaySets with definitions"/>
                        </div>
                    </div>


                </UncontrolledTreeEnvironment>
            </form>
        }
    </div>
}