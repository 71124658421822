
import './GuildRequestsTable.scss'
import {formatBudget, Guild} from '../models/guild'
import GuildStatus from '../models/guildStatus'
import {NumericFormat} from 'react-number-format'
import {number, object, string} from 'yup'
import {useFormik} from 'formik'
import {FormControl, InputAdornment, TextField} from '@mui/material'
import {LoadingButton} from '@mui/lab'
import {CREDITS} from '../../constants/credits'
import { useUpdateGuildMutation } from '../rtkGuildApi'


const AcceptGuildCreationForm = ({currentGuild}: { currentGuild: Guild }) => {
    const [updateGuild, updateGuildResult] = useUpdateGuildMutation()

    const validationSchema = object({
        budget: number().required().positive().integer(),
        contractId: string().required(),
        contractOwner: string().required()
    })


    const handleSubmit = () => {
        const guild = {
            ...currentGuild,
            contractId: formik.values.contractId,
            contractOwner: formik.values.contractOwner,
            budget: formatBudget(formik.values.budget),
            status: GuildStatus.APPROVED
        }

        updateGuild(guild)
    }

    const formik = useFormik({
        initialValues: {
            budget: currentGuild?.budget ?? '',
            contractId: currentGuild?.contractId ?? '',
            contractOwner: currentGuild?.contractOwner
        },
        validationSchema: validationSchema,
        onSubmit: handleSubmit
    })

    return <form
        id={'form-' + currentGuild.id}
        className="request-action-form"
        onSubmit={formik.handleSubmit}
    >
        <FormControl>
            <label htmlFor={'contract-id-' + currentGuild.id}>Contract ID <span className="asterisk">*</span></label>
            <TextField id={'contract-id-' + currentGuild.id}
                       variant="outlined"
                       name="contractId"
                       value={formik.values.contractId ?? ''}
                       onChange={formik.handleChange}
                       type="text"
                       required
                       aria-invalid={formik.touched.contractId && Boolean(formik.errors.contractId)}
            />
        </FormControl>

        <FormControl>
            <label htmlFor={'contract-owner-' + currentGuild.id}>Contract Owner <span className="asterisk">*</span></label>
            <TextField id={'contract-owner-' + currentGuild.id}
                       variant="outlined"
                       name="contractOwner"
                       required
                       value={formik.values.contractOwner ?? ''}
                       onChange={formik.handleChange}
                       type="text"
                       aria-invalid={formik.touched.contractOwner && Boolean(formik.errors.contractOwner)}
            />
        </FormControl>
        <FormControl>
            <label htmlFor={'budget-' + currentGuild.id}>Guild Budget <span className="asterisk">*</span></label>
            <NumericFormat
                id={'budget-' + currentGuild.id}
                name="budget"
                customInput={TextField}
                thousandSeparator={' '}
                decimalSeparator="."
                required
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="start">{CREDITS}</InputAdornment>
                    ),
                }}
                value={formik.values.budget ?? null}
                onChange={formik.handleChange}
                aria-invalid={formik.touched.budget && Boolean(formik.errors.budget)}
            />
        </FormControl>


        <LoadingButton
            disabled={!formik.dirty || !formik.isValid}
            type="submit"
            color="primary"
            className="confirmButton"
            variant="contained"
            loading={updateGuildResult.isLoading}
        >
            Accept
        </LoadingButton>
    </form>

}

export default AcceptGuildCreationForm
