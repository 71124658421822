import React from 'react'
import {Button, useTheme} from '@mui/material'
import {ArrowDropDown} from '@mui/icons-material'


const CustomHeaderButton: React.FC<any> = ({activeNavLink, noIcon, ...other}) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const {iconButton, palette} = useTheme()


    return <Button
        size="small"
        className={(activeNavLink ? 'active-nav-link ' : '') + 'header-button' }
        endIcon={
            <ArrowDropDown sx={{
                ...iconButton.medium.icon,
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                fill: `${palette?.white} !important`,
                width: '24px',
                height: '24px',
            }}/>
        }
        {...other}
    />
}

export default CustomHeaderButton
