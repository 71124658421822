import {Dialog as MuiDialog, IconButton,} from '@mui/material'
import './ServiceRequestDialog.scss'
import {Close} from '@mui/icons-material'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import ServiceRequestContent from '../../ServiceRequests/ServiceRequestsTable/ServiceRequestsContent'
import {GridCellParams} from '@mui/x-data-grid'

function ServiceRequestDialog({
                          className,
                          title,
                          open,
                          onClose,
                          selectedServiceRequest,
                      }: {
    className: string,
    title: string,
    open: boolean,
    onClose: () => void,
    selectedServiceRequest?: GridCellParams,
}) {
    return (
        <MuiDialog
            open={open}
            onClose={onClose}
            classes={{
                root: 'service-request-dialog-root',
                paper: 'service-request-dialog-container',
            }}
            className={className}
            onClick = {(event)=>
                event.stopPropagation()
            }
        >
            <div className="service-request-dialog">
                <div className="dialog-header">
                    <div className="dialog-title">{title}</div>
                    <IconButton className="dialog-close" onClick={onClose}>
                        <Close/>
                    </IconButton>
                </div>
                <div className="id-container">
                    <span className="id-value" title="ID">ID: </span>
                    <span className="id-value">{selectedServiceRequest?.row.id}</span>
                    <IconButton onClick={() => {
                        navigator.clipboard.writeText(selectedServiceRequest?.row.id)}}>
                        <ContentCopyIcon className="pasteIcon"/>
                    </IconButton >
                </div>
                <div className="dialog-body">
                    {selectedServiceRequest && (
                        <ServiceRequestContent selectedServiceRequest={selectedServiceRequest}/>
                    )}
                </div>
            </div>
        </MuiDialog>
    )
}

export default ServiceRequestDialog
