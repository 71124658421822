import {TreeItem} from 'react-complex-tree'
import {CustomTreeItemForInformationPackageTree, InformationPackageModel} from '../model/InformationPackageModel'
import {ROUTES} from '../../util/constants/routing'
import {EditButtonForTree} from '../../genericComponents/tree/EditButtonForTree'

export const InformationPackageForTree = ({node, informationPackage}: {
    node: TreeItem<CustomTreeItemForInformationPackageTree>,
    informationPackage: InformationPackageModel
}) => {
    return <div className={'information-package-for-tree'}>
        {informationPackage.label} - {informationPackage.billingType} ({node.children?.length})
        <EditButtonForTree path={ROUTES.informationPackage.path + '/' + informationPackage.id}></EditButtonForTree>
    </div>
}
