import React, {useState} from 'react'
import {Guild} from '../models/guild'
import GuildStatus from '../models/guildStatus'
import CustomModal from '../../genericComponents/customModal/CustomModal'
import {LoadingButton} from '@mui/lab'
import {Button} from '@mui/material'
import {useUpdateGuildStatusMutation} from '../rtkGuildApi'


export const DenyGuildRequest = ({
                                     currentGuild,
                                     afterClose,
                                 }: {
    currentGuild: Guild,
    afterClose: () => void,
}) => {
    const [showCancelDialog, setShowCancelDialog] = useState(true)
    const [updateGuildStatus, updateGuildResult] = useUpdateGuildStatusMutation()

    const close = () => {
        setShowCancelDialog(false)
        afterClose()
    }

    const denyGuildCreation = () => {
        const guild = {
            ...currentGuild,
            status: GuildStatus.DENIED
        }

        updateGuildStatus(guild).then(() => {
            setShowCancelDialog(false)
        })
    }

    return <>{showCancelDialog &&
        <CustomModal handleClose={close}
                     title={'Do you want to deny this guild creation request'}
                     body={'This action cannot be reversed'}
                     actions={<>
                         <Button type="button" className="cancel" color="primary"
                                 onClick={close}
                         >
                             Back
                         </Button>
                         <LoadingButton variant="contained" color="primary"
                                        onClick={denyGuildCreation}
                                        loading={updateGuildResult.isLoading}
                         >
                             Deny
                         </LoadingButton>
                     </>}
        >
        </CustomModal>
    }
    </>
}