import {matchRoutes, RouteMatch, RouteObject, useLocation} from 'react-router-dom'
import {RouteCustomType, ROUTES} from './constants/routing'


type CustomRouteMatch =  RouteMatch & {route: RouteObject & RouteCustomType}

// used to get the object value of the corresponding route in ROUTES
// it is to correctly obtain the right options attached to the route
export const useMatchedRoute = ():CustomRouteMatch[] | null => {
    const location = useLocation()
    return matchRoutes(Object.values(ROUTES), location)
}
