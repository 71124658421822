import {News} from '../../model/News'
import {useSortable} from '@dnd-kit/sortable'
import {CSS} from '@dnd-kit/utilities'
import {Button, TableCell, TableRow} from '@mui/material'
import DragIndicatorIcon from '@mui/icons-material/DragIndicator'
import GetStatus, {getFormattedDate} from './NewsTableStatus'
import NewsTableActions from '../NewsTableActions'
import React from 'react'
import {MoreHoriz} from '@mui/icons-material'

const NewsTableRow = ({
    news,
}: {
    news: News
}) => {
    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
        isDragging
    } = useSortable({id: news.id ?? ''})

    const style = {
        transform: CSS.Transform.toString(transform),
        transition: transition,
        backgroundColor : isDragging ? 'var(--primaryBlueColor)' : undefined,
    }

    return(
        <TableRow
            ref = {news.enabled? setNodeRef : null}
            style={{...style}}
            className="news-row"
            hover={true}
            data-testid={news.title}
        >
            <TableCell className="drag-cell" >
                <Button
                    {...attributes} {...listeners}
                    className = "drag-cell-button"
                    disabled={!news.enabled}
                >
                    <DragIndicatorIcon/>
                </Button>
            </TableCell>
            <TableCell>
                {news.title}
            </TableCell>
            <TableCell></TableCell>
            <TableCell>
                {isDragging ? <div>{news.enabled ? 'Published': 'Draft'}</div> :
                <GetStatus isPublished={news.enabled}/>
                }
            </TableCell>
            <TableCell>
                {getFormattedDate(news.createdDate ?? [])}
            </TableCell>
            <TableCell>
                {getFormattedDate(news.updatedDate ?? [])}
            </TableCell>
            <TableCell>
                <NewsTableActions currentNews={news}/>
            </TableCell>
        </TableRow>
    )
}

const DraggableNewsRow = ({
    news
}: {
    news: News
}) => {
    return(
        <TableRow className="news-dragging-row">
            <TableCell
                className="drag-cell"
            >
                <Button
                    className = "static-drag-cell-button"
                >
                    <DragIndicatorIcon/>
                </Button>
            </TableCell>
            <TableCell>{news.title}</TableCell>
            <TableCell></TableCell>
            <TableCell><GetStatus isPublished={news.enabled}/></TableCell>
            <TableCell>{getFormattedDate(news.createdDate ?? [])}</TableCell>
            <TableCell>{getFormattedDate(news.updatedDate ?? [])}</TableCell>
            <TableCell><MoreHoriz/></TableCell>
        </TableRow>
    )
}

export {NewsTableRow, DraggableNewsRow}