import {RouteObject} from 'react-router-dom'

export interface RouteTypeDic {
    [x: string]: RouteObject & RouteCustomType
}

export interface RouteCustomType {
    options?: { pageTitle?: string,hideSearch?:boolean  }
}

// If useRoutes will be used later to setup routes,
// this object can be modified into array for that purpose.
export const ROUTES: RouteTypeDic = {
    login: {path: '/login'},
    root: {path: '/'},
    guildRequest: {path: '/guilds/requests', options: {pageTitle: 'Pending Guild Requests'}},
    activeGuilds: {path: '/guilds/active', options: {pageTitle: 'Active Guilds'}},
    editGuild: {path: '/guilds/editGuild/', options: {pageTitle: 'Edit Guilds'}},

    serviceRequest: {path: '/services/request', options: {pageTitle: 'Service Request'}},
    serviceRequestQuotation: {path: '/services/request/quotation/', options: {pageTitle: 'Service Request Quotation'}},

    displaySet: {path: '/portal-structure/display-sets', options: {pageTitle: 'Display Sets'}},
    displaySetNew: {path: '/portal-structure/display-sets/new', options: {pageTitle: 'Create Display Set'}},
    displaySetEdit: {path: '/portal-structure/display-sets/:displaySetId', options: {pageTitle: 'Edit Display Set'}},
    informationPackage: {path: '/portal-structure/information-packages', options: {pageTitle: 'Information Packages'}},
    informationPackageNew: {path: '/portal-structure/information-packages/new', options: {pageTitle: 'Create Information Package'}},
    informationPackageEdit: {path: '/portal-structure/information-packages/:informationPackageId', options: {pageTitle: 'Edit Information Package'}},
    preferredDefinitions: {path: '/portal-structure/preferred-definitions', options: {pageTitle: 'Preferred Definitions'}},
    banner: {path: '/portal-management/banners', options: {pageTitle: 'Banners'}},
    editBanner: {path: '/portal-management/banners/editBanner/:bannerId', options: {pageTitle: 'Edit Banner'}},
    createBanner: {path: '/portal-management/banners/createBanner/', options: {pageTitle: 'Create Banner'}},
    news: {path: '/portal-management/news', options: {pageTitle: 'News'}},
    editNews: {path: '/portal-management/news/editNews/:newsId', options: {pageTitle: 'Edit News'}},
    createNews: {path: '/portal-management/news/createNews', options: {pageTitle: 'Create News'}},

    factoryUsers: {path: 'factory-users/active'},
    createFactoryUser: {path: 'factory-users/create'}
}