import UserRequestStatusEnum from './UserRequestStatusEnum'

export interface UserServiceRequestStatus {
    color: string;
    label: string;
    actionEnable: boolean
}


const getUserServiceStatus = (status: string): UserServiceRequestStatus => {
    const userServiceStatusMap = new Map<string, UserServiceRequestStatus>([])
    userServiceStatusMap.set(UserRequestStatusEnum.AWAITING_APPROVAL, {
        color: '#FFC140',
        label: 'Awaiting Approval',
        actionEnable: false
    })
    userServiceStatusMap.set(UserRequestStatusEnum.AWAITING_QUOTATION, {
        color: '#FFC140',
        label: 'Awaiting Quotation',
        actionEnable: true
    })
    userServiceStatusMap.set(UserRequestStatusEnum.REQUEST_REJECTED, {
        color: '#E63A56',
        label: 'Request Rejected',
        actionEnable: false
    })
    userServiceStatusMap.set(UserRequestStatusEnum.AWAITING_QUOTATION_APPROVAL, {
        color: '#FFC140',
        label: 'Awaiting Quotation Approval',
        actionEnable: false
    })
    userServiceStatusMap.set(UserRequestStatusEnum.QUOTATION_APPROVED, {
        color: '#2DB453',
        label: 'Quotation Approved',
        actionEnable: true
    })
    userServiceStatusMap.set(UserRequestStatusEnum.QUOTATION_REJECTED, {
        color: '#E63A56',
        label: 'Quotation Rejected',
        actionEnable: false
    })
    userServiceStatusMap.set(UserRequestStatusEnum.DELIVERED, {
        color: '#2DB453',
        label: 'Delivered',
        actionEnable: false
    })

    return userServiceStatusMap.get(status) ?? {color: 'black', label: 'N/A', actionEnable: false}
}

export default getUserServiceStatus
