import {createApi} from '@reduxjs/toolkit/dist/query/react'
import { OrganizationIdentificationSummary } from '../Guilds/models/OrganizationIdentificationSummary'
import {axiosBaseQuery} from '../rtkQueryUtils'
import {SEARCH_API_ENDPOINT} from '../util/constants/backendUrls'
import { TCIS_BCN_CODIFICATION_SYSTEM_ID } from '../util/constants/ids'

export const searchApi = createApi({
    reducerPath: 'searchApi',
    keepUnusedDataFor: 0,
    baseQuery: axiosBaseQuery({baseUrl: `${SEARCH_API_ENDPOINT}/datx/`}),
    endpoints: (builder) => ({
        getTCISOrganization: builder.query<OrganizationIdentificationSummary[], void>({
            query: () => ({
                url: `organizations/codification-system/${TCIS_BCN_CODIFICATION_SYSTEM_ID}`,
                method: 'GET'
            }),
            transformResponse : (response:OrganizationIdentificationSummary[]) => response.sort((a, b) => a?.label.localeCompare(b?.label)),
        })
    })
})

export const {useGetTCISOrganizationQuery} = searchApi