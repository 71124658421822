import React from 'react'
import {ItemPriceRule} from '../QuotationRule'
import {ServiceRequest} from '../../ServiceRequest'
import {FormControl, TextField} from '@mui/material'
import {InputAttributes} from 'react-number-format'
import {CopyButton} from '../../../../genericComponents/button/CopyButton'
import {setSnackbarMessage} from '../../../../util/commonSlice'
import {useTypedDispatch} from '../../../../util/store'

export const UpdateItemPriceRuleComponent = ({rule, serviceRequest, index, handleChange}: {
    rule: ItemPriceRule,
    serviceRequest: ServiceRequest,
    index: number,
    handleChange: InputAttributes['onChange']
}) => {
    const dispatch = useTypedDispatch()
    const subTotal = rule.calculate(0)

    return <div className="quotation-detail-rule-structure">
        <div className="quotation-detail-description quotation-detail-item-name">
            <p className="quotation-detail-item-name-text">{rule?.itemName}</p>
            <CopyButton toCopy={rule?.itemName}
                        onCopySuccess={() => dispatch(setSnackbarMessage('Item name was successfully copied to your clipboard'))} />
        </div>
        <FormControl className="quotation-detail-unit-price">
            <TextField
                inputProps={{style: {textAlign: 'right'}}}
                name={`genericRules.${index}.price`}
                type="number"
                value={rule.price}
                sx={{width: 1}}
                size="small"
                required
                placeholder="eg. 10"
                error={rule.price < 0}
                helperText={rule.price < 0 ? 'Price should be >= 0' : ''}
                onChange={handleChange} />
        </FormControl>
        <p className="quotation-detail-total">{subTotal}</p>
    </div>
}