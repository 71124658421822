import {AxiosPromise} from 'axios'
import {Dispatch} from 'redux'
import {setSnackbarMessage} from '../commonSlice'

export type GenericAnswer<T> = {
    data: T,
    message: string
}

export const errorWrapper = <T, >(promise: AxiosPromise<GenericAnswer<T>>, dispatch: Dispatch): AxiosPromise<GenericAnswer<T>> => {
    return promise
        .catch((error) => {
            dispatch(setSnackbarMessage(error?.response?.data?.errorMessage || 'Something went wrong'))
            return error
        })
}