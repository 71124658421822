import {
    Divider,
    InputLabel,
    TextField,
    Typography,
    Link
} from '@mui/material'
import {News} from '../../model/News'
import {object, string} from 'yup'
import {useFormik} from 'formik'
import React, {useState} from 'react'
import {LoadingButton} from '@mui/lab'
import {ROUTES} from '../../../util/constants/routing'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore'
import './NewsForm.scss'

const NewsForm = (
    {
        formTitle,
        handlePublish,
        initialNews
    }:{
        formTitle: string
        handlePublish : (news: News) => void
        initialNews : News
    }
) => {
    const [loading, setLoading] = useState(false)

    const isEditPage = (formTitle === 'Edit News & Announcements')
    const htmlRegexExp = /(<([^>]+)>)/ig // stripping html tags from wordcount with regex
    const validationSchema = object({
        body: string().required().test('len', 'The 5000 character limit is reached', (val) =>
            val.replace(htmlRegexExp, '').length <= 5000 && val.replace(htmlRegexExp, '').length !== 0
        ),
        title: string().required().max(100, 'The 100 character limit is reached')
    })

    const quillFormats = ['size', 'bold', 'italic', 'link', 'underline', 'list'] //what is allowed
    const quillModules = { //what is shown
        toolbar: [
            [{ size: ['small', false, 'large'] }],
            ['bold', 'italic', 'underline', 'link'],
            [{ 'list': 'ordered'}, { 'list': 'bullet' }],
            ]
    }

    const handleSubmit = () => {
        handlePublish({...initialNews,
            title :formik.values.title,
            body :formik.values.body,
            enabled : true
        })
        setLoading(true)
    }

    const handleDraft = () => {
        handlePublish({...initialNews,
            title :formik.values.title,
            body :formik.values.body
        })
        setLoading(true)
    }

    const formik = useFormik({
            initialValues: {
                title : initialNews?.title ?? '',
                body : initialNews?.body ?? ''
            },
            validationSchema: validationSchema,
            onSubmit: handleSubmit
        }
    )

    const wordCount = formik.values.body.replace(htmlRegexExp, '').length

    return <>
        <Link
            href = {ROUTES.news.path ?? ''}
            className = "back-link"
        >
            <NavigateBeforeIcon/>
            <span className="back-link-text">Back</span>
        </Link>
        <form onSubmit={formik.handleSubmit}>
            <div className="news-actions-form">
                <div className="news-form-title-container">
                    <h1 className="news-form-title">
                        {formTitle}
                    </h1>
                    <Divider className="divider-title"/>
                </div>
                <div>
                    <InputLabel className="news-input-label">
                        News Title<span className="asterisk">*</span>
                    </InputLabel>
                    <TextField
                        id = "title"
                        value={formik.values.title}
                        onChange={formik.handleChange}
                        className="news-title-textfield"
                        size="small"
                        placeholder="Write your news title here"
                        inputProps={{maxLength: 100}}
                        required
                        aria-invalid= {formik.touched.title && Boolean(formik.errors.title)}
                    />
                </div>
                <div>
                    <div className="text-count-container">
                        <InputLabel className="news-input-label-count">
                            News Body<span className="asterisk">*</span>
                        </InputLabel>
                        <Typography className="text-count" variant="caption">
                            {wordCount}
                        </Typography>
                    </div>
                    <ReactQuill
                        formats={quillFormats}
                        modules={quillModules}
                        value = {formik.values.body}
                        id = "body"
                        onChange = {(e) => formik.setFieldValue('body', e)}
                        aria-invalid= {formik.touched.body && Boolean(formik.errors.body)}
                    />
                    <span className="field-helper-text">Max. 5000 Characters</span>
                </div>
                <Divider className="news-bottom-divider"/>
                <div>
                    {(initialNews.enabled && isEditPage)
                        ? null
                        : <LoadingButton
                            className = "publish-button"
                            variant="outlined"
                            disabled={!formik.dirty || !formik.isValid}
                            disableElevation
                            loading = {loading}
                            data-testid = "news-form-publish-button"
                            onClick={handleDraft}
                            >
                            <span>Save as Draft</span>
                        </LoadingButton>
                    }
                    <LoadingButton
                        className = "publish-button"
                        variant="contained"
                        type = "submit"
                        disabled={!formik.dirty || !formik.isValid}
                        disableElevation
                        loading = {loading}
                        data-testid = "news-form-publish-button"
                    >
                        {(initialNews.enabled && isEditPage)
                            ? <span>Save & Publish</span>
                            : <span>Publish</span>
                        }
                    </LoadingButton>
                </div>
            </div>
        </form>
    </>
}


export default NewsForm