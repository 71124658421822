import {LoadingButton} from '@mui/lab'
import React, {ReactNode} from 'react'
import './submit-button.scss'

export const SubmitButton = ({'data-cy': dataCy, disabled, className = '', handleSubmit, children, loading}:
                          { className?: string, 'data-cy'?: string, disabled?: boolean,
                              loading?: boolean,
                              handleSubmit?: () => any, children: ReactNode }) => {
    return <LoadingButton data-cy={dataCy}
                          color="primary"
                          variant="contained"
                          disabled={disabled}
                          type="submit"
                          loading={loading}
                          className={className + ' submit-button'}
                          onClick={handleSubmit}
    >
        {children}
    </LoadingButton>
}