import GuildStatus from './guildStatus'

export interface GuildCreationStatus {
    color: string;
    label: string;
}

const getGuildCreationStatus = (status: string): GuildCreationStatus => {
    const userServiceStatusMap = new Map<string, GuildCreationStatus>([])
    userServiceStatusMap.set(GuildStatus.WAITING_APPROVAL, {
        color: '#FFC140',
        label: 'Awaiting Approval'
    })
    userServiceStatusMap.set(GuildStatus.DENIED, {
        color: '#E63A56',
        label: 'Denied'
    })
    userServiceStatusMap.set(GuildStatus.APPROVED, {
        color: '#2DB453',
        label: 'Approved'
    })
   
    return userServiceStatusMap.get(status) ?? {color: 'black', label: 'N/A'}
}

export default getGuildCreationStatus
