import {TreeItem} from 'react-complex-tree'
import {CustomTreeItemForPreferredDefinitionsTree, TechnicalNodeModel} from '../model/TechnicalNodeModel'

export const ClassificationNodeForTree = ({node, technicalNode}: {
    node: TreeItem<CustomTreeItemForPreferredDefinitionsTree>,
    technicalNode: TechnicalNodeModel
}) => {
    return <div className={'technical-node-for-tree'}>
        {technicalNode.label} ({node.children?.length})
    </div>
}
