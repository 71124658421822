import {GridRenderCellParams, GridValueGetterParams} from '@mui/x-data-grid'
import React from 'react'
import './BannerTable.scss'
export const getStatus = (params: GridRenderCellParams) => {
    const status = params.value
    if (status) {
        return <div className="banner-status-publish">Published</div>
    }
    else {
        return <div className="banner-status-draft">Draft</div>
    }
}
export const getBannerTypeDisplay = (params: GridValueGetterParams) => {
    const type = params.value
    const bannerTypeMap = new Map<string, string>([])
    bannerTypeMap.set('INFO','Blue- Informational')
    bannerTypeMap.set('SUCCESS','Green- Success')
    bannerTypeMap.set('WARNING','Yellow- Warning')
    bannerTypeMap.set('ALERT','Red- Urgent')

    return bannerTypeMap.get(type) ?? 'Blue- Informational'
}


export default {getBannerTypeDisplay, getStatus}
