import {GuildUser} from './guildUser'
import {User} from '../../util/models/User'

export type Guild = {
  id: string;
  name: string;
  guildUsers: GuildUser[];
  status: string;
  budget: number;
  contractId: string;
  contractOwner: string;
  updatedDate: string;
  createdDate: string;
  defaultOrganization: string;
  maxUsersInGuild: number;
  numberOfUsers?: number;
  creationValidatedBy?: User;
  creationValidationDate?: number[];
  budgetUpdateComment?: string;
}

export const formatBudget = (budget: number | undefined): number => {
  if(!budget) return 0
  const formattedBudget = budget.toString().replaceAll(' ', '').replace(',', '.')
  return +formattedBudget
}