import React, {useState} from 'react'
import {File} from '../../models/File'
import './DownloadFile.scss'
import {CircularProgress, Link} from '@mui/material'

export const DownloadFile = ({
    file,
    downloadFile,
    icon = true
}: {
  file: File | undefined,
  downloadFile: () => Promise<string>,
  icon? : boolean
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const getFile = () => {
    setIsLoading(true)
    downloadFile().finally(() =>
        setIsLoading(false)
    )
  }

  if (!file) {
    return <></>
  }

  if (file.status !== 'Valid') {
    return (
      <div className="text-error file-name-format" title={file?.status}>
        {file.status}
      </div>
    )
  }

  return (
    <>
      <Link className="file-name-format" component="button" onClick={() => getFile()}>{file.name}</Link>
      {!isLoading || <CircularProgress size="16px"/> }
    </>
  )
}