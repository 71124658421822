import {ServiceRequest} from '../../models/ServiceRequest'

export const formatFileName = (serviceRequest: ServiceRequest): string => {
  const removeHyphenUnderscoreAndSpacesFrom = (originString: string) => {
    return originString
      ?.replaceAll('_', '')
      ?.replaceAll('-', '')
      ?.replaceAll(' ', '')
  }

  const guildName = removeHyphenUnderscoreAndSpacesFrom(
    serviceRequest.guildDetail?.name
  )

  const serviceFileName = removeHyphenUnderscoreAndSpacesFrom(
    serviceRequest.service ?? ''
  )

  if(!serviceRequest?.file?.name) return ''

  // This part is to retrieve the extension if present
  let extension = ''
  const splitFileName = serviceRequest.file.name.split('.')
  if (splitFileName.length > 1) {
    extension = '.' + splitFileName.pop()
  }
  // "Restore" dots if presents in the file name
  const fileNameWithoutExtension = removeHyphenUnderscoreAndSpacesFrom(
    splitFileName.join('.')
  )

  let requestEncodedId = serviceRequest?.requesterDetail?.email

  /*keep only the part before the @ then split on the dots and keep only the first letter of 2 first blocks.
        bob.morane@thalesdigital.io => bm
        bob.morane-laventurier@thalesdigital.io => bm
        bob.morane.laventurier@thalesdigital.io => bm
        bob@thalesdigital.io => bo*/

  requestEncodedId = requestEncodedId.split('@')[0]

  const splittedEmail = requestEncodedId.split('.')

  requestEncodedId =
    splittedEmail.length === 1
      ? requestEncodedId.substring(0, 2)
      : splittedEmail[0][0] + splittedEmail[1][0]

  requestEncodedId = requestEncodedId.concat(
    removeHyphenUnderscoreAndSpacesFrom(serviceRequest.id).substring(0, 8)
  )

  return (
    [
      fileNameWithoutExtension,
      guildName,
      serviceFileName,
      requestEncodedId,
    ].join('-') + extension
  )
}

export default formatFileName
