import {Banner} from './model/Banner'
import {guildApi} from '../Guilds/rtkGuildApi'
import {IdType} from '../util/models/IdType'

const BANNER_TAG = 'Banners'

export const bannerApi = guildApi.enhanceEndpoints({
    addTagTypes : [BANNER_TAG]
}).injectEndpoints({
    endpoints: (builder) => ({
        getAllBanners : builder.query<Banner[], void>({
            query : () => ({
                url : 'banners/all',
                method: 'GET'
            }),
            transformResponse: (response:any) => response.data,
            transformErrorResponse: () => 'Error getting all banners',
            providesTags : [BANNER_TAG]
        }),
        updateBanner : builder.mutation<any, Banner>({
            query: (banner) => ({
                url : `banners/${banner.id}`,
                method : 'PATCH',
                data : banner
            }),
            transformResponse: (response:any) => response.data,
            transformErrorResponse: () => 'Error updating banner',
            invalidatesTags : [BANNER_TAG]
        }),
        deleteBanner : builder.mutation<any, IdType>({
            query: (bannerId) => ({
                url : `banners/${bannerId}`,
                method : 'DELETE'
            }),
            transformResponse: (response:any) => response.data,
            transformErrorResponse: () => 'Error deleting banner',
            invalidatesTags : [BANNER_TAG]
        }),
        createBanner : builder.mutation<Banner, Banner>({
            query: (banner) => ({
                url : 'banners',
                method : 'POST',
                data : banner
            }),
            transformResponse: (response:any) => response.data,
            transformErrorResponse: () => 'Error creating banner',
            invalidatesTags : [BANNER_TAG]
        }),

    })
})

export const {
    useGetAllBannersQuery,
    useUpdateBannerMutation,
    useDeleteBannerMutation,
    useCreateBannerMutation
} = bannerApi