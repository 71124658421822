import {UserRoleEnum} from '../model/UserRoleEnum'
import {object, string} from 'yup'
import {useFormik} from 'formik'
import {MenuItem, Select, TextField} from '@mui/material'
import {KeyboardArrowDown} from '@mui/icons-material'
import './CreateFactoryUserForm.scss'
import React from 'react'
import {SubmitButton} from '../../../genericComponents/button/submitButton'
import {setSnackbarMessage} from '../../../util/commonSlice'
import {useCreateFactoryUserMutation} from '../../rtkFactoryApi'
import {useDispatch} from 'react-redux'

const CreateFactoryUserForm = () => {
    const [createFactoryUser, createFactoryUserResult] = useCreateFactoryUserMutation()

    const dispatch = useDispatch()

    const handleSubmit = () => {
        createFactoryUser({
            name: formik.values.name,
            email: formik.values.email,
            roles: [formik.values.role]
        }).then(() => {
            dispatch(setSnackbarMessage('New Factory User Created'))
            formik.resetForm()
        })
            .catch((error) => dispatch(setSnackbarMessage(error)))
    }

    const validationSchema = object({
        name: string().required('Name is required'),
        email: string().email('Invalid Email Address').required('Email is required'),
        role: string().required().oneOf(Object.values(UserRoleEnum))
    })


    const formik= useFormik({
        initialValues: {
            name: '',
            email: '',
            role: '',
        },
        enableReinitialize: true,
        validationSchema: validationSchema,
        onSubmit: handleSubmit
    })

    return (
        <>
            <form onSubmit={formik.handleSubmit}>
                <div className="form-datx-user-creation">
                    <label className="label">User Name</label>
                    <TextField
                        className="user-input"
                        id="name"
                        variant="outlined"
                        value={formik.values.name}
                        onChange={formik.handleChange}
                        required
                        placeholder="Enter user name"
                    />
                    <label className="label">User Email</label>
                    <TextField
                        className="user-input"
                        id="email"
                        variant="outlined"
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        required
                        placeholder="Enter user email"
                    />
                    <label className="label">User Type</label>
                    <Select
                        className="user-type-select"
                        size="small"
                        data-testid="user-type-select"
                        id="role"
                        name="Role"
                        value={formik.values.role}
                        IconComponent={KeyboardArrowDown}
                        displayEmpty
                        onChange={(event) => {
                            formik.setFieldValue('role', event.target.value)
                        }}
                    >
                        <MenuItem value="" disabled>Select a Role</MenuItem>

                        {Object.values(UserRoleEnum).sort().map((value) =>
                            <MenuItem key={value} value={value}>{value}</MenuItem>
                        )}
                    </Select>
                </div>
                <SubmitButton className="create-factory-user-form-submission-button" disabled={!formik.dirty || !formik.isValid}
                              loading={createFactoryUserResult.isLoading}>Create User</SubmitButton>
            </form>
        </>
    )
}

export default CreateFactoryUserForm