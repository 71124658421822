import React, {Children, ReactNode} from 'react'
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton} from '@mui/material'
import {Close} from '@mui/icons-material'
import './CustomModal.scss'
import InfoRoundedIcon from '@mui/icons-material/InfoRounded'
import UploadFile from '../../ServiceRequests/Files/UploadFile/UploadFile'
import {LoadingButton} from '@mui/lab'

const CustomModal = ({
                         handleClose,
                         children,
                         title, actions, body
                     }: {
    handleClose: () => void;
    title?: ReactNode,
    body?: ReactNode,
    actions?: ReactNode,
    children?: ReactNode;
}) => {


    return <Dialog
        classes={{paper: 'custom-modal-dialog'}}
        onClose={handleClose}
        open={true}
        data-testid="modal-component"
    >
        <DialogTitle className="modal-title">
            {title}
            <IconButton
                className="custom-modal-close-button"
                disableRipple={true}
                onClick={handleClose}
            >
                <Close/>
            </IconButton>
        </DialogTitle>

        {body && <DialogContent className="modal-body">
            {body}
        </DialogContent>}

        {actions && <DialogActions className="modal-button-container">
            {actions}
        </DialogActions>}

        {children}
    </Dialog>
}

export default CustomModal
