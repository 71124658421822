import {createApi} from '@reduxjs/toolkit/dist/query/react'
import {axiosBaseQuery} from '../rtkQueryUtils'
import {GUILD_API_ENDPOINT} from '../util/constants/backendUrls'
import {IdType} from '../util/models/IdType'
import {ServiceRequest} from './models/ServiceRequest'
import {Quotation} from './models/Quotation'

export const GET_SERVICE_REQUESTS = 'GetServiceRequest'

export const serviceRequestApi = createApi({
    reducerPath: 'serviceRequestApi',
    keepUnusedDataFor: 0,
    tagTypes: [GET_SERVICE_REQUESTS],
    baseQuery: axiosBaseQuery({baseUrl: `${GUILD_API_ENDPOINT}`}),
    endpoints: (builder) => ({
        getOpenServiceRequests: builder.query<ServiceRequest[], void>({
            query: () => ({
                url: '/datx/serviceRequests',
                method: 'GET'
            }),
            transformResponse: (response:any) => response.data,
            transformErrorResponse: (response, meta, arg) => `An error occured while retrieving service requests ${arg}`,
            providesTags: [GET_SERVICE_REQUESTS]
        }),
        getServiceRequest: builder.query<ServiceRequest, IdType>({
            query: (serviceRequestId) => ({
                url: `/datx/serviceRequests/${serviceRequestId}`,
                method: 'GET'
            }),
            transformResponse: (response:any) => response.data,
            transformErrorResponse: (response, meta, arg) => `An error occured while retrieving service request ${arg}`,
            providesTags: [GET_SERVICE_REQUESTS]
        }),
        updateQuotation: builder.mutation<any, { serviceRequestId: IdType, quotation: Quotation }>({
            query: ({serviceRequestId, quotation}) => ({
                url: `/datx/serviceRequests/${serviceRequestId}/quotation`,
                method: 'PATCH',
                data: quotation
            }),
            transformResponse: (response: any) => response.data,
            transformErrorResponse: (response: any) => response?.data?.errorMessage || 'Something went wrong when updating quotation rules',
            invalidatesTags: [GET_SERVICE_REQUESTS]
        }),
        awaitingQuotationApproval : builder.mutation<any, IdType>({
            query: (serviceRequestId) => ({
                url: `/datx/serviceRequests/serviceRequest/${serviceRequestId}/status/awaiting_quotation_approval`,
                method: 'PATCH'
            }),
            transformErrorResponse : (response:any) => response?.data?.errorMessage || 'Something went wrong when accepting quotation',
            invalidatesTags: [GET_SERVICE_REQUESTS]
        }),
        deliverServiceRequest : builder.mutation<any, IdType>({
            query: (serviceRequestId) => ({
                url: `/datx/serviceRequests/serviceRequest/${serviceRequestId}/status/delivered`,
                method: 'PATCH'
            }),
            transformErrorResponse : (response:any) => response?.data?.errorMessage || 'Something went wrong when delivering service request',
            invalidatesTags: [GET_SERVICE_REQUESTS]
        })
    })
})

export const {useGetOpenServiceRequestsQuery, useGetServiceRequestQuery, useUpdateQuotationMutation, useAwaitingQuotationApprovalMutation, useDeliverServiceRequestMutation} = serviceRequestApi