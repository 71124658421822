import React from 'react'
import getUserServiceStatus from '../../ServiceRequests/models/UserServiceRequestStatus'
import {GridCellParams, GridRenderCellParams, GridValueGetterParams} from '@mui/x-data-grid'
import getServiceSubscriptionType, {ServiceType} from '../../ServiceRequests/models/ServiceType'
import {ServiceRequest} from '../../ServiceRequests/models/ServiceRequest'
import {getServiceRequestFile, getServiceRequestQuotationFile} from '../../ServiceRequests/serviceRequestAsyncActions'
import {useDispatch} from 'react-redux'
import {DownloadFile} from '../../ServiceRequests/Files/DownloadFile/DownloadFile'
import formatFileName from '../../ServiceRequests/ServiceRequestsTable/FormatFileName/FormatFileName'
import {getTotalFromRequest} from '../../ServiceRequests/models/Quotation'
import {formatUtcDateToLocal} from '../utils/DateService'

export const getStatus = ({row}: { row: ServiceRequest }) => {
    const status = retrieveStatus(row)
    return <span className="label-format" style={{color: status.color}} title={status.label}>{status.label}</span>
}

export const retrieveStatus =  (row: ServiceRequest ) => {
    const status = row?.status == undefined ? '' : row?.status?.charAt(0)?.toUpperCase() + row?.status?.slice(1)
    return  getUserServiceStatus(status)
}

export const getStatusValue = (params :GridValueGetterParams) => {
    return retrieveStatus(params.row).label
}

export const getQuotationCost = ({row}: { row: ServiceRequest }) => {
    const total = getTotalFromRequest(row) ?? '-'
    return <span title={total.toString()} className="label-format">{total}</span>
}

export const getQuotationValue = (params: GridValueGetterParams) => {
    return getTotalFromRequest(params.row) ?? '-'
}

export const getContractId = ({row}: { row: ServiceRequest }) => {
    return row?.guildDetail?.contractId ?? '-'
}

export const getGuildName = ({row}: { row: ServiceRequest }) => {
    return row.guildDetail?.name
}

export const getRequestDetailsValue = (params: GridValueGetterParams) => {
    return params?.row.file?.name
}

export const getCreatedDate = ({row}: { row: ServiceRequest }) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return formatDate(row?.createdDate)
}

export const getLastModifiedDate = ({row}: { row: ServiceRequest }) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return formatDate(row?.updatedDate)
}

export const formatDate = (date : Date) => {
    if (!date) return '-'
    return formatUtcDateToLocal(date)
}

const formatString = ( label: string ) => {
    if (!label) {
        return '-'
    }
    return <span title={label} className="label-format">{label}</span>
}

export const formatService = ({row}: { row: ServiceRequest }) => {
    if(!row?.service) return ''
    const service: ServiceType = getServiceSubscriptionType(row?.service ?? '')
    return formatString(service.label)
}

export const getServiceLabel = (params: GridValueGetterParams) => {
    return  getServiceSubscriptionType(params.row.service ?? '').label
}

export const formatEmail = ({row}: { row: ServiceRequest }) => {
    return formatString(row?.requesterDetail?.email)
}

export const getRequesterEmail = (params : GridValueGetterParams) => {
    return params.row?.requesterDetail?.email
}

export const formatIdLink= (params: GridRenderCellParams, onClick: (params:GridCellParams) => void ): React.ReactNode => {
    const {row} = params
    const value = row.id
    if (!value) {
        return '-'
    }
    return <span onClick={() => onClick(params)} title={value} className="label-clickable-format">{value}</span>
}



export const quotationDetails= (params: GridRenderCellParams, onClick: (params:GridCellParams) => void ): React.ReactNode => {
    const value = getTotalFromRequest(params.row) ?? '-'
    return <span onClick={() => onClick(params)} title={value.toString()} className="label-clickable-format">{value}</span>
}

export const GetQuotationFile = ({row}: { row: ServiceRequest }) => {

    const dispatch = useDispatch()

    const getFile = (): Promise<string> => {
        return getServiceRequestQuotationFile(row.id, dispatch)
    }
    return (
        <>
            {(row.quotation?.quotationFile !==null && row.quotation?.quotationFile?.status === 'Valid') ?
                <div className="dl-component">
                    <DownloadFile file={row.quotation?.quotationFile}
                                  downloadFile={getFile} icon={false}/>
                </div>
                :
                <span>-</span>
            }
        </>
    )
}

export const GetRequestDetails = ({row}: { row: ServiceRequest }) => {
    const dispatch = useDispatch()
    const serviceRequest: ServiceRequest = row
    const fileName = formatFileName(serviceRequest)

    const downloadFile = (): Promise<string> => {
        return getServiceRequestFile(serviceRequest.id, fileName, dispatch)
    }

    return <DownloadFile file={serviceRequest.file} downloadFile={downloadFile} icon={false}/>
}


