import React, {useState} from 'react'
import {FileDownload} from '@mui/icons-material'
import LoadingButton from '@mui/lab/LoadingButton'
import * as XLSX from 'xlsx'
import FileSaver from 'file-saver'
import {setSnackbarMessage} from '../../util/commonSlice'
import {useTypedDispatch} from '../../util/store'
import {ServiceRequest} from '../models/ServiceRequest'

import getServiceRequestType, {ServiceType} from '../../ServiceRequests/models/ServiceType'
import formatFileName from './FormatFileName/FormatFileName'
import {getTotalFromQuotation} from '../models/Quotation'
import {
    formatDate,
    getCreatedDate, getLastModifiedDate,
    retrieveStatus
} from '../../genericComponents/serviceRequest/ServiceRequestFormatter'

interface ServiceRequestExcel {
    requestId : string
    service: string
    email: string
    guildName:string
    contractId :string
    requestDetails? :string
    quotation :string
    status :string
    creationDate :string,
    lastModifiedDate :string
}


const ExportServiceRequests = ( {serviceRequests}:{serviceRequests :ServiceRequest[]}) => {
    const [isLoading, setIsLoading] = useState(false)

    const dispatch = useTypedDispatch()


    const constructRows = (): ServiceRequestExcel[] => {
        return serviceRequests.map((serviceRequest: ServiceRequest) => {
            return {
                requestId : serviceRequest.id,
                service: getServiceRequestType(serviceRequest?.service ?? '').label,
                email : serviceRequest.requesterDetail.email,
                guildName: serviceRequest.guildDetail.name,
                contractId : serviceRequest.guildDetail.contractId,
                requestDetails : serviceRequest.file?.name,
                quotation : getTotalFromQuotation(serviceRequest.quotation).toString(),
                status: retrieveStatus(serviceRequest).label,
                creationDate : getCreatedDate({row : serviceRequest}),
                lastModifiedDate : getLastModifiedDate({row : serviceRequest})
            }
        })
    }

    const downloadExcel = () => {
        try {
            const currDate = new Date()
            const fileName = `Service-requests-${currDate.toLocaleString('en-GB').replace(' ', '_').replace(',', '')}`
            const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
            const fileExtension = '.xlsx'
            // Init worksheet
            const ws = XLSX.utils.json_to_sheet(constructRows())
            // Set custom headers
            const headers = [['Request Id','Service', 'Requester Email','Guild Name', 'Contract ID','Request Details','Quotation (Credits)', 'Status', 'Creation Date','Last Modified' ]]

            XLSX.utils.sheet_add_aoa(ws, headers)

            const excelBuffer = XLSX.write(XLSX.utils.book_new(ws,'Service Requests'), {bookType: 'xlsx', type: 'array'})

            const data = new Blob([excelBuffer], {type: fileType})
            FileSaver.saveAs(data, fileName + fileExtension)
            dispatch(setSnackbarMessage('All service requests have been exported'))
        } catch (e) {
            dispatch(setSnackbarMessage('There was an error exporting the list of service requests'))
        }
    }

    const downloadServiceRequests = () => {
        setIsLoading(true)
        downloadExcel()
        setIsLoading(false)
    }

    return <LoadingButton
        data-testid="export-members-button"
        className="member-button"
        variant="outlined"
        color="primary"
        type="button"
        disabled={isLoading}
        onClick={downloadServiceRequests}
        startIcon={<FileDownload />}
    >
        Export
    </LoadingButton>
}

export default ExportServiceRequests