import {createApi} from '@reduxjs/toolkit/dist/query/react'
import {axiosBaseQuery} from '../rtkQueryUtils'
import {GUILD_API_ENDPOINT} from '../util/constants/backendUrls'
import {IdType} from '../util/models/IdType'
import {Guild} from './models/guild'
import {ChangeLog} from './models/ChangeLog'

const GUILDS_TAG = 'GUILDS'

export const guildApi = createApi({
    reducerPath: 'guildApi',
    keepUnusedDataFor: 0,
    baseQuery: axiosBaseQuery({baseUrl: `${GUILD_API_ENDPOINT}/datx/`}),
    tagTypes: [GUILDS_TAG],
    endpoints: (builder) => ({
        attachOrganization : builder.mutation<Guild, any>({
            query: ({guildId, organizationId}) => ({
                url : `guilds/${guildId}/organization/${organizationId}`,
                method : 'PUT'
            }),
            invalidatesTags: [GUILDS_TAG],
            transformResponse: (response:any) => response.data,
            transformErrorResponse: (response, meta, arg) => `An error occured while attaching organization ${arg.organizationId}`,
        }),
        getGuild: builder.query<Guild, IdType>({
            // added linkedTo DTO to Guild type
            query: (guildId) => ({
                url: `guilds/${guildId}`,
                method: 'GET'
            }),
            providesTags:[GUILDS_TAG],
            transformResponse: (response:any) => response.data,
            transformErrorResponse: (response, meta, arg) => `An error occured while retrieving guild ${arg}`
        }),
        updateGuild: builder.mutation<Guild, Guild>({
            query: (guild) => ({
                url:`guilds/${guild.id}`,
                method: 'PUT',
                data: guild
            }),
            invalidatesTags: [GUILDS_TAG],
            transformErrorResponse: (response, meta, arg) => `An error occured while updating guild ${arg.name}`
            
        }),
        updateGuildStatus: builder.mutation<Guild, Guild>({
            query: (guild) => ({
                url:`guilds/${guild.id}/status/${guild.status}`,
                method: 'PATCH',
                data: guild
            }),
            invalidatesTags: [GUILDS_TAG],
            transformErrorResponse: (response, meta, arg) => `An error occured while updating guild status of ${arg.name}`

        }),
        getActiveGuilds:  builder.query<Guild[], void>({
            query: () => ({
                url: 'guilds/activeGuilds',
                method: 'GET'
            }),
            providesTags: [GUILDS_TAG],
            transformResponse: (response:any) => response.data,
            transformErrorResponse: (response, meta, arg) => 'An error occured while retrieving guilds',
        }),
        getInactiveGuilds:  builder.query<Guild[], void>({
            query: () => ({
                url: 'guilds/inactiveGuilds',
                method: 'GET'
            }),
            providesTags:[GUILDS_TAG],
            transformResponse: (response:any) => response.data,
            transformErrorResponse: (response, meta, arg) => 'An error occured while retrieving guilds',
        }),
        getGuildChangelog: builder.query<ChangeLog[], { guildId?: IdType }>({
            query: ({guildId}) => ({
                url: `guilds/${guildId}/changelog`,
                method: 'GET'
            }),
            transformResponse: (response: any) => response.data,
            transformErrorResponse: () => 'An error occurred while retrieving guild changelog',
            providesTags: [GUILDS_TAG]
        }),
        deleteDeniedGuild: builder.mutation<Guild, Guild>({
            query: (guild) => ({
                url:`guilds/${guild.id}/denied`,
                method: 'DELETE',
                data: guild
            }),
            invalidatesTags: [GUILDS_TAG],
            transformErrorResponse: (response, meta, arg) => `An error occured while deleting guild ${arg.name}`

        }),
    })
})

export const {
    useAttachOrganizationMutation,
    useGetGuildQuery,
    useGetActiveGuildsQuery,
    useGetInactiveGuildsQuery,
    useGetGuildChangelogQuery,
    useUpdateGuildMutation,
    useUpdateGuildStatusMutation,
    useDeleteDeniedGuildMutation} = guildApi