import {ReactNode} from 'react'
import './CardContainer.scss'

const CardContainer = ({children, containerClassName = '', contentClassName = '', fullHeight = false, fullWidth = false}:
                           {children: ReactNode, containerClassName?: string, contentClassName?: string, fullHeight?: boolean, fullWidth?: boolean}) => {
    const heightClassName = fullHeight ? 'full-height ' : 'content-height '
    const widthClassName = fullWidth ? 'full-width ' : 'content-width '

    return (
        <div className={'card-container ' + heightClassName + widthClassName + containerClassName}>
            <div className={'card-content ' + contentClassName}>{children}</div>
        </div>
    )
}

export default CardContainer
