import React from 'react'
import './ConfirmationDialog.scss'
import CustomDialog from '../../genericComponents/customDialog/CustomDialog'
import {Divider} from '@mui/material'

const ConfirmationDialog = ({open, closeDialog, confirmationMsg}:
                                {
                                    open: boolean,
                                    closeDialog: () => void,
                                    confirmationMsg: string
                                }
) => {

    return (
        <CustomDialog
            className="confirmation-dialog"
            open={open}
            title="Confirmation"
            onClose={closeDialog}
        >
            <div>
                <p className="confirmation-dialog-text">
                    {confirmationMsg}
                </p>

            </div>
        </CustomDialog>
    )
}

export default ConfirmationDialog
