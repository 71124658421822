import React from 'react'
import {MinQuantityRule, MultiplyPricePerQuantityRule} from '../QuotationRule'
import {ServiceRequest} from '../../ServiceRequest'
import {FormControl, TextField} from '@mui/material'
import {NumericFormat, InputAttributes} from 'react-number-format'
import {EditQuotationFormikValues} from '../../../ServiceRequestsTable/UpdateQuotation/EditQuotation'
import ServiceSubscriptionEnum from '../../ServiceEnum'

export const UpdateMultiplyPricePerQuantityRuleComponent = ({rule, serviceRequest, index, handleChange, values, optionName}: {
    rule: MultiplyPricePerQuantityRule,
    serviceRequest: ServiceRequest,
    index: number,
    handleChange: InputAttributes['onChange'],
    values: EditQuotationFormikValues,
    optionName?:string
}) => {
    const quantity = values.genericRules[index]?.quantity ?? 0
    const subTotal = rule.calculate(0, quantity)

    const defaultDescriptioin = 'No. Of Items'
    const description = optionName ?? defaultDescriptioin
    return <div className="quotation-detail-rule-structure" >
        <p className="quotation-detail-description">{description}</p>
        <FormControl className="quotation-detail-unit-price">
            <TextField
                inputProps={{style: {textAlign: 'right'}}}
                name={`genericRules.${index}.subRule.price`}
                type="number"
                value={rule?.price}
                sx={{width: 1}}
                size="small"
                required
                placeholder="eg. 10"
                error={(rule.price ?? 0) < 0}
                helperText={rule.price < 0 ? 'Price should be >= 0': ''}
                onChange={handleChange}/>
        </FormControl>
        <FormControl className="quotation-detail-nb-object">
            <TextField
                inputProps={{style: {textAlign: 'right'}}}
                name={`genericRules.${index}.quantity`}
                value={quantity}
                type="number"
                sx={{width: 1}}
                size="small"
                required
                placeholder="eg. 10"
                error={quantity < 0}
                helperText={quantity < 0 ? 'Number of object should be >= 0': ''}
                onChange={handleChange}/>
        </FormControl>
        <p className="quotation-detail-total">{subTotal}</p>
    </div>
}
