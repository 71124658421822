import ServiceEnum from './ServiceEnum'


export interface ServiceType {
    label: string
}

const getServiceRequestType = (value: ServiceEnum | string) => {
    const serviceSubscriptionTypeMap = new Map<string, ServiceType>([])
    serviceSubscriptionTypeMap.set(ServiceEnum.PART_MATCHING, {label: 'Part Matching'})
    serviceSubscriptionTypeMap.set(ServiceEnum.PART_REQUEST, {label: 'Part Request'})
    serviceSubscriptionTypeMap.set(ServiceEnum.ITEM_MONITORING, {label: 'Item Monitoring'})
    serviceSubscriptionTypeMap.set(ServiceEnum.NEW_PARTS, {label: 'New Parts'})
    serviceSubscriptionTypeMap.set(ServiceEnum.NEW_COMPANY_ITEMS, {label: 'New Companies Items'})
    serviceSubscriptionTypeMap.set(ServiceEnum.NEW_MANUFACTURER_ITEMS, {label: 'New Manufacturer Items'})
    serviceSubscriptionTypeMap.set(ServiceEnum.ONE_TIME_EXTRACTION, {label: 'One-time Extraction'})
    serviceSubscriptionTypeMap.set(ServiceEnum.RECURRING_EXTRACTION, {label: 'Recurring Extraction'})
    serviceSubscriptionTypeMap.set(ServiceEnum.SUBSCRIBE_INFORMATION_PACKAGE, {label: 'Access to Item Data'})
    serviceSubscriptionTypeMap.set(ServiceEnum.CREATE_PRIVATE_ATTRIBUTES, {label: 'Manage Private Attributes Creation'})
    serviceSubscriptionTypeMap.set(ServiceEnum.CAD_MODEL, {label: 'Item Modelling'})
    serviceSubscriptionTypeMap.set(ServiceEnum.ITEM_UPDATE, {label: 'Item Update'})
    return serviceSubscriptionTypeMap.get(value) ?? {label: 'N/A'}
}

export default getServiceRequestType

